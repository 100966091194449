// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rest_EBKQ2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.text_Zoltf {
  font-size: 14px;
  color: #2D8CEB;
  margin-left: 15px;
}
.arrow_Nbx3w {
  flex: none;
  margin-right: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/RestItem/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;AAHF;AAMA;EACE,eAAA;EACA,cAAA;EACA,iBAAA;AAJF;AAOA;EACE,UAAA;EACA,kBAAA;AALF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.rest {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 50px;\n}\n\n.text {\n  font-size: 14px;\n  color: #2D8CEB;\n  margin-left: 15px;\n}\n\n.arrow {\n  flex: none;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rest": `rest_EBKQ2`,
	"text": `text_Zoltf`,
	"arrow": `arrow_Nbx3w`
};
export default ___CSS_LOADER_EXPORT___;
