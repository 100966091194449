import * as React from 'react';
import * as _ from 'lodash-es';
import { event } from '@libs/ga';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import { connect } from 'dva';
import { navigateByType } from '@utils/browser';
import SwiperCard from '@components/SwiperCard';
import stl from './style.less';

export class RelatedFaq extends React.PureComponent<any, any> {
  get card() {
    const { list } = this.props;
    return _.map(list, (i) => (
      <div className={stl.card}>
        {_.map(i, (item) => (
          <div className={stl.item} key={item.id}>
            <div className={stl.name} onClick={this.click} data-id={item.id}>
              {item.title}
            </div>
            <IcnArrowRight className={stl.arrow} />
          </div>
        ))}
      </div>
    ));
  }

  click = (evt) => {
    const { id } = evt.currentTarget.dataset;
    event({
      category: 'related-faq_navigation',
      action: 'click',
      label: `${id}`,
    });
    const { list } = this.props;
    navigateByType(
      _.find(_.flattenDeep(list), (i) => i.id === id),
      this.props.dispatch,
      'search/fetchFaq'
    );
  };

  render() {
    const { list } = this.props;
    return (
      !_.isEmpty(list) && (
        <>
          <div className={stl.header}>探索更多问题</div>
          <SwiperCard list={this.card} height={'280px'} />
        </>
      )
    );
  }
}

export default connect(({ search }) => ({
  list: search.relatedList,
}))(RelatedFaq);
