// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_EkAih {
  width: 255px;
  height: 220px;
  margin: auto;
  overflow: hidden;
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.bg_TSHV9 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.status_Ewm5N {
  font-size: 18px;
  font-weight: bold;
  color: #26334D;
  text-align: center;
  margin-top: 81px;
  position: relative;
}
.hints__03Zw {
  font-size: 14px;
  color: #636B7A;
  text-align: center;
  margin-top: 15px;
  position: relative;
}
.action_PSMyc {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #636B7A;
  height: 36px;
  padding: 0 32px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 18px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.action_PSMyc:active {
  background-color: #F5F5F5;
}
`, "",{"version":3,"sources":["webpack://./src/components/Wrong/style.less"],"names":[],"mappings":"AAIA;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,QAAA;EACA,OAAA;EACA,QAAA;EACA,2BAAA;AAHF;AAMA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AAJF;AAOA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AALF;AAQA;EACE,eAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AANF;AASA;EACE,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,qCAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,2BAAA;EACA,eAAA;AAPF;AAUA;EACE,yBAAA;AARF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.block {\n  width: 255px;\n  height: 220px;\n  margin: auto;\n  overflow: hidden;\n  position: absolute;\n  top: 45%;\n  left: 0;\n  right: 0;\n  transform: translateY(-50%);\n}\n\n.bg {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.status {\n  font-size: 18px;\n  font-weight: bold;\n  color: #26334D;\n  text-align: center;\n  margin-top: 81px;\n  position: relative;\n}\n\n.hints {\n  font-size: 14px;\n  color: #636B7A;\n  text-align: center;\n  margin-top: 15px;\n  position: relative;\n}\n\n.action {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 14px;\n  color: #636B7A;\n  height: 36px;\n  padding: 0 32px;\n  border-radius: 4px;\n  border: 1px solid rgba(0, 0, 0, 0.05);\n  margin-top: 18px;\n  position: relative;\n  left: 50%;\n  transform: translateX(-50%);\n  cursor: pointer;\n}\n\n.action:active {\n  background-color: #F5F5F5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `block_EkAih`,
	"bg": `bg_TSHV9`,
	"status": `status_Ewm5N`,
	"hints": `hints__03Zw`,
	"action": `action_PSMyc`
};
export default ___CSS_LOADER_EXPORT___;
