import * as React from 'react';
import stl from './style.less';

export default class SuicideCard extends React.PureComponent<unknown, any> {
  render() {
    return (
      <div className={stl.card}>
        <div className={stl.desc}>心理危机免费热线 / 24 小时在线</div>
        <a className={stl.phone} href='tel:010-82951332'>010-82951332</a>
        <div className={stl.quote}>“别放弃，也许还有别的办法”</div>
      </div>
    );
  }
}
