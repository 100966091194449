import * as React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import ActivityViews from '@components/ActivityViews';
import PicQrcode from '@components/icons/PicQrcode';
import { pageview } from '@libs/ga';
import stl from './style.less';

export default class MinaEntranceViews extends React.PureComponent<unknown, any> {
  static show() {
    const elm = document.createElement('div');
    elm.id = `minaentrance`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<MinaEntranceViews />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_mina_entrance`);
    this.state = {
      visible: true,
    };
  }

  close = () => {
    const elm = document.getElementById(`minaentrance`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { visible } = this.state;
    return (
      <ActivityViews visible={visible} onClose={this.close}>
        <>
          <div className={stl.title}>保存图片到手机，使用微信扫码后体验</div>
          <PicQrcode className={stl.qrcode} />
        </>
      </ActivityViews>
    );
  }
}
