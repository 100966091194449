import * as React from 'react';
import IcnOtc from '@components/icons/IcnOtc';
import IcnRx from '@components/icons/IcnRx';
import stl from './style.less';

export default class Formula extends React.PureComponent<IProps, any> {
  render() {
    const { item } = this.props;
    return !!item && (
      <>
        <div className={stl.header}>
          <div className={stl.label}>{item.name}</div>
          {item.name === '非处方药' ? (
            <IcnOtc />
          ) : (
            <IcnRx />
          )}
        </div>
        <div className={stl.value}>{item.description}</div>
      </>
    );
  }
}

interface IProps {
  item: {
    isOtc: boolean;
    name: string;
    description: string;
  };
}
