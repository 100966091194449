import * as React from 'react';
import * as _ from 'lodash-es';
import cx from 'classnames';
import stl from './style.less';
import history from '@utils/history';
import emptyImg from '@images/icn-baike-empty.png';
import IcnArrowRight from '@components/icons/IcnArrowRight';

export default class DiseaseContent extends React.PureComponent<IProps, any> {
  tabsRef: React.RefObject<HTMLDivElement>;
  constructor(props) {
    super(props);
    this.state = {};
    this.tabsRef = React.createRef();
  }

  toBaike = () => {
    const {item} = this.props;
    history.push(`/bk?q=${item.disease.name}`);
  };

  toArticle = (article) => {
    history.push(`/disease-topic/${article.topic.id}`);
  }

  onClickTopic = (topic, i) => {
    this.scrollToTopic(i);
    this.props.item.page = 1;
    global['app']._store.dispatch({
      type: 'disease/update',
      payload: {
        topic,
      },
    });
    global['app']._store.dispatch({
      type: 'disease/loadDiseaseArticles',
      payload: {
        id: this.props.item.disease.id,
        topic: topic.value,
        page: this.props.item.page,
      },
    });
  }

  loadMore = () => {
    global['app']._store.dispatch({
      type: 'disease/loadDiseaseArticles',
      payload: {
        id: this.props.item.disease.id,
        topic: this.props.item.topic.value,
        page: ++this.props.item.page,
      },
    });
  }

  scrollToTopic = (index) => {
    const tabs = Array.from(this.tabsRef.current.children || []);
    let sum = 0;
    const layouts = tabs.map((tab) => {
      sum += tab.clientWidth;
      return {
        width: tab.clientWidth,
        sum,
      };
    });
    const middle = window.screen.width >> 1;
    const layout = layouts[index];
    const scrollLeft = layout.sum - middle - (layout.width >> 1);
    this.tabsRef.current.scrollLeft = scrollLeft;
  }

  render() {
    const {item} = this.props;
    const source = getDiseaseSource(item.disease);
    const summary = getDiseaseSummary(item.disease);

    return _.isEmpty(item.disease) ? (
      <div className={stl.empty}>
        <img className={stl.icon} src={emptyImg} alt="空" />
        <div className={stl.text}>未找到相关内容</div>
      </div>
    ) : (
    <div>
      <div className={stl.card} onClick={this.toBaike}>
        <div className={stl.card__header}>
          <div className={stl.card__title}>{item.disease.name}</div>
        </div>
        <div className={stl.card__desc}>{summary}</div>
        <div className={stl.card__navigation}>
          <div className={stl.source}>{source.logo}
            <div className={stl.source__name}>来自{source.name}</div>
            <IcnArrowRight className={stl.source__arrow} />
          </div>
        </div>
      </div>
      <div className={stl.list}>
        <div className={stl.list__title}>热点话题</div>
        <div className={stl.tabs} ref={this.tabsRef}>
          {item.topics.map((topic, i) => (
            <div 
              key={topic.name}
              className={cx(stl.tab, {[stl.active]: item.topic.value === topic.value})}
              onClick={() => this.onClickTopic(topic, i)}>
            {topic.name}
            </div>
          ))}
        </div>
        {item.articles.map(art => (
          <div key={art.id} className={stl.article} onClick={() => this.toArticle(art)}>
            <div className={stl.article__title}>{art.title}</div>
            <div className={stl.article__desc}>{art.summary}</div>
          </div>
        ))}
        {(
          <div className={cx(stl.item, stl.last)}>
            <div className={stl.more} onClick={() => this.loadMore()}>加载更多</div>
          </div>
        )}
      </div>
    </div>
    );
  }
}

function getDiseaseSource(disease) {
  const {logo, source} = _.get(disease, 'detailList[0]', {});
  return {
    name: source,
    logo,
  };
}

function getDiseaseSummary(disease) {
  return _.get(disease, 'detailList[0].value') || '';
}

interface topic {
  name: string;
  value: string;
}

interface IProps {
  item: {
    topic: topic;
    topics: topic[];
    articles: any[];
    disease: {
      id: string;
      name: string;
    }
    page: number;
  };
}
