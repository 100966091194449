import dva from 'dva';
import createLoading from 'dva-loading';
import 'raf/polyfill';
import appModel from '@models/app';
import kgModel from '@models/kg';
import searchModel from '@models/search';
import doctorModel from '@models/doctor';
import hospitalModel from '@models/hospital';
import feedbackModel from '@models/feedback';
import baikeModel from '@models/baike';
import diseaseModel from '@models/disease';
import faqModel from '@models/faq';
import userModel from '@models/user';
import router from './router';
import { error } from '@libs/log';
import { registerModel } from './utils';
import history from './utils/history';
import 'moment/locale/zh-cn';

const app = dva({
  history,
  onError: err => {
    error(err);
  },
});
global['app'] = app;
// register your hooks here
app.use(createLoading());

// register your models here
registerModel(app, appModel);
registerModel(app, kgModel);
registerModel(app, searchModel);
registerModel(app, doctorModel);
registerModel(app, hospitalModel);
registerModel(app, feedbackModel);
registerModel(app, baikeModel);
registerModel(app, diseaseModel);
registerModel(app, faqModel);
registerModel(app, userModel);

app.router(router);

app.start('#root');
