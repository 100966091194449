// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_xhs7s {
  padding-top: 30px;
  padding-bottom: 17px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-top: 20px;
  position: relative;
}
.header_eJwV2 {
  font-size: 17px;
  font-weight: bold;
  color: #26334D;
  margin-left: 25px;
  margin-bottom: 15px;
}
.source_E74Wq {
  font-size: 15px;
  line-height: 2;
  color: #636B7A;
  margin-left: 25px;
}
.logo_DGItz {
  position: absolute;
  bottom: 0;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/pc/Banner/style.less"],"names":[],"mappings":"AAIA;EACE,iBAAA;EACA,oBAAA;EACA,kBAAA;EACA,qCAAA;EACA,gBAAA;EACA,kBAAA;AAHF;AAMA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;AAJF;AAOA;EACE,eAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;AALF;AAQA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;AANF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.card {\n  padding-top: 30px;\n  padding-bottom: 17px;\n  border-radius: 6px;\n  border: 1px solid @black05;\n  margin-top: 20px;\n  position: relative;\n}\n\n.header {\n  font-size: 17px;\n  font-weight: bold;\n  color: #26334D;\n  margin-left: 25px;\n  margin-bottom: 15px;\n}\n\n.source {\n  font-size: 15px;\n  line-height: 2;\n  color: #636B7A;\n  margin-left: 25px;\n}\n\n.logo {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `card_xhs7s`,
	"header": `header_eJwV2`,
	"source": `source_E74Wq`,
	"logo": `logo_DGItz`
};
export default ___CSS_LOADER_EXPORT___;
