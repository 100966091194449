import * as React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import * as _ from 'lodash-es';
import cx from 'classnames';
import SidebarViews from '@components/SidebarViews';
import Ranking from '@components/Ranking';
import { event, pageview } from '@libs/ga';
import ContactListViews from '@components/ContactListViews';
import MpListViews from '@components/MpListViews';
import icnArrow from '@assets/icon/icn-arrow-right@2x.png';
import icnNav from '@assets/icon/icn-nav@2x.png';
import icnPhone from '@assets/icon/icn-phone@2x.png';
import icnMp from '@images/icn-mp-weixin.png';
import icnStar from '@images/icn-star.png';
import ShadowLine from '@components/ShadowLine';
import ProfileBlock from '@components/ProfileBlock';
import history from '@utils/history';
import ProfileViews from '@components/ProfileViews';
import { fetchDoctorIntent } from '@services/search';
import DoctorItemSimple from '@components/DoctorItemSimple';
import { os } from '@utils/browser';
import stl from './style.less';
import config from '@config';

const defaultDepartment = '全部科室';
export default class HospitalViews extends React.PureComponent<any, any> {
  static show(item) {
    const elm = document.createElement('div');
    elm.id = `hospital_${item.id}`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<HospitalViews item={item} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_hospital`);
    const tagList = _.compact(_.concat([props?.item?.grade], props?.item?.tags));
    const departmentRowList = _.chunk(props?.item?.departmentList, props?.item?.departmentList?.length > 10 ? Math.ceil(props?.item?.departmentList?.length / 3) : props?.item?.departmentList?.length);
    this.state = {
      visible: !!props.item,
      tagList,
      departmentRowList,
      current: defaultDepartment,
      doctors: {
        list: [],
        url: '',
        params: {},
      },
    };
  }

  get tagList() {
    const { item } = this.props;
    return _.compact(_.concat([item.grade], item.tags));
  }

  toLocation = () => {
    const { item } = this.props;
    event({
      category: 'hospital-views_location',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    window.open(`http://api.map.baidu.com/geocoder?address=${item.address}&output=html`);
  };

  toPhone = () => {
    const { item } = this.props;
    event({
      category: 'hospital-views_phone',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    ContactListViews.show(item.contacts);
  };

  toMp = () => {
    const { item } = this.props;
    event({
      category: 'hospital-views_mp',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    MpListViews.show(item.mpList);
  };

  searchRanking = query => {
    history.push('/search');
    global['app']._store.dispatch({
      type: 'search/search',
      payload: {
        query,
      },
    });
    setTimeout(() => {
      this.close();
    }, 800);
  };

  toBranch = (evt) => {
    const { index } = evt.currentTarget.dataset;
    const item = this.props.item.branchList[index];
    event({
      category: 'hospital-views_tobranch',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    window.open(`http://api.map.baidu.com/geocoder?address=${item.address}&output=html`);
  };

  toProfile = () => {
    event({
      category: 'hospital-views_toprofile',
      action: 'click',
      label: this.props.item.id,
    });
    ProfileViews.show(this.props.item);
  };

  hasStar = (list) => {
    if (!list) {
      return false;
    }
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i].length; j++) {
        if (list[i][j].star) {
          return true;
        }
      }
    }
    return false;
  };

  search = async (params) => {
    const resp = await fetchDoctorIntent({
      ...this.state.doctors.params,
      ...params,
    });
    this.setState({
      doctors: {
        list: _.concat(params.page === 1 ? [] : this.state.doctors.list, resp.list),
        url: resp.url,
        params: resp.params,
      },
    });
  };

  searchDepartment = (evt) => {
    const { name } = evt.currentTarget.dataset;
    this.setState({
      current: name,
    });
    this.search({
      page: 1,
      hospitalName: this.props.item?.name,
      departmentName: name,
    });
  };

  toDoctor = item => {
    event({
      category: 'hospital-views_todoctor',
      action: 'click',
      label: item.id,
    });
    global['app']._store.dispatch({
      type: 'doctor/fetchDoctor',
      payload: {
        id: item.id,
      },
    });
  };

  scrollToLower = () => {
    const { doctors } = this.state;
    if (doctors.url) {
      this.search({
        page: (doctors?.params?.page ?? 0) + 1,
      });
    }
  };

  close = () => {
    const { item } = this.props;
    const elm = document.getElementById(`hospital_${item.id}`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  componentDidMount() {
    this.search({
      page: 1,
      hospitalName: this.props.item?.name,
      hospitalId: this.props.item?.id,
    });
  }

  render() {
    const { item } = this.props;
    const { visible, tagList, departmentRowList, current, doctors } = this.state;
    return (
      <SidebarViews visible={visible} onClose={this.close}
                    onScrollToLower={this.scrollToLower}>
        {!!item && (
          <>
            <div className={stl.nameWrap}>
              <div className={stl.title}>{item.name}</div>
              <div className={stl.list}>
                {_.map(tagList, tag => (
                  <div className={stl.tag} key={tag}>{tag}</div>
                ))}
              </div>
              <img className={stl.logo} src={item.avatar} />
            </div>
            {_.isEmpty(item.branchList) ? (
              <div className={stl.addressWrap} onClick={this.toLocation}>
                <div className={stl.address}>{item.address}</div>
                <img className={stl.arrow} src={icnArrow} />
              </div>
            ) : _.map(item.branchList, (branch, index) => (
              <div className={stl.addressWrap} key={branch.name}
                   onClick={this.toBranch} data-index={index}>
                <div className={stl.address}>{branch.prefix}：{branch.address}</div>
                <img className={stl.arrow} src={icnArrow} />
              </div>
            ))}
            <div className={stl.entrance}>
              {config.sponsor.hasLocation && (
                <div className={cx(stl.wrap, stl.nav, { [stl.disable]: !item.address })}
                     onClick={this.toLocation}>
                  <img className={stl.icon} src={icnNav} />
                </div>
              )}
              <div className={cx(stl.wrap, stl.phone, { [stl.disable]: !item?.contacts?.length })}
                   onClick={this.toPhone}>
                <img className={stl.icon} src={icnPhone} />
              </div>
              {config.sponsor.hasService && (
                <div className={cx(stl.wrap, stl.mp, { [stl.disable]: !item?.mpList?.length })}
                     onClick={this.toMp}>
                  <img className={stl.icon} src={icnMp} />
                </div>
              )}
            </div>
            {os.isMobile && (
              <ShadowLine />
            )}
            <div className={stl.header}>
              <div className={stl.label}>医院简介</div>
              <div className={stl.hints}>{item.rankInfo.source}</div>
            </div>
            {!_.isEmpty(item.profile) && (
              <ProfileBlock profile={item.profile} onClick={this.toProfile} />
            )}
            {!_.isEmpty(_.get(item, 'rankInfo.list')) && (
              <Ranking list={item.rankInfo.list} onClick={this.searchRanking} />
            )}
            {os.isMobile && (
              <ShadowLine />
            )}
            <div className={stl.header}>
              <div className={stl.label}>科室医生</div>
              {this.hasStar(departmentRowList) && (
                <div className={stl.hints}>加星表示特色科室</div>
              )}
            </div>
            <div className={stl.line} />
            <div className={stl.departmentParallel}>
              {_.map(departmentRowList, (departmentList, index) => (
                <div className={stl.slot} key={index}>
                  {_.map(departmentList, department => (
                    <div className={cx(stl.department, { [stl.active]: current === department.name })}
                         onClick={this.searchDepartment} key={department.name}
                         data-name={department.name}>
                      {department.name}
                      {department.star && (
                        <img className={stl.star} src={icnStar} />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className={stl.line} />
            {_.map(doctors.list, doctor => (
              <div className={stl.item} key={doctor.id}>
                <DoctorItemSimple item={doctor} onClick={this.toDoctor} />
              </div>
            ))}
            <div style={{ height: '60px' }} />
          </>
        )}
      </SidebarViews>
    );
  }
}
