import * as React from 'react';
import icnArrow from '@assets/icon/icn-arrow-down@2x.png';
import LocationViews from '@components/LocationViews';
import stl from './style.less';

export default class CityPicker extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      province: props.province,
      city: props.city,
    };
  }

  pickLocation = () => {
    LocationViews.show({
      province: this.state.province,
      city: this.state.city,
      onUpdate: ({ province, city }) => {
        this.setState({
          province,
          city,
        });
        this.props.onSearch({
          province,
          city,
          page: 1,
        });
      },
    });
  };

  render() {
    const { city, province } = this.state;
    return (
      <div className={stl.location} onClick={this.pickLocation}>
        <div className={stl.text}>地区：{city || province || '全国'}</div>
        <img className={stl.down} src={icnArrow} />
      </div>
    );
  }
}
