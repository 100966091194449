import IcnArrowRight from '@components/icons/IcnArrowRight';
import React from 'react';
import stl from './style.less';
import _ from 'lodash-es';

export default function DoctorItemMiniCard({ item, onClick }) {
  return (
    <div className={stl.doctor} onClick={onClick}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: 0,
          flex: 1,
        }}>
        <img className={stl.photo} src={item.avatar} alt={'医生头像'} />
        <div style={{ overflow: 'hidden' }}>
          <div className={stl.name}>
            {item.name}
            <div className={stl.jobTitle}>{item.title}</div>
          </div>
          {item.hospital && (
            <div className={stl.info}>
              {_.compact([item.hospital, item.department]).join('・')}
            </div>
          )}
        </div>
      </div>
      <IcnArrowRight />
    </div>
  );
}
