// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_RpdLD {
  height: calc(100% - 55px);
  width: auto;
  overflow-y: auto;
}
.title_DO3EF {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.27;
  color: #26334D;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.desc_QWwX4 {
  font-size: 14px;
  color: #656C7C;
  line-height: 1.71;
  text-align: center;
}
.wrap_iw_RC {
  margin: 25px 20px 120px;
}
.qrcode_LrfB4 {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/BusinessViews/style.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;EACA,gBAAA;AACF;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAF;AAGA;EACE,eAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AADF;AAIA;EACE,uBAAA;AAFF;AAKA;EACE,WAAA;AAHF","sourcesContent":[".block {\n  height: calc(100% - 55px);\n  width: auto;\n  overflow-y: auto;\n}\n\n.title {\n  font-size: 22px;\n  font-weight: 600;\n  line-height: 1.27;\n  color: #26334D;\n  text-align: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.desc {\n  font-size: 14px;\n  color: #656C7C;\n  line-height: 1.71;\n  text-align: center;\n}\n\n.wrap {\n  margin: 25px 20px 120px;\n}\n\n.qrcode {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `block_RpdLD`,
	"title": `title_DO3EF`,
	"desc": `desc_QWwX4`,
	"wrap": `wrap_iw_RC`,
	"qrcode": `qrcode_LrfB4`
};
export default ___CSS_LOADER_EXPORT___;
