import { error } from '@libs/log';
import { fetchFaq, fetchRelatedFaqList } from '@services/search';

export default {
  namespace: 'faq',
  state: {
    item: {},
    relatedFaqList: [],
  },
  effects: {
    *fetchFaq({ payload }, { call, put }) {
      try {
        const item = yield call(fetchFaq, payload.id);
        yield put({
          type: 'setFaq',
          payload: item,
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
    *fetchRelatedFaqList({ payload }, { call, put }) {
      try {
        const list = yield call(fetchRelatedFaqList, payload.query);
        yield put({
          type: 'setRelatedFaqList',
          payload: list,
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
  },
  reducers: {
    setFaq(state, { payload }) {
      return {
        ...state,
        item: payload,
      };
    },
    setRelatedFaqList(state, { payload }) {
      return {
        ...state,
        relatedFaqList: payload,
      };
    },
  },
};
