// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_lTMWB {
  display: inline-block;
  margin: 18px 0 -2px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.logoHome_xBo26 {
  height: 24px;
}
.searchBar_ROo3d {
  padding: 0 10px;
}
.searchResult_GZlNh {
  padding-bottom: 60px;
}
.margin_HnZDt {
  margin: 0 10px;
}
.button_Gx5r5 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  margin: 20px 15px 0;
  cursor: pointer;
  position: relative;
}
.button_Gx5r5:active {
  background-color: #F5F5F5;
}
.text_E5gWS {
  font-size: 14px;
  color: #636B7A;
  margin-left: 5px;
}
.trademark_lGLpn {
  display: block;
  text-align: center;
  margin-top: 60px;
}
.footer_Zy4_b {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.entrance_ydFXg {
  flex: none;
  font-size: 14px;
  color: #636B7A;
  padding: 20px;
  cursor: pointer;
}
.entrance_ydFXg:active {
  opacity: 0.6;
}
.line_wa6M5 {
  flex: none;
  height: 15px;
  width: 1px;
  background-color: #A8ADB8;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Search/mobile/style.less"],"names":[],"mappings":"AAIA;EACE,qBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,2BAAA;AAHF;AAMA;EACE,YAAA;AAJF;AAOA;EACE,eAAA;AALF;AAQA;EACE,oBAAA;AANF;AASA;EACE,cAAA;AAPF;AAUA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;EACA,qCAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AARF;AAUE;EACE,yBAAA;AARJ;AAYA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAVF;AAaA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;AAXF;AAcA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAZF;AAeA;EACE,UAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;AAbF;AAeE;EACE,YAAA;AAbJ;AAiBA;EACE,UAAA;EACA,YAAA;EACA,UAAA;EACA,yBAAA;AAfF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.header {\n  display: inline-block;\n  margin: 18px 0 -2px;\n  position: relative;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.logoHome {\n  height: 24px;\n}\n\n.searchBar {\n  padding: 0 10px;\n}\n\n.searchResult {\n  padding-bottom: 60px;\n}\n\n.margin {\n  margin: 0 10px;\n}\n\n.button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 50px;\n  border-radius: 25px;\n  border: 1px solid @black04;\n  margin: 20px 15px 0;\n  cursor: pointer;\n  position: relative;\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n}\n\n.text {\n  font-size: 14px;\n  color: #636B7A;\n  margin-left: 5px;\n}\n\n.trademark {\n  display: block;\n  text-align: center;\n  margin-top: 60px;\n}\n\n.footer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 30px;\n}\n\n.entrance {\n  flex: none;\n  font-size: 14px;\n  color: #636B7A;\n  padding: 20px;\n  cursor: pointer;\n\n  &:active {\n    opacity: 0.6;\n  }\n}\n\n.line {\n  flex: none;\n  height: 15px;\n  width: 1px;\n  background-color: #A8ADB8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_lTMWB`,
	"logoHome": `logoHome_xBo26`,
	"searchBar": `searchBar_ROo3d`,
	"searchResult": `searchResult_GZlNh`,
	"margin": `margin_HnZDt`,
	"button": `button_Gx5r5`,
	"text": `text_E5gWS`,
	"trademark": `trademark_lGLpn`,
	"footer": `footer_Zy4_b`,
	"entrance": `entrance_ydFXg`,
	"line": `line_wa6M5`
};
export default ___CSS_LOADER_EXPORT___;
