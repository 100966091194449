import * as React from 'react';
import stl from './style.less';

export default class ShadowLine extends React.PureComponent<unknown, any> {
  render() {
    return (
      <div className={stl.bg} />
    );
  }
}
