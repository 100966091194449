import * as React from 'react';
import IcnArrowDown from '@components/icons/IcnArrowDown';
import stl from './style.less';

export default class RestItem extends React.PureComponent<IProps, any> {
  click = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { count } = this.props;
    return (
      <div className={stl.rest} onClick={this.click}>
        <div className={stl.text}>余下 {count} 项</div>
        <div className={stl.arrow}>
          <IcnArrowDown />
        </div>
      </div>
    );
  }
}

interface IProps {
  count: number;

  onClick(): void;
}
