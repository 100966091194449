import * as React from 'react';
import { CXZC, DQFXDJ, HSJC, JZWW, JZZL, LCSY, YMYYJZ, YPZL } from '@constants/index';
import IcnSource from '@components/icons/IcnSource';
import IcnType from '@components/icons/IcnType';
import iconDoctor from '@assets/icon/icn-doctor-white@2x.png';
import iconHospital from '@assets/icon/icn-hospital-white@2x.png';
import iconDrug from '@assets/icon/icn-drug-white@2x.png';
import iconDisease from '@assets/icon/icn-disease-white@2x.png';
import iconRoom from '@assets/icon/icn-room-white@2x.png';
import iconEdu from '@assets/icon/icn-edu-white@2x.png';
import iconSearch from '@assets/icon/icn-search-white@2x.png';

export function getSourceLogo(logo) {
  return <IcnSource src={logo || 'https://ask-cdn.nocode.com/sources/icn-web.png'} />;
}

export function getTypeLogo(type) {
  switch (type) {
    case 'hospital':
      return <IcnType src={iconHospital} />;
    case 'department':
      return <IcnType src={iconRoom} />;
    case 'drug':
      return <IcnType src={iconDrug} />;
    case 'vaccine_location':
      return <IcnType src={iconDrug} />;
    case 'disease':
      return <IcnType src={iconDisease} />;
    case 'faq':
      return <IcnType src={iconEdu} />;
    case 'examination_indicator':
      return <IcnType src={iconEdu} />;
    case 'query':
    case 'examine':
      return <IcnType src={iconSearch} />;
    case 'doctor':
      return <IcnType src={iconDoctor} />;
  }
}

export function getBackgroundColorByType(type) {
  switch (type) {
    case 'hospital':
      return '#68BA53';
    case 'department':
      return '#AD7EE8';
    case 'drug':
      return '#35B791';
    case 'vaccine_location':
      return '#35B791';
    case 'query':
    case 'examine':
    case 'disease':
      return '#7388A1';
    case 'faq':
      return '#F18A45';
    case 'examination_indicator':
      return '#F18A45';
    case 'doctor':
      return '#519BF0';
  }
}

export function getMinaCodeBy(type) {
  switch (type) {
    case JZZL:
      return 'https://resource.nocode.com/assets/share/med_assistant_share.png';
    case YPZL:
      return 'https://resource.nocode.com/assets/share/drug_assistant_share.png';
    case LCSY:
      return 'https://resource.nocode.com/assets/share/clinical_trials_share.png';
    case HSJC:
      return 'https://ask-cdn.nocode.com/mina-hsjc.png';
    case YMYYJZ:
      return 'https://ask-cdn.nocode.com/mina-ymyyjz.png';
    case DQFXDJ:
      return 'https://ask-cdn.nocode.com/mina-dqfxdj.png';
    case CXZC:
      return 'https://ask-cdn.nocode.com/mina-cxzc.png';
    default:
      return '';
  }
}

export function getMinaGhCodeBy(type) {
  switch (type) {
    case JZZL:
      return 'gh_7fb3dd293cdd';
    case YPZL:
      return 'gh_87062e907102';
    case LCSY:
      return 'gh_7713a2e421b6';
    case JZWW:
      return 'gh_f4ccad239ba3';
    default:
      return '';
  }
}

export function changeColor(url, color) {
  return url.replace(/%23[a-zA-Z0-9]{6}/g, color.replace('#', '%23'));
}
