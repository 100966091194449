import * as React from 'react';
import cx from 'classnames';
import stl from './style.less';

export default function IcnSource({ className, src }: IProps) {
  return (
    <img className={cx(stl.icon, className)}
         src={src} alt="信源图片" />
  );
}

interface IProps {
  className?: string;
  src: string;
}
