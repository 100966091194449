import * as React from 'react';
import BaikeContent from '@components/BaikeContent';
import stl from './style.less';
import { connect } from 'dva';

@connect(({ baike }) => ({
  item: baike.item,
}))
export default class Baike extends React.PureComponent<any, any> {
  render() {
    const { item } = this.props;
    return (
      <div className={stl.page}>
        <BaikeContent item={item} />
        <div style={{ height: '80px' }} />
      </div>
    );
  }
}
