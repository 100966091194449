import * as React from 'react';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import { os } from '@utils/browser';
import { getBackgroundColorByType, getTypeLogo } from '@utils/logo';
import stl from './style.less';

export default class Query extends React.PureComponent<IProps, any> {
  get style() {
    const { item } = this.props;
    return { backgroundColor: getBackgroundColorByType(item.type) };
  }

  render() {
    const { item } = this.props;
    return (
      <div className={stl.item}>
        <div className={stl.iconWrap} style={this.style}>{getTypeLogo(item.type)}</div>
        <div className={stl.text}>{item.text}</div>
        {os.isPc && (
          <IcnArrowRight className={stl.arrowRight} />
        )}
      </div>
    );
  }
}

interface IProps {
  item: IQuery;
}

export interface IQuery {
  type: string;
  text: string;
}
