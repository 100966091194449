import * as React from 'react';
import * as _ from 'lodash-es';
import { event } from '@libs/ga';
import stl from './style.less';
import WxOpenLaunchWeapp from '@components/mobile/WxOpenLaunchWeapp';
import { os } from '@utils/browser';
import MinaCodeViews from '@components/MinaCodeViews';

export default class CovidCard extends React.PureComponent<IProps, any> {
  toMina = evt => {
    const { name } = evt.currentTarget.dataset;
    event({
      category: 'covid-card_tomina',
      action: 'click',
      label: name,
    });
    return MinaCodeViews.show(name);
  };

  render() {
    const { list } = this.props;
    return (
      <div className={stl.block}>
        {_.map(list, (i, k) => (
          <div className={stl.mina} key={k}
               onClick={this.toMina} data-name={i.name}>
            <img className={stl.logo} src={i.logo} />
            <div className={stl.text}>{i.name}</div>
            <WxOpenLaunchWeapp condition={os.isWechat} height={50}
                               username={i.ghCode} path={i.path} />
          </div>
        ))}
      </div>
    );
  }
}

interface IProps {
  list?: {
    appId: string;
    logo: string;
    name: string;
    path: string;
  }[];
}
