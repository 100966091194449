import React, { lazy, Suspense } from 'react';
import { connect } from 'dva';
import { os } from '@utils/browser';
import { pageview } from '@libs/ga';

const Pc = lazy(() => import(/* webpackChunkName: "HomePc" */'@pages/Home/pc'));
const Mobile = lazy(() => import(/* webpackChunkName: "HomeMobile" */'@pages/Home/mobile'));
@connect()
export default class Home extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    pageview('home');
    props.dispatch({
      type: 'search/init',
    });
  }

  render() {
    if (os.isPc) {
      return (
        <Suspense fallback>
          <Pc />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback>
          <Mobile />
        </Suspense>
      );
    }
  }
}

interface IProps {
  dispatch?(opt): void;
}
