import * as React from 'react';
import IcnAuthority from '@components/icons/IcnAuthority';
import * as _ from 'lodash-es';
import { banners } from '@constants/index';
import stl from './style.less';

export default class Banner extends React.PureComponent<any, any> {
  render() {
    return (
      <div className={stl.card}>
        <div className={stl.header}>权威信源，优质专家</div>
        {_.map(banners, (b, k) => (
          <div className={stl.source} key={k}>{b}</div>
        ))}
        <IcnAuthority className={stl.logo} />
      </div>
    );
  }
}
