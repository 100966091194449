import { fetchFaq, fetchRelatedFaqList, HighlightItem } from '@services/search';
import * as React from 'react';
import * as _ from 'lodash-es';
import stl from './style.less';
import { createRoot } from 'react-dom/client';
let root;
import SidebarViews from '@components/SidebarViews';
import FaqContent from '@components/FaqContent';
import { event, pageview } from '@libs/ga';
import { navigateByType } from '@utils/browser';

export default class FaqViews extends React.PureComponent<IProps, IState> {
  static async show(q: IQuery, type: string) {
    const elm = document.createElement('div');
    elm.id = `${type}_${q.id}`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<FaqViews query={q} type={type} />);
  }

  state: IState;
  constructor(props: IProps) {
    super(props);
    pageview(`${location.pathname}#!views_faq`);
    this.state = {
      visible: true,
      item: {} as any,
      faqList: [],
    };
    this.init(props.query);
  }

  init = async (q: IQuery) => {
    const item = await fetchFaq(q.id);
    this.setState({
      item,
    });
    this.setState({
      faqList: await fetchRelatedFaqList(item.title),
    });
  };

  get doctor() {
    const { item } = this.state;
    return _.get(item, `doctor`, {});
  }

  close = () => {
    const { type } = this.props;
    const { item } = this.state;
    const elm = document.getElementById(`${type}_${item.id}`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  toDoctor = () => {
    global['app']._store.dispatch({
      type: 'doctor/fetchDoctor',
      payload: {
        id: this.doctor.id,
      },
    });
  };

  toFaq = (item) => {
    event({
      category: 'faq-views_tofaq',
      action: 'click',
      label: `${item.id}_${item.title}_${item.source}`,
    });
    navigateByType(item, global['app']._store.dispatch, 'search/fetchFaq');
  };

  toBaike = (item) => {
    const map = {
      disease: 'search/fetchDisease',
      drug: 'search/fetchDrug',
      examine: 'search/fetchExamine',
    };
    navigateByType(item, global['app']._store.dispatch, map[item.type]);
  };

  render() {
    const { visible, item, faqList } = this.state;
    return (
      <>
        <SidebarViews visible={visible} onClose={this.close}>
          {visible && (
            <div className={stl.container}>
              <FaqContent item={item} faqList={faqList} />
            </div>
          )}
        </SidebarViews>
      </>
    );
  }
}

interface IState {
  visible: boolean;
  faqList: any;
  item: {
    id: string,
    title: string,
    summary: string,
    category: string,
    date: string,
    nerList: any[],
    url: string,
    mina: any,
    source: string,
    logo: any,
    doctor: any,
    highlightResult: HighlightItem[],
  };
}

interface IProps {
  type: string;
  query: IQuery;
}

interface IQuery {
  id: string;
  query: string;
}
