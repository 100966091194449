import { error } from '@libs/log';
import { fetchHospital } from '@services/search';
import HospitalViews from '@components/HospitalViews';

export default {
  namespace: 'hospital',
  state: {},
  effects: {
    * fetchHospital({ payload: { id } }, { call }) {
      try {
        const hospital = yield call(fetchHospital, id);
        HospitalViews.show(hospital);
      } catch (e) {
        error(e);
        throw e;
      }
    },
  },
  reducers: {},
};
