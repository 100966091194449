import * as React from 'react';
import cx from 'classnames';
import { withDefault } from '@utils/index';
import stl from './style.less';

export default class ActivityViews extends React.PureComponent<IProps, any> {
  isTop = true;
  scrollTop = 0;
  scrollX = 0;
  scrollY = 0;
  close = () => {
    const { onClose } = this.props;
    onClose();
  };

  touchStart = evt => {
    this.scrollTop = evt.currentTarget.scrollTop;
    this.scrollX = withDefault(evt.changedTouches[0].pageX, 0);
    this.scrollY = withDefault(evt.changedTouches[0].pageY, 0);
  };

  touchEnd = evt => {
    const scrollX = withDefault(evt.changedTouches[0].pageX, 0);
    const scrollY = withDefault(evt.changedTouches[0].pageY, 0);
    if (this.scrollY > scrollY) {
      return this.isTop = false;
    }
    if (this.scrollY < scrollY && this.degLess30(scrollX, scrollY)) {
      if (this.scrollTop <= 0) {
        this.close();
      }
      if (evt.currentTarget.scrollTop <= 0 && this.isTop) {
        this.close();
      }
      this.isTop = true;
    }
  };

  degLess30 = (scrollX, scrollY) => {
    const x = Math.abs(scrollX - this.scrollX);
    const y = Math.abs(scrollY - this.scrollY);
    return (y * y) / (x * x) > 3;
  };

  render() {
    const { visible, children } = this.props;
    return (
      <>
        <div className={stl.cover} onClick={this.close} />
        <div className={cx(stl.views, visible ? stl.visible : stl.hidden)}
             onTouchStart={this.touchStart} onTouchEnd={this.touchEnd}>
          {children}
        </div>
      </>
    );
  }
}

interface IProps {
  visible: boolean;

  onClose(): void;
}
