import * as React from 'react';
import * as _ from 'lodash-es';
import { withDefault } from '@utils/index';
import cx from 'classnames';
import stl from './style.less';
import Comments from '@components/Comments';

export default class FaqItem extends React.PureComponent<IProps, any> {
  get tagList() {
    const { item } = this.props;
    return _.get(item, 'doctor.tags');
  }

  click = () => {
    const { item, onClick } = this.props;
    onClick(item);
  };

  render() {
    const { item } = this.props;
    return (
      <div onClick={this.click}>
        <div className={stl.name}>{item.title}</div>
        <div className={stl.summary}>{item.summary}</div>
        <Comments logo={item.logo} reference={
          withDefault(_.compact([item.doctor.name, item.doctor.hospital, item.doctor.department]).join(' · '), item.source)}
          style={{marginTop: 13}} />
        {!_.isEmpty(this.tagList) && (
          <div className={stl.tags}>
            {_.map(this.tagList, (i, index) => (
              <div className={cx(stl.tagItem, index === this.tagList.length - 1 && stl.oneLine)}>
                {i}
              </div>
            ))}
          </div>
        )}
        <div style={{ height: '20px' }} />
      </div>
    );
  }
}

interface IProps {
  item: {
    title: string;
    summary: string;
    logo: React.ReactElement;
    source: string;
    doctor: any;
  };

  onClick(item): void;
}
