import * as React from 'react';
import * as _ from 'lodash-es';
import cx from 'classnames';
import Insurance from '@components/Insurance';
import Formula from '@components/Formula';
import SpecialMedication from '@components/SpecialMedication';
import { withDefault } from '@utils/index';
import { empty_text } from '@constants/index';
import { event } from '@libs/ga';
import stl from './style.less';
import md from '@libs/md';

function removeSpaceBetweenHtmlTag(value: string) {
  return value.replace(/>\s+</g, '><');
}

export default class TabViews extends React.PureComponent<IProps, any> {
  get value() {
    return withDefault(this.item?.value, empty_text);
  }

  get item() {
    const { list, current } = this.props;
    return list[current];
  }

  pick = evt => {
    const { index } = evt.currentTarget.dataset;
    const { list } = this.props;
    event({
      category: 'tab-views_tab',
      action: 'click',
      label: `${list[index].label}`,
    });
    const { onChange } = this.props;
    onChange(+index);
  };

  onClick = () => {
    const { onClick } = this.props;
    if (_.isFunction(onClick)) {
      onClick();
    }
  };

  renderValue = () => {
    if (this.item?.key === 'medicare') {
      return (
        <div className={stl.wrap} onClick={this.onClick} style={this.props.valueStyle}>
          <Insurance item={this.value} />
        </div>
      );
    }
    if (this.item?.key === 'otc_type') {
      return (
        <div className={stl.wrap} onClick={this.onClick} style={this.props.valueStyle}>
          <Formula item={this.value} />
        </div>
      );
    }
    if (this.item?.key === 'person_use') {
      return (
        <div className={stl.wrap} onClick={this.onClick} style={this.props.valueStyle}>
          <SpecialMedication item={this.value} />
        </div>
      );
    }
    if (this.item?.contentType === 'md') {
      return (
        <div className={stl.markdown} onClick={this.onClick} style={this.props.valueStyle}>
          <div dangerouslySetInnerHTML={{__html: removeSpaceBetweenHtmlTag(md.render(this.value))}} />
        </div>
      );
    }
    return (
      <div className={stl.value} onClick={this.onClick} style={this.props.valueStyle}>
        {this.value}
      </div>
    );
  }

  scrollTo = index => {
    const parent = document.getElementById(`${this.props.idPrefix}_tabs`);
    const elm = document.getElementById(`${this.props.idPrefix}_tab_${index}`);
    if (!elm) {
      return;
    }
    parent.scrollTo({
      top: 0,
      left: elm.offsetLeft - 50,
      behavior: 'smooth',
    });
  };

  componentDidUpdate() {
    const { current } = this.props;
    this.scrollTo(current);
  }

  componentDidMount() {
    const { current } = this.props;
    /*
     * 延迟滚动定位
     * 虽然 dom 元素存在，但没有加载完毕，导致滚动定位失败
     * */
    setTimeout(() => {
      this.scrollTo(current);
    }, 500);
  }

  render() {
    const { current, list, idPrefix } = this.props;
    return !_.isEmpty(list) && (
      <>
        <div className={stl.block} id={`${idPrefix}_tabs`}>
          {_.map(list, (i, index) => (
            <div className={cx(stl.tab, { [stl.active]: current === index })}
              key={index} onClick={this.pick} id={`${idPrefix}_tab_${index}`}
              data-index={index}>{i.label}</div>
          ))}
          <div className={stl.i} />
        </div>
        {this.renderValue()}
      </>
    );
  }
}

interface IProps {
  idPrefix: string;
  list: any[];
  current: number;
  valueStyle?: React.CSSProperties;

  onChange(current): void;

  onClick?(): void;
}
