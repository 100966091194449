import React, { lazy, Suspense } from 'react';
import Popup from '@components/abstract/Popup';
import icnBeta from '@images/icn-beta.png';
import icnBack from '@images/icn-back.png';
import icnForward from '@images/icn-forward.png';
import IcnClose from '@components/icons/IcnClose';
import { connect } from 'dva';
import * as _ from 'lodash-es';
import cx from 'classnames';
import { getColorByType } from '@services/kg';
import { showAlert } from '@utils/browser';
import stl from './style.less';

const Graphin = lazy(() => import(/* webpackChunkName: "antv" */'@antv/graphin'));
// 缩放画布
const ZoomCanvas = lazy(() => import(/* webpackChunkName: "antv" */'@antv/graphin').then(v => ({ default: v.Behaviors.ZoomCanvas })));
// 点击选中节点
const ClickSelect = lazy(() => import(/* webpackChunkName: "antv" */'@antv/graphin').then(v => ({ default: v.Behaviors.ClickSelect })));

const layout = {
  type: 'force',
  nodeSize: 0,
  collideStrength: 1,
  nodeStrength: -18000,
  alpha: 1,
  alphaDecay: 0.8,
  alphaMin: 0,
};
const types = {
  symptom: '症状',
  drug: '药品',
  department: '科室',
  examine: '检查',
  disease: '疾病',
};

export class ExploreKG extends React.PureComponent<any, any> {
  graphinRef;

  bindGraph = elm => {
    if (elm) {
      this.graphinRef = elm.graph;
      this.graphinRef.on('node:click', evt => {
        const id = evt.item?._cfg?.model?.id;
        const clickable = evt.item?._cfg?.model?.clickable;
        if (!clickable) {
          return showAlert('科室、检查暂不支持探索');
        }
        const { data, dispatch } = this.props;
        dispatch({
          type: 'kg/createGraph',
          payload: {
            nodeList: _.reject(_.map(data.nodes, i => i.id), i => i === id),
            currentNodes: [id],
          },
        });
      });
    } else {
      this.graphinRef.off();
    }
  };

  handleRedo = () => {
    const { enableRedo, dispatch } = this.props;
    if (!enableRedo) {
      return;
    }
    dispatch({
      type: 'kg/redo',
    });
  };

  handleUndo = () => {
    const { enableUndo, dispatch } = this.props;
    if (!enableUndo) {
      return;
    }
    dispatch({
      type: 'kg/undo',
    });
  };

  close = () => {
    const { onClose, dispatch } = this.props;
    dispatch({
      type: 'kg/cleanData',
    });
    if (_.isFunction(onClose)) {
      onClose();
    }
  };

  componentDidUpdate(prevProps) {
    if (_.isEmpty(prevProps.data.nodes) && !_.isEmpty(this.props.data.nodes)) {
      /*
      * 没有找到合适的触发时机
      * 延迟只是一种折中方案，可能存在调整过早或者过晚的情况
      * 过早，表现出没有调整的状态
      * 过晚，表现出明显的缩放现象
      * */
      setTimeout(() => {
        this.graphinRef.fitView(20);
      }, 1000);
    }
  }

  render() {
    const { visible, data, enableUndo, enableRedo } = this.props;
    return (
      <Popup coverClass={stl.cover} customClass={stl.popup}
             visible={visible}>
        <Suspense fallback>
          <div className={stl.header}>
            <div className={stl.title}>图谱探索</div>
            <img className={stl.icnBeta} src={icnBeta} alt="测试版" />
            <div className={stl.icnClose} onClick={this.close}>
              <IcnClose />
            </div>
          </div>
          <div className={stl.graph}>
            <Graphin data={data} layout={layout}
                    ref={this.bindGraph}
                    minZoom={0.5} maxZoom={2}>
              <ZoomCanvas sensitivity={1} />
              <ClickSelect multiple={false} selectedState={'normal'} />
              <div className={stl.menu}>
                <img className={cx(stl.menuItem, { [stl.disabled]: !enableUndo })}
                    src={icnBack} alt="后退"
                    onClick={this.handleUndo} />
                <img className={cx(stl.menuItem, { [stl.disabled]: !enableRedo })}
                    src={icnForward} alt="前进"
                    onClick={this.handleRedo} />
              </div>
              <div className={stl.legend}>
                {_.map(types, (text, type) => (
                  <div className={stl.squareWrap} key={type}>
                    <div className={stl.square}
                        style={{ backgroundColor: getColorByType(type) }} />
                    <div className={stl.text}>{text}</div>
                  </div>
                ))}
              </div>
            </Graphin>
          </div>
        </Suspense>
      </Popup>
    );
  }
}

export default connect(({ kg }) => ({
  data: kg.present?.data,
  enableUndo: !!kg.past.length,
  enableRedo: !!kg.future.length,
}))(ExploreKG);
