import * as React from 'react';
import * as _ from 'lodash-es';
import Query from '@components/Query';
import { event } from '@libs/ga';
import cx from 'classnames';
import { connect } from 'dva';
import stl from './style.less';

export class HomeList extends React.PureComponent<any, any> {
  onClick = evt => {
    const { query } = evt.currentTarget.dataset;
    event({
      category: 'home-list_question',
      action: 'click',
      label: `${query}`,
    });
    window.location.href = `/search?q=${query}`;
  };

  render() {
    const { list, className } = this.props;
    const filteredList = _.filter(list, item => !item?.text?.includes('附近'));
    return !_.isEmpty(filteredList) && (
      <div className={cx(stl.block, className)}>
        {_.map(filteredList, (i, k) => (
          <div className={stl.item} key={k}
               onClick={this.onClick} data-query={i.text}>
            <Query item={i} />
          </div>
        ))}
      </div>
    );
  }
}

export default connect(({ search }) => ({
  list: search.queryList,
}))(HomeList);
