// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lines_UHIct {
  margin-top: 15px;
  margin-bottom: 12px;
}
.line_Wqla6 {
  font-size: 14px;
  color: #26334d;
  line-height: 1;
  margin-left: 15px;
}
.line_Wqla6:last-child {
  margin-top: 7px;
}
.bold_zgr8F {
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
}
.fill_wUdFg {
  display: inline-block;
  color: #26334d;
  font-size: 14px;
  line-height: 1;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px;
  margin: 0 6px;
  cursor: pointer;
}
.fill_wUdFg:hover {
  opacity: 0.6;
}
`, "",{"version":3,"sources":["webpack://./src/components/QueryCorrection/style.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;AACF;AACA;EACE,eAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;AACF;AAEA;EACE,eAAA;AAAF;AAGA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AADF;AAIA;EACE,qBAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,qCAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AAFF;AAKA;EACE,YAAA;AAHF","sourcesContent":[".lines {\n  margin-top: 15px;\n  margin-bottom: 12px;\n}\n.line {\n  font-size: 14px;\n  color: #26334d;\n  line-height: 1;\n  margin-left: 15px;\n}\n\n.line:last-child {\n  margin-top: 7px;\n}\n\n.bold {\n  font-size: 14px;\n  line-height: 1;\n  font-weight: 600;\n}\n\n.fill {\n  display: inline-block;\n  color: #26334d;\n  font-size: 14px;\n  line-height: 1;\n  border-radius: 4px;\n  background-color: rgba(0, 0, 0, 0.05);\n  padding: 8px;\n  margin: 0 6px;\n  cursor: pointer;\n}\n\n.fill:hover {\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lines": `lines_UHIct`,
	"line": `line_Wqla6`,
	"bold": `bold_zgr8F`,
	"fill": `fill_wUdFg`
};
export default ___CSS_LOADER_EXPORT___;
