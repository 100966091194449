import { setQuery } from '@utils/history';
import { savePkg } from '@services/app';
import { compact } from '@utils/index';
import _ from 'lodash';

export default {
  namespace: 'app',
  state: {
    tdk: {},
    noSearch: false,
    lon: null,
    lat: null,
  },
  effects: {
    * config({ payload }, { put, call }) {
      yield put({
        type: 'saveConfig',
        payload,
      });
      yield call(savePkg, payload.q, payload._pkg);
    },
    * setQuery({ payload }, { select }) {
      const { noSearch, lon, lat } = yield select(state => state.app);
      setQuery({
        ...payload,
        ...compact('s', noSearch ? '0' : null),
        ...compact('geo', _.compact([lon, lat]).toString()),
      });
      yield;
    },
  },
  reducers: {
    saveConfig(state, { payload }) {
      const noSearch = payload.s === '0';
      const [lon, lat] = (payload.geo || '').split(',');
      return {
        ...state,
        noSearch,
        lon,
        lat,
      };
    },
    setTDK(state, { payload: { tdk } }) {
      if (
        typeof document !== 'undefined' &&
        document.head &&
        document.head.querySelector
      ) {
        if (tdk.keywords) {
          document.head
                  .querySelector('meta[name="keywords"]')
                  .setAttribute('content', tdk.keywords);
        }
        if (tdk.description) {
          document.head
                  .querySelector('meta[name="description"]')
                  .setAttribute('content', tdk.description);
        }
        if (tdk.title) {
          document.head.querySelector('title').innerHTML = tdk.title;
        }
      }
      return { ...state, tdk: { ...state.tdk, ...tdk } };
    },
  },
};
