// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_SpPlu {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.minaPage_Yc1aq {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  height: 100vh;
}
.logoHome_NW3KG {
  flex: none;
  align-self: center;
  height: 33px;
  margin-top: 80px;
  margin-bottom: 10px;
}
.minaGoto_XkkeT {
  flex: none;
  align-self: center;
  height: auto;
  width: 100%;
}
.searchBar_P3xtD {
  padding: 0 15px;
}
.list_ndRbO {
  flex: none;
  width: auto;
  margin: 30px 15px;
}
.footer_khU81 {
  flex: none;
  margin-top: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/mobile/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AAHF;AAMA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,aAAA;AAJF;AAOA;EACE,UAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AALF;AAQA;EACE,UAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AANF;AASA;EACE,eAAA;AAPF;AAUA;EACE,UAAA;EACA,WAAA;EACA,iBAAA;AARF;AAWA;EACE,UAAA;EACA,gBAAA;AATF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.page {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n}\n\n.minaPage {\n  display: flex;\n  flex-direction: column;\n  background-color: #f9f9f9;\n  height: 100vh;\n}\n\n.logoHome {\n  flex: none;\n  align-self: center;\n  height: 33px;\n  margin-top: 80px;\n  margin-bottom: 10px;\n}\n\n.minaGoto {\n  flex: none;\n  align-self: center;  \n  height: auto;\n  width: 100%;\n}\n\n.searchBar {\n  padding: 0 15px;\n}\n\n.list {\n  flex: none;\n  width: auto;\n  margin: 30px 15px;\n}\n\n.footer {\n  flex: none;\n  margin-top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `page_SpPlu`,
	"minaPage": `minaPage_Yc1aq`,
	"logoHome": `logoHome_NW3KG`,
	"minaGoto": `minaGoto_XkkeT`,
	"searchBar": `searchBar_P3xtD`,
	"list": `list_ndRbO`,
	"footer": `footer_khU81`
};
export default ___CSS_LOADER_EXPORT___;
