import * as React from 'react';
import PicBgWrong from '@components/icons/PicBgWrong';
import history from '@utils/history';
import { event } from '@libs/ga';
import stl from './style.less';

export default class Wrong extends React.PureComponent<unknown, any> {
  click = () => {
    event({
      category: 'wrong_reload',
      action: 'click',
      label: '',
    });
    history.go(0);
  };

  render() {
    return (
      <div className={stl.block}>
        <div className={stl.bg}>
          <PicBgWrong />
        </div>
        <div className={stl.status}>当前访问人数过多</div>
        <div className={stl.hints}>请稍候重新尝试</div>
        <div className={stl.action} onClick={this.click}>点此重试</div>
      </div>
    );
  }
}
