import React from 'react';
import { get } from 'lodash';
import stl from './style.less';
import KgViews from '@components/KgViews';
import bg from '@assets/pic-texture.png';

export default function KgCard({ item, style = null }) {
  function handleClick() {
    KgViews.show(item);
  }

  return (
    <div className={stl.container} style={style} onClick={handleClick}>
      {getSubject(item)}
      <div className={stl.content}>{get(item, 'text')}</div>
      <div className={stl.from}>
        {get(item, 'from')}
        <i className={stl.iconArrow}></i>
      </div>
      <img className={stl.bg} src={bg} alt="背景图" />
    </div>
  );
}

function getSubject(item) {
  return (
    <div className={stl.subject}>
      <img className={stl.logo} src={get(item, 'logo')} alt="icon" />
      {get(item, 'entity')} · {get(item, 'predicate')}
    </div>
  );
}
