import moment from 'moment';

export function getYearDate(date: string) {
  return moment(date).format('YYYY年M月D日');
}

export function getDate(date: string) {
  return moment(date).format('M月D日');
}

// 返回 1 ～ 12月
export function getMonth(date: string) {
  return moment(date).month() + 1;
}

export function getMonthDay(date: string) {
  return moment(date).date();
}

export function getWeekday(date: string) {
  return moment(date).day();
}

export function getDateTime(date: string | number) {
  return moment(date).format('M月D日 HH:mm');
}

export function getTime(date: string) {
  return moment(date).format('HH:mm');
}

export function isDuringThisWeek(date: string, now = moment().format()) {
  const thisMonday = moment(now).isoWeekday(1);
  const nextMonday = moment(now).isoWeekday(8);
  return moment(date).isSameOrAfter(thisMonday, 'day') && moment(date).isBefore(nextMonday, 'day');
}

export function isDuringLastWeek(date: string, now = moment().format()) {
  const thisMonday = moment(now).isoWeekday(1);
  const lastMonday = moment(now).isoWeekday(-6);
  return moment(date).isSameOrAfter(lastMonday, 'day') && moment(date).isBefore(thisMonday, 'day');
}

export function isToday(date: string | number) {
  return moment(date).isSame(moment(), 'date');
}

export function isYesterday(date: string | number) {
  return moment(date).isSame(moment().subtract(1, 'day'), 'date');
}

export function isTomorrow(date: string | number) {
  return moment(date).isSame(moment().add(1, 'day'), 'date');
}
