// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_ozkYH {
  margin-top: 55px;
}
.line_Vg3Fk {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  margin-left: 15px;
  margin-right: 15px;
}
.contact_Pk8QF {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}
.contact_Pk8QF:active {
  background-color: #F5F5F5;
}
.name_ONA3M {
  font-size: 16px;
  font-weight: bold;
  color: #2C7EDB;
  margin-bottom: 12px;
}
.phone_mqGox {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.66);
}
.wrap_ej2zj {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #519BF0;
  border-radius: 50%;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/ContactListViews/style.less"],"names":[],"mappings":"AAIA;EACE,gBAAA;AAHF;AAMA;EACE,4CAAA;EACA,iBAAA;EACA,kBAAA;AAJF;AAOA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AALF;AAOE;EACE,yBAAA;AALJ;AASA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAPF;AAUA;EACE,eAAA;EACA,0BAAA;AARF;AAWA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;AATF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.block {\n  margin-top: 55px;\n}\n\n.line {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.04);\n  margin-left: 15px;\n  margin-right: 15px;\n}\n\n.contact {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 80px;\n  padding-left: 15px;\n  padding-right: 15px;\n  cursor: pointer;\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n}\n\n.name {\n  font-size: 16px;\n  font-weight: bold;\n  color: #2C7EDB;\n  margin-bottom: 12px;\n}\n\n.phone {\n  font-size: 15px;\n  color: rgba(0, 0, 0, 0.66);\n}\n\n.wrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 40px;\n  height: 40px;\n  background-color: #519BF0;\n  border-radius: 50%;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `block_ozkYH`,
	"line": `line_Vg3Fk`,
	"contact": `contact_Pk8QF`,
	"name": `name_ONA3M`,
	"phone": `phone_mqGox`,
	"wrap": `wrap_ej2zj`
};
export default ___CSS_LOADER_EXPORT___;
