import * as React from 'react';
import * as _ from 'lodash-es';
import icnArrow from '@assets/icon/icn-arrow-right@2x.png';
import stl from './style.less';
import config from '@config';

export default class DoctorItem extends React.PureComponent<IProps, any> {
  onClick = () => {
    const { item, onClick } = this.props;
    onClick(item);
  };

  render() {
    const { item } = this.props;
    return (
      <div className={stl.item} onClick={this.onClick}>
        <img className={stl.logo} src={item.avatar} />
        <div className={stl.info}>
          <div className={stl.nameWrap}>
            <div className={stl.name}>{item.name}</div>
            {item.hasOnline && config.sponsor.hasService && <Online className={stl.onlineTag} />}
          </div>
          {!_.isEmpty(item.tags) && (
            <div className={stl.tags}>
              {_.map(item.tags, tag => (
                <div className={stl.tag} key={tag}>
                  {tag}
                </div>
              ))}
            </div>
          )}
          <div className={stl.hospital}>{item.hospital}</div>
          {!_.isEmpty(item.departmentInfo) && (
            <div className={stl.department}>
              {item.departmentInfo}
            </div>
          )}
          {!_.isEmpty(item.expertise) && (
            <div className={stl.expertise}>
              擅长：{item.expertise}
            </div>
          )}
          {!_.isEmpty(item.onlineScore) && (
            <div className={stl.article}>
              {item.onlineScore}
            </div>
          )}
          {!_.isEmpty(item.paperScore) && (
            <div className={stl.consultant}>
              {item.paperScore}
            </div>
          )}
        </div>
        <img className={stl.arrow} src={icnArrow} />
      </div>
    );
  }
}

function Online(props) {
  const boxStyle = {
    fontSize: 12,
    color: '#17A193',
    padding: '2px 3px',
    borderRadius: 2,
    border: '1px solid rgba(23, 161, 147, 0.5)',
  };
  return <div {...props} style={boxStyle}>线上问诊</div>;
}

interface IProps {
  item: {
    tags: string[];
    name: string;
    hospital: string;
    avatar: string;
    departmentInfo: string;
    expertise: string;
    onlineScore: string;
    paperScore: string;
    hasOnline: boolean;
  };

  onClick(item): void;
}
