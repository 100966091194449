import rq from '@utils/request';
import { qsStringify } from '@utils/index';
import { withSign } from '@mina-modules/sign';
import config from '@config';
import { error, info } from '@libs/log';

function formatBaikeId(res = {} as any) {
  return {
    id: res.data?.unique_id,
    type: res.data?.view_type,
  };
}

export async function fetchBaikeId(query: string): Promise<any> {
  try {
    const data = qsStringify({
      query,
    });
    const url = `${config.HOST_JAVA}/romens/encyclopaedia/_index${data}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    info(resp);
    return formatBaikeId(resp);
  } catch (e) {
    error(e);
    throw e;
  }
}
