import { createGraph } from '@services/kg';
import { error } from '@libs/log';
import { showAlert } from '@utils/browser';
import * as _ from 'lodash-es';

const initState = {
  past: [],
  present: {
    data: {
      nodes: [],
      edges: [],
    },
    params: {
      idList: [],
      nodeList: [],
      currentNodes: [],
    },
  },
  future: [],
};
export default {
  namespace: 'kg',
  state: initState,
  effects: {
    * createGraph({ payload }, { call, put, select }) {
      try {
        const { present: { params } } = yield select(state => state.kg);
        const { idList, nodeList, currentNodes }: any = {
          ...params,
          ...payload,
        };
        const data = yield call(createGraph, idList, nodeList, currentNodes);
        if (_.isEmpty(data?.nodes)) {
          showAlert('数据为空');
        }
        yield put({
          type: 'createGraphSuccess',
          payload: data,
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
  },
  reducers: {
    createGraphSuccess(state, { payload }) {
      return {
        ...state,
        past: [
          ...state.past,
          state.present,
        ],
        present: {
          ...state.present,
          data: payload,
        },
        future: [],
      };
    },
    cleanData() {
      return initState;
    },
    undo(state) {
      return {
        ...state,
        past: _.initial(state.past),
        present: _.last(state.past),
        future: [
          state.present,
          ...state.future,
        ],
      };
    },
    redo(state) {
      return {
        ...state,
        past: [
          ...state.past,
          state.present,
        ],
        present: _.first(state.future),
        future: state.future.slice(1),
      };
    },
  },
};
