import * as React from 'react';
import config from '@config';

export default class Logo extends React.PureComponent<IProps, any> {
  get logo() {
    return config.sponsor.logo;
  }

  get product() {
    return config.sponsor.product;
  }

  render() {
    const { className } = this.props;
    return (
      <img className={className} src={this.logo} alt={this.product} />
    );
  }
}

interface IProps {
  className?: string;
}
