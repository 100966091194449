import React from 'react';
import { cloneDeep, map, set } from 'lodash-es';
import SidebarViews from '@components/SidebarViews';
import { pageview } from '@libs/ga';
import { createRoot } from 'react-dom/client';
let root;
import stl from './style.less';
import { getSourceLogo } from '@utils/logo';
import Comments from '@components/Comments';
import NextAIViews from '@components/NextAIViews';
import { fetchProperties } from '@services/search';
import cx from 'classnames';

export default class KgViews extends React.PureComponent<any, any> {
  static show(item) {
    const elm = document.createElement('div');
    elm.id = `KgViews`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<KgViews item={item} />);
  }

  private _contact: any;

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_kg`);
    this.state = {
      visible: !!props.item,
      list: [],
    };
  }

  componentDidMount() {
    const { id, type } = this.props.item;
    fetchProperties(id, type).then(({list, contact}) => {
      this.setState({list});
      this._contact = contact;
    });
  }

  close = () => {
    const elm = document.getElementById(`KgViews`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  toNextAI = () => {
    NextAIViews.show(this._contact || true);
  };

  unfold = (item) => {
    set(item, 'showMore', true);
    this.setState({
      list: cloneDeep(this.state.list),
    });
  };

  render() {
    const { item } = this.props;
    const { visible, list } = this.state;

    return (
      <SidebarViews visible={visible} onClose={this.close}>
        <div className={stl.subject}>{item.entity} · {item.predicate}</div>
        <div className={stl.content}>
          {item.text}
        </div>
        <div className={stl.from}>
          <Comments
            style={{ flex: 1, color: '#26334d' }}
            reference={item.from}
            logo={getSourceLogo(item.logo)}
            onClick={this.toNextAI}
          />
        </div>
        <div className={stl.split}></div>
        <div className={stl.title}>{item.entity} · 其他属性</div>
        {map(list, (other, index) => (
          <div className={stl.listItem} key={index}>
            <img
              className={stl.logo}
              src={other.logo}
              alt="icon"
            />
            <div style={{display: 'flex', flexDirection: 'column', width: 0, flex: 1}}>
              <div className={stl.itemTitle}>{other.name}</div>
              <div className={cx(stl.itemText, {[stl.fold]: !other.showMore})}>
                {other.showMore || <span className={stl.moreBtn} onClick={() => this.unfold(other)}>更多</span>}
                {other.text}
              </div>
            </div>
          </div>
        ))}
        <div style={{height: 80}}></div>
      </SidebarViews>
    );
  }
}
