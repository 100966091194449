import User from '../utils/user';

const initialState = {
  error: null,
};
export default {
  namespace: 'user',
  state: initialState,
  effects: {
    // eslint-disable-next-line no-empty-pattern
    *login({}, { put }) {
      try {
        yield User.login();
      } catch (error) {
        yield put({
          type: 'loginFail',
          error,
        });
      }
    },
  },
  reducers: {
    loginFail(state, { error }) {
      return {
        ...state,
        error: error.code,
      };
    },
  },
};
