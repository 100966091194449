import {
  fetchDisease,
  fetchDoctorIntent,
  fetchDrug,
  fetchExamine,
  fetchHospitalIntent,
  fetchHybridIntent,
  fetchPaper,
  fetchPredictList,
  fetchQa,
  fetchVaccine,
  initList,
  search,
  suggest,
} from '@services/search';
import * as _ from 'lodash-es';
import { compact, withDefault } from '@utils/index';
import { os, scrollToTop } from '@utils/browser';
import { error } from '@libs/log';
import BaikeViews from '@components/BaikeViews';
import FaqViews from '@components/FaqViews';
import QaViews from '@components/QaViews';
import PaperViews from '@components/PaperViews';
import VaccineViews from '@components/VaccineViews';
import PredictMobileViews from '@components/mobile/PredictViews';
import PredictPCViews from '@components/pc/PredictViews';

const initialState = {
  query: '',
  queryCorrection: {},
  commonSearch: false,
  isBlur: false,
  state: null,
  covid: [],
  types: [],
  entityNameList: [],
  departmentList: [],
  drugList: [],
  diseaseList: [],
  examineList: [],
  hospitalList: [],
  bestFaqList: [],
  faqList: [],
  opinionList: [],
  queryList: [],
  relatedList: [],
  hybridList: [],
  suggestList: [],
  hospitalItem: null,
  registrationItem: null,
  detailItem: null,
  contactList: null,
  mpList: null,
  doctorIntent: {},
  hospitalIntent: {},
  drugstoreIntent: {},
  relatedIntent: {},
  faqOpinionIntent: {},
  baikeIntent: {},
  hybridIntent: {},
  predict: {},
  intent: {},
};
export default {
  namespace: 'search',
  state: initialState,
  effects: {
    * relatedIntent({ payload: { intent } }, { put, select }) {
      try {
        const { query } = yield select(state => state.search);
        yield put({
          type: 'search',
          payload: {
            query,
            intentQuery: intent.query,
          },
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchHybridIntent(__, { call, put, select }) {
      try {
        const { hybridIntent, query } = yield select(state => state.search);
        const res = yield call(fetchHybridIntent, query, hybridIntent.url);
        yield put({
          type: 'fetchHybridIntentSuccess',
          payload: {
            hybridIntent: res,
          },
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchDoctorIntent({ payload }, { call, put, select }) {
      try {
        const { doctorIntent: { params } } = yield select(state => state.search);
        const res = yield call(fetchDoctorIntent, {
          ...params,
          ...payload,
        });
        yield put({
          type: 'fetchDoctorIntentSuccess',
          payload: {
            doctorIntent: res,
          },
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchHospitalIntent({ payload }, { call, put, select }) {
      try {
        const { hospitalIntent: { params } } = yield select(state => state.search);
        const res = yield call(fetchHospitalIntent, {
          ...params,
          ...payload,
        });
        yield put({
          type: 'fetchHospitalIntentSuccess',
          payload: {
            hospitalIntent: res,
          },
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * suggest({ payload: { value } }, { call, put }) {
      try {
        let list = [];
        if (_.size(value) >= 2) {
          list = yield call(suggest, value);
        }
        yield put({
          type: 'setSuggestList',
          payload: {
            list,
          },
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * predict(__, { put, select }) {
      try {
        const { query, intent, expectedIntent, faqOpinionIntent } = yield select(state => state.search);
        const faqIds = _.compact([expectedIntent, ...faqOpinionIntent.list].map(item => item.id));
        if (!faqIds.length) return;
        yield put({
          type: 'setPredict',
          payload: {
            value: yield fetchPredictList(query, intent.id, faqIds),
          },
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchDrug({ payload: { item, key } }, { call }) {
      try {
        const drug = yield call(fetchDrug, item);
        BaikeViews.show(drug, 'drug', key || item.key);
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchDisease({ payload: { item, key } }, { call }) {
      try {
        const disease = yield call(fetchDisease, item.id);
        BaikeViews.show(disease, 'disease', key || item.key);
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchFaq({ payload: { id } }, { select }) {
      try {
        const { query } = yield select(state => state.search);
        const faq = { id, query };
        FaqViews.show(faq, 'faq');
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchQa({ payload: { id } }, { call }) {
      try {
        const faq = yield call(fetchQa, id);
        QaViews.show(faq, 'qa');
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchPaper({ payload: { id } }, { call }) {
      try {
        const faq = yield call(fetchPaper, id);
        PaperViews.show(faq, 'paper');
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchExamine({ payload: { item, key } }, { call }) {
      try {
        const examine = yield call(fetchExamine, item.id, item.name);
        BaikeViews.show(examine, 'examine', key || item.key);
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchVaccine({ payload: { id } }, { call }) {
      try {
        const item = yield call(fetchVaccine, id);
        VaccineViews.show(item);
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * fetchPredict({ payload: { item } }, { put, select }) {
      const PredictViews = os.isMobile ? PredictMobileViews : PredictPCViews;
      const isBlurSearch = os.isMobile;
      yield put({
        type: 'setBlur',
        payload: {
          visible: isBlurSearch,
        },
      });
      try {
        const { predict } = yield select(state => state.search);
        PredictViews.show({
          title: predict.title,
          list: predict.list,
          current: item,
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * search({ payload: { query, intentQuery, direct } }, { call, put, select }) {
      try {
        yield put({
          type: 'setQuery',
          payload: {
            value: query,
          },
        });
        yield put({
          type: 'setSuggestList',
          payload: {
            list: [],
          },
        });
        if (!query) {
          return;
        }
        const { lon, lat } = yield select(state => state.app);
        const res = yield call(
          search,
          withDefault(intentQuery, query),
          direct,
          lon,
          lat,
        );

        scrollToTop();
        yield put({
          type: 'searchSuccess',
          payload: {
            res,
          },
        });
        if (res.intent.id) {
          yield put({
            type: 'predict',
          });
        }
        if (res.commonSearch) {
          yield put({
            type: 'fetchHybridIntent',
          });
        }
        yield put({
          type: 'app/setQuery',
          payload: {
            ...compact('q', query),
            ...compact('d', direct),
          },
        });
        yield put({
          type: 'app/setTDK',
          payload: {
            tdk: {
              title: `${query}`,
            },
          },
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
    * init(__, { call, put }) {
      try {
        const res = yield call(initList);
        yield put({
          type: 'initSuccess',
          payload: res,
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
  },
  reducers: {
    fetchHybridIntentSuccess(state, { payload: { hybridIntent } }) {
      return {
        ...state,
        hybridIntent: {
          list: _.concat(state.hybridIntent.list || [], hybridIntent.list),
          url: hybridIntent.url,
        },
      };
    },
    fetchDoctorIntentSuccess(state, { payload: { doctorIntent } }) {
      return {
        ...state,
        doctorIntent: {
          list: _.concat(doctorIntent.params.page === 1 ? [] : state.doctorIntent.list, doctorIntent.list),
          url: doctorIntent.url,
          params: doctorIntent.params,
        },
      };
    },
    fetchHospitalIntentSuccess(state, { payload: { hospitalIntent } }) {
      return {
        ...state,
        hospitalIntent: {
          list: _.concat(hospitalIntent.params.page === 1 ? [] : state.hospitalIntent.list, hospitalIntent.list),
          url: hospitalIntent.url,
          params: hospitalIntent.params,
        },
      };
    },
    setSuggestList(state, { payload: { list } }) {
      return {
        ...state,
        suggestList: list,
      };
    },
    setQuery(state, { payload: { value } }) {
      return {
        ...state,
        query: value,
      };
    },
    searchSuccess(state, { payload: { res } }) {
      return {
        ...state,
        ...res,
        hybridIntent: {},
      };
    },
    initSuccess(state, { payload: { data } }) {
      return {
        ...state,
        queryList: _.map(data, (i) => {
          return {
            ...i,
            text: i.query,
          };
        }),
      };
    },
    setRegistrationItem(state, { payload: { item } }) {
      return {
        ...state,
        registrationItem: item,
        detailItem: null,
        contactList: null,
        mpList: null,
      };
    },
    setDetailItem(state, { payload: { item } }) {
      return {
        ...state,
        detailItem: item,
        registrationItem: null,
        contactList: null,
        mpList: null,
      };
    },
    setHospitalItem(state, { payload: { item } }) {
      return {
        ...state,
        hospitalItem: item,
        detailItem: null,
        suggestItem: null,
        registrationItem: null,
        contactList: null,
        mpList: null,
      };
    },
    setContactList(state, { payload: { list } }) {
      return {
        ...state,
        contactList: list,
        detailItem: null,
        registrationItem: null,
        mpList: null,
      };
    },
    setMpList(state, { payload: { list } }) {
      return {
        ...state,
        mpList: list,
        detailItem: null,
        registrationItem: null,
        contactList: null,
      };
    },
    setPredict(state, { payload: { value } }) {
      return {
        ...state,
        predict: value,
      };
    },
    setBlur(state, { payload: { visible, style } }) {
      return {
        ...state,
        isBlur: visible,
        blurStyle: style,
      };
    },
  },
};
