import * as React from 'react';
import * as _ from 'lodash-es';
import { event } from '@libs/ga';
import { os } from '@utils/browser';
import { connect } from 'dva';
import FeedbackViews from '@components/mobile/FeedbackViews';
import cx from 'classnames';
import ShadowLine from '@components/ShadowLine';
import TabViews from '@components/TabViews';
import IcnWikiText from '@components/icons/IcnWikiText';
import stl from './style.less';
import Comments from '@components/Comments';

export class BaikeCard extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    const current = Math.max(_.findIndex(_.get(props.item, 'detailList'), i => i.key === _.get(props.item, 'key')), 0);
    this.state = {
      current,
    };
  }

  get item() {
    const { item } = this.props;
    const { current } = this.state;
    return _.get(item, `detailList[${current}]`, {});
  }

  change = current => {
    this.setState({
      current,
    });
  };

  showFeedback = () => {
    event({
      category: 'maincard_showfeedback',
      action: 'click',
      label: '',
    });
    FeedbackViews.show();
  };

  toView = () => {
    const { item } = this.props;
    event({
      category: 'baike-card_toview',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    switch (item.type) {
      case 'disease':
        return this.props.dispatch({
          type: 'search/fetchDisease',
          payload: {
            item,
            key: this.item.key,
          },
        });
      case 'drug':
        return this.props.dispatch({
          type: 'search/fetchDrug',
          payload: {
            item,
            key: this.item.key,
          },
        });
      case 'examine':
        return this.props.dispatch({
          type: 'search/fetchExamine',
          payload: {
            item,
            key: this.item.key,
          },
        });
    }
  };

  render() {
    const { item } = this.props;
    const { current } = this.state;
    return (
      <div className={cx(stl.card, { [stl.pc]: os.isPc })}>
        <div className={stl.context}>
          <IcnWikiText />
          {os.isMobile && (
            <div className={stl.feedback} onClick={this.showFeedback}>问题反馈</div>
          )}
        </div>
        <div className={stl.title}>{item.name}</div>
        <TabViews current={current} list={item.detailList}
                  onChange={this.change} onClick={this.toView}
                  idPrefix={'BaikeCard'} valueStyle={{height: 174, cursor: 'pointer'}} />
        <Comments style={{paddingTop: 15, paddingBottom: 25, cursor: 'pointer'}} logo={this.item.logo} reference={this.item.source} onClick={this.toView} />
        {os.isMobile && (
          <ShadowLine />
        )}
      </div>
    );
  }
}

export default connect()(BaikeCard);
