import React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import SidebarViews from '@components/SidebarViews';
import { pageview } from '@libs/ga';
import { createRoot } from 'react-dom/client';
let root;
import stl from './style.less';
import { getSourceLogo } from '@utils/logo';
import Navigation from '@components/Navigation';
import { formatArticle } from '@utils/index';
// import { navigateByType } from '@utils/browser';

export default class QaViews extends React.PureComponent<IProps, any> {
  static show(item, type) {
    const elm = document.createElement('div');
    elm.id = `${type}_${item.id}`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<QaViews item={item} type={type} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_faq`);
    this.state = {
      visible: !!props.item,
    };
  }

  close = () => {
    const { item, type } = this.props;
    const elm = document.getElementById(`${type}_${item.id}`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  toAuthor = (author) => {
    global['app']._store.dispatch({
      type: 'doctor/fetchDoctor',
      payload: {
        id: author.id,
      },
    });
  };

  // toView = () => {
  //   navigateByType(this.props.item);
  // };

  render() {
    const { item } = this.props;
    const { visible } = this.state;
    return (
      <>
        <SidebarViews visible={visible} onClose={this.close}>
          {!!item && (
            <div className={stl.container}>
              <div className={stl.scrollBox}>
                <div className={stl.title}>{item.title}</div>
                <div className={stl.authorBlock}>
                  <div className={stl.authors}>
                    <div className={stl.label}>作者：</div>
                    <div className={stl.authorWrap}>
                      {_.map(item.authorList, (author, index) => {
                        return (
                          <div
                            className={cx(stl.author, author.id && stl.strong)}
                            key={author.name}
                            onClick={() => author.id && this.toAuthor(author)}>
                            {`${author.name}${
                              index < item.authorList.length - 1 ? '・' : ''
                            }`}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {item.date && (
                    <div className={stl.date}>{`日期：${item.date}`}</div>
                  )}
                  <div className={stl.date}>{`刊名：${item.journal}`}</div>
                </div>
                <div className={stl.summary}>{formatArticle(item.summary)}</div>
              </div>
              <div className={stl.viewMoreBox}>
                <Navigation
                  name={item.source}
                  logo={getSourceLogo(item.logo)}
                  mina={item.mina}
                  url={item.url}
                  // onClick={this.toView}
                />
              </div>
            </div>
          )}
        </SidebarViews>
      </>
    );
  }
}

interface IProps {
  type: string;
  item: {
    id: string,
    title: string,
    summary: string,
    category: string,
    date: string,
    url: string,
    mina: any,
    source: string,
    logo: any,
    journal: string,
    authorList: any[],
  };
}
