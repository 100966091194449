import * as React from 'react';
import stl from './style.less';
import { connect } from 'dva';
import DiseaseTopicContent from '@components/DiseaseTopicContent';

@connect(({ disease }) => ({
  item: disease,
}))
export default class DiseaseTopic extends React.PureComponent<any, any> {
  render() {
    const { item } = this.props;
    return (
      <div className={stl.page}>
        <DiseaseTopicContent item={item} />
      </div>
    );
  }
}
