import { error } from '@libs/log';
import { fetchDoctor } from '@services/search';
import DoctorViews from '@components/DoctorViews';

export default {
  namespace: 'doctor',
  state: {},
  effects: {
    * fetchDoctor({ payload: { id } }, { call }) {
      try {
        const doctor = yield call(fetchDoctor, id);
        DoctorViews.show(doctor);
      } catch (e) {
        error(e);
        throw e;
      }
    },
  },
  reducers: {},
};
