import * as React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import ActivityViews from '@components/ActivityViews';
import ClipboardJS from 'clipboard';
import { email } from '@constants/index';
import { pageview } from '@libs/ga';
import stl from './style.less';

let clipboard;
export default class DutyViews extends React.PureComponent<unknown, any> {
  static show() {
    const elm = document.createElement('div');
    elm.id = `duty`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<DutyViews />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_duty`);
    this.state = {
      visible: true,
    };
  }

  close = () => {
    const elm = document.getElementById(`duty`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount(); 
        document.body.removeChild(elm);
      }, 200);
    }
  };

  componentDidMount() {
    clipboard = new ClipboardJS(`#copyField`);
  }

  componentWillUnmount() {
    clipboard.destroy();
  }

  render() {
    const { visible } = this.state;
    return (
      <ActivityViews visible={visible} onClose={this.close}>
        <>
          <div className={stl.header}>免责声明</div>
          <div className={stl.desc}>
            本小程序所载文章及其他资源仅为提供更多信息，仅供参考使用，不构成任何学术或医疗建议。其中部分资源来自互联网，如果你认为该整理或转载侵犯了你的权益，请与我们联系。
          </div>
          <div className={stl.desc}>本小程序上的任何信息如涉及描述或评论，仅代表作者本人的观点，与本小程序的立场无关。</div>
          <div className={stl.desc}>本声明以及其修改权、更新权及最终的解释权均属「无码科技」所有。如您有任何问题，请与我们联系。</div>
          <div className={stl.contact}>点击可复制联系方式 <span className={stl.strong} id={'copyField'}
                                                       data-clipboard-text={email}>{email}</span></div>
        </>
      </ActivityViews>
    );
  }
}
