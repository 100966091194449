// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_U8_Vf {
  overflow: hidden;
  margin-top: 20px;
}
.card_U8_Vf.pc_uFAqL {
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
}
.context_e2JBu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #F5FAFF;
  padding: 0 15px;
}
.feedback_n_4q8 {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.title_JNr7r {
  font-size: 20px;
  font-weight: bold;
  color: #26334D;
  margin: 20px 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/BaikeCard/style.less"],"names":[],"mappings":"AAIA;EACE,gBAAA;EACA,gBAAA;AAHF;AAKE;EACE,mBAAA;EACA,kBAAA;EACA,qCAAA;EACA,sBAAA;AAHJ;AAOA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AALF;AAQA;EACE,eAAA;EACA,yBAAA;AANF;AASA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AAPF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.card {\n  overflow: hidden;\n  margin-top: 20px;\n\n  &.pc {\n    margin-bottom: 20px;\n    border-radius: 8px;\n    border: 1px solid @black04;\n    box-sizing: border-box;\n  }\n}\n\n.context {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 50px;\n  background-color: #F5FAFF;\n  padding: 0 15px;\n}\n\n.feedback {\n  font-size: 13px;\n  color: @black50;\n}\n\n.title {\n  font-size: 20px;\n  font-weight: bold;\n  color: #26334D;\n  margin: 20px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `card_U8_Vf`,
	"pc": `pc_uFAqL`,
	"context": `context_e2JBu`,
	"feedback": `feedback_n_4q8`,
	"title": `title_JNr7r`
};
export default ___CSS_LOADER_EXPORT___;
