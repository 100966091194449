import * as React from 'react';
import * as _ from 'lodash-es';
import { createRoot } from 'react-dom/client';
let root;
import SidebarViews from '@components/SidebarViews';
import icnArrow from '@assets/icon/icn-arrow-right@2x.png';
import { event, pageview } from '@libs/ga';
import stl from './style.less';
import { navigateByType } from '@utils/browser';

export default class ConsultantListViews extends React.PureComponent<any, any> {
  static show(list) {
    const elm = document.createElement('div');
    elm.id = `consultantlist`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<ConsultantListViews list={list} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_consultant_list`);
    this.state = {
      visible: !!props.list,
    };
  }

  toService = evt => {
    const { index } = evt.currentTarget.dataset;
    const item = this.props.list[index];
    event({
      category: 'consultant-list-views_toservice',
      action: 'click',
      label: ``,
    });
    navigateByType(item);
  };

  close = () => {
    const elm = document.getElementById(`consultantlist`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { list } = this.props;
    const { visible } = this.state;
    return (
      <SidebarViews visible={visible} onClose={this.close}>
        {!!list && (
          <>
            <div className={stl.title}>线上问诊</div>
            <div className={stl.desc}>该医生在以下平台提供服务</div>
            <div className={stl.line} />
            {_.map(list, (item, index) => (
              <div key={item.name}>
                <div className={stl.item}
                     onClick={this.toService} data-index={index}>
                  <img className={stl.photo} src={item.logo} />
                  <div className={stl.info}>
                    <div className={stl.name}>{item.name}</div>
                    {_.map(item.service, (service, key) => (
                      <div className={stl.price} key={key}>
                        {service}
                      </div>
                    ))}
                  </div>
                  <img className={stl.arrow} src={icnArrow} />
                </div>
                <div className={stl.line} />
              </div>
            ))}
          </>
        )}
      </SidebarViews>
    );
  }
}
