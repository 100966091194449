// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mask_OzeZm {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}
.mask_OzeZm.show_CpAAi {
  pointer-events: auto;
  opacity: 0.9;
}
.backdrop_txvUw {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}
.backdrop_txvUw.show_CpAAi {
  pointer-events: auto;
  opacity: 1;
  transition: opacity 0.3s 0.2s;
}
.close_bAM5p {
  display: flex;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: #909499;
  position: fixed;
  left: 50%;
  bottom: 38px;
  transform: translateX(-50%);
}
.close__img_gwuJ1 {
  height: 16px;
  width: 16px;
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/mobile/MaskViews/style.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,oBAAA;EACA,UAAA;EACA,wBAAA;AACF;AAEA;EACE,oBAAA;EACA,YAAA;AAAF;AAGA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,UAAA;EACA,wBAAA;AADF;AAIA;EACE,oBAAA;EACA,UAAA;EACA,6BAAA;AAFF;AAKA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,SAAA;EACA,YAAA;EACA,2BAAA;AAHF;AAMA;EACE,YAAA;EACA,WAAA;EACA,YAAA;AAJF","sourcesContent":[".mask {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background: #f5f7fa;\n  pointer-events: none;\n  opacity: 0;\n  transition: opacity .3s;\n}\n\n.mask.show {\n  pointer-events: auto;\n  opacity: 0.9;\n}\n\n.backdrop {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n  opacity: 0;\n  transition: opacity .2s;\n}\n\n.backdrop.show {\n  pointer-events: auto;\n  opacity: 1;\n  transition: opacity .3s .2s;\n}\n\n.close {\n  display: flex;\n  width: 66px;\n  height: 66px;\n  border-radius: 50%;\n  background-color: #909499;\n  position: fixed;\n  left: 50%;\n  bottom: 38px;\n  transform: translateX(-50%);\n}\n\n.close__img {\n  height: 16px;\n  width: 16px;\n  margin: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mask": `mask_OzeZm`,
	"show": `show_CpAAi`,
	"backdrop": `backdrop_txvUw`,
	"close": `close_bAM5p`,
	"close__img": `close__img_gwuJ1`
};
export default ___CSS_LOADER_EXPORT___;
