import history from '@utils/history';
import * as _ from 'lodash-es';
import {error} from '@libs/log';
import {fetchBaikeId} from '@services/baike';
import {fetchDisease} from '@services/search';
import {
  fetchDiseaseArticle,
  fetchDiseaseArticles,
  fetchDiseaseArticleTypes,
} from '@services/disease';

export default {
  namespace: 'disease',
  state: {
    disease: {},
    topic: '',
    topics: [],
    article: {},
    articles: [],
    page: 1,
  },
  effects: {
    *search({ payload }, { call, put }) {
      try {
        const { id, type } = yield call(fetchBaikeId, payload.query);
        if (type !== 'disease_encyclopedia') {
          return redirectToBaike(payload.query);
        }

        const disease = yield call(fetchDisease, id, 'TEXT');
        const topics = yield call(fetchDiseaseArticleTypes, disease.id);
        if (!topics.length) {
          return redirectToBaike(payload.query);
        }

        const topic = topics[0];
        const articles = yield call(
          fetchDiseaseArticles,
          disease.id,
          topic.value,
          1,
          20
        );

        yield put({
          type: 'update',
          payload: {
            disease,
            topic,
            topics,
            articles,
          },
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
    *loadDiseaseArticle({ payload }, { call, put }) {
      const article = yield call(fetchDiseaseArticle, payload.id);
      yield put({
        type: 'update',
        payload: {
          article,
        },
      });
    },
    *loadDiseaseArticles({ payload }, { call, put }) {
      const articles = yield call(
        fetchDiseaseArticles,
        payload.id,
        payload.topic,
        payload.page,
        20
      );
      yield put({
        type: 'fetchDiseaseArticlesSuccess',
        payload: {
          articles,
          page: payload.page,
        },
      });
    },
  },
  reducers: {
    fetchDiseaseArticlesSuccess(state, { payload: { articles, page } }) {
      return {
        ...state,
        articles: _.concat(page === 1 ? [] : state.articles, articles),
      };
    },
    update(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

function redirectToBaike(q) {
  history.replace(`/bk?q=${q}`);
}
