import * as React from 'react';
import stl from './style.less';
import IcnOutOfService from '@components/icons/IcnOutOfService';

export default class NoMedicalResult extends React.PureComponent<unknown, any> {
  render() {
    return (
      <>
        <div className={stl.icon}>
          <IcnOutOfService />
        </div>
        <div className={stl.tips}>这个问题超出了我的能力范围</div>
        <div className={stl.tips}>请换一个医疗问题 :-)</div>
      </>
    );
  }
}
