import * as React from 'react';
import * as _ from 'lodash-es';
import stl from './style.less';
import config from '@config';

export default class DoctorItemSimple extends React.PureComponent<IProps, any> {
  onClick = () => {
    const { item, onClick } = this.props;
    onClick(item);
  };

  render() {
    const { item } = this.props;
    return (
      <div className={stl.item} onClick={this.onClick}>
        <img className={stl.logo} src={item.avatar} />
        <div className={stl.info}>
          <div className={stl.name}>
            {item.name}
            {item.department && (
              <div className={stl.department}>{item.department}</div>
            )}
            {item.hasOnline && config.sponsor.hasService && <Online className={stl.onlineTag} />}
          </div>
          {!_.isEmpty(item.tags) && (
            <div className={stl.tags}>
              {_.map(item.tags, tag => (
                <div className={stl.tag} key={tag}>
                  {tag}
                </div>
              ))}
            </div>
          )}
          {item.multiSiteHospital && (
            <div className={stl.primary}>主执业点：{item.multiSiteHospital}</div>
          )}
          {item.expertiseArea && (
            <div className={stl.expertise}>{item.expertiseArea}</div>
          )}
        </div>
      </div>
    );
  }
}

function Online(props) {
  const boxStyle = {
    fontSize: 12,
    color: '#17A193',
    padding: '2px 3px',
    borderRadius: 2,
    border: '1px solid rgba(23, 161, 147, 0.5)',
  };
  return <div {...props} style={boxStyle}>线上问诊</div>;
}

interface IProps {
  item: {
    tags: string[];
    name: string;
    department: string;
    avatar: string;
    multiSiteHospital: string;
    expertiseArea: string;
    hasOnline: boolean;
  };

  onClick(item): void;
}
