import rq from '@utils/request';
import { compact } from '@utils/index';
import { withSign } from '@mina-modules/sign';
import config from '@config';
import { event } from '@libs/ga';
import { error, info } from '@libs/log';

export async function feedback(query, result, comment) {
  try {
    event({
      category: 'feedback',
      action: 'click',
      label: `q=${encodeURIComponent(query)}`,
    });
    const url = `${config.HOST_JAVA}/feedback`;
    const data = {
      ...compact('raw_query', query),
      ...compact('comment', comment),
      ...compact('page_path', window.location.href),
      ...compact('intent', result?.data?.intent?.data?.id),
    };
    info(`post ${url}`);
    await rq(withSign({
      url,
      method: 'post',
      data,
    }));
    return true;
  } catch (e) {
    error(e);
    throw e;
  }
}
