import rq from '@utils/request';
import { compact, formatText, qsStringify, typeOf, withDefault } from '@utils/index';
import { withSign } from '@mina-modules/sign';
import * as _ from 'lodash-es';
import { TXYD } from '@constants/index';
import config from '@config';
import { getBackgroundColorByType, getSourceLogo, getTypeLogo } from '@utils/logo';
import { event } from '@libs/ga';
import { os } from '@utils/browser';
import { error, info } from '@libs/log';
import icnHospital from '@assets/hospital-default@2x.png';
import { generateQueryId } from '@utils/cache';
import moment from 'moment';

type BaikeFormatter = 'TEXT' | 'MD';

export function getDetailList(detail) {
  return _.reduce(detail, (result, i) => {
    let value = i.value;
    const source = i.source;
    const temp = {
      contentType: i.content_type,
      label: i.name,
      source,
      value,
      key: i.key,
      url: i.url,
      mina: {
        appid: _.get(i, 'mina.appid'),
        path: _.get(i, 'mina.path'),
      },
      logo: getSourceLogo(i.logo),
    };
    if (_.isString(value)) {
      if (source === TXYD) {
        value = splitTengXunYiDian(value);
      }
      if (temp.contentType === 'text') {
        temp.value = formatText(formatMultiSpace(value));
      }
    }
    result.push(temp);
    return result;
  }, []);
}

export function getSummaryList(detail) {
  return _.reduce(detail, (result, v, k) => {
    result.push({
      label: k,
      value: _.isString(v) ? formatText(v) : v,
      valueType: typeOf(v),
    } as ISummary);
    return result;
  }, []);
}

export interface ISummary {
  label: string;
  value: string | any;
  valueType: string;
}

export function splitTengXunYiDian(text) {
  return /[。！？]$/.test(text) ? text : text.concat('...');
}

export function formatMultiSpace(text) {
  return withDefault(text, '').replace(/(\s)\s*/g, '$1');
}

function formatHospitalList(res) {
  return _.map(res, i => ({
    id: i.unique_id,
    name: formatText(i.name),
    grade: i.grade,
    tags: i.tags,
    serviceTag: i.service_tag,
    address: formatText(i.address),
    summaryList: getSummaryList(i.prior_info || []),
    priorInfo: i.department_level_description || '',
    avatar: withDefault(i.avatar_url, icnHospital),
    distance: i.distance,
    source: i.source,
    url: i.url,
    mina: {
      appid: _.get(i, 'mina.appid'),
      path: _.get(i, 'mina.path'),
    },
    geo: i.geo,
    contacts: i.contacts,
    regInfo: i.reg_info,
    rankInfo: {
      list: _.map(_.get(i, 'rank_info.data'), r => ({
        name: r.name,
        rank: r.rank,
        query: r.query,
      })),
      source: _.get(i, 'rank_info.meta.source'),
    },
    mpList: _.map(_.get(i, 'wechat_infos'), r => ({
      id: r.uid,
      name: r.name,
      url: r.url,
      regUrl: r.reg_url,
    })),
    branchList: _.map(i.branches, branch => ({
      name: formatText(branch.name),
      address: formatText(branch.address),
      prefix: branch.branch_suffix,
      geo: branch.geo,
    })),
  }));
}

function formatDrugstoreList(res) {
  return _.map(res, i => ({
    id: i.unique_id,
    name: formatText(i.name),
    address: formatText(i.address),
    contacts: i.contacts,
    geo: i.geo,
    distance: i.distance,
    businessState: i.business_state,
    isMedicare: i.is_medicare,
    openingHours: i.opening_hours,
  }));
}

function formatVaccineList(res) {
  return _.map(res, formatVaccine);
}

function formatVaccine(res) {
  return {
    id: res.unique_id,
    name: formatText(res.name),
    address: formatText(res.address),
    contacts: res.contacts,
    geo: res.geo,
    distance: res.distance,
    tagList: res.tags,
  };
}

function formatFaqOpinionList(res) {
  const list = _.map(res, i => ({
    id: i.unique_id,
    title: formatText(i.title),
    drugTradeName: i.drug_trade_name,
    value: formatText(i.content),
    source: i.source,
    logo: getSourceLogo(i.logo),
    url: i.url,
    mina: {
      appid: _.get(i, 'mina.appid'),
      path: _.get(i, 'mina.path'),
    },
    doctor: i.doctor ? formatDoctor(i.doctor) : formatAuthor(i.author),
    key: i.key,
    isPower: i.authority_doctor,
  }));
  if (config.sponsor.filterTXYD) {
    return _.reject(list, l => l.source === TXYD);
  }
  return list;
}

function formatFaqList(res) {
  const list = _.map(res, e => {
    let summary = e.summary;
    if (e.source === TXYD) {
      summary = splitTengXunYiDian(summary);
    }
    return {
      id: e.unique_id,
      title: formatText(e.title),
      summary: formatText(formatMultiSpace(summary)),
      category: e.tcm_type,
      url: e.url,
      mina: {
        appid: _.get(e, 'mina.appid'),
        path: _.get(e, 'mina.path'),
      },
      source: e.source,
      logo: getSourceLogo(e.logo),
      doctor: e.doctor ? formatDoctor(e.doctor) : formatAuthor(e.author),
    };
  });
  if (config.sponsor.filterTXYD) {
    return _.reject(list, l => l.source === TXYD);
  }
  return list;
}

function formatService(res) {
  return {
    name: res.source,
    service: res.service,
    logo: res.logo,
    url: res.service_url,
    mina: {
      appid: _.get(res, 'mina.appid'),
      path: _.get(res, 'mina.path'),
    },
  };
}

function formatMp(res) {
  return {
    id: res.uid,
    name: res.name,
    url: res.url,
    regUrl: res.reg_url,
  };
}

function formatDoctorView(res) {
  return {
    id: res.doctor_id,
    name: res.doctor_name,
    hospital: res.hospital_name,
    hospitalId: res.hospital_id,
    multiSiteHospital: res.primary_hospital_name,
    title: res.title,
    avatar: withDefault(res.avatar, 'https://ask-cdn.nocode.com/icn-doctor-default.png'),
    department: res.department_name,
    departmentInfo: res.department_description,
    profile: formatText(res.profile),
    expertise: res.expertise_disease,
    expertiseArea: res.expertise_area,
    nerList: _.map(res.relation_entities, i => ({
      id: i.entity_id,
      name: i.entity_name,
      query: i.query,
    })),
    tags: _.map(res.tags, i => i.text),
    consultantList: _.map(res.online_service, formatService),
    mpList: _.map(res.wechat_infos, formatMp),
    tabTag: {
      faq: _.get(res, 'doctor_production.pop_science_list'),
      qa: _.get(res, 'doctor_production.qa_list'),
      paper: _.get(res, 'doctor_production.paper_list'),
    },
    onlineScore: res.online_score,
    paperScore: res.paper_score,
    hasOnline: res.online_service,
  };
}

function formatDoctorIntent(res) {
  return {
    context: _.get(res, 'text'),
    list: _.map(res.data, formatDoctorView),
    url: _.get(res, 'links.next'),
    params: {
      province: _.get(res, 'params.province'),
      city: _.get(res, 'params.city'),
      lon: _.get(res, 'params.lon'),
      lat: _.get(res, 'params.lat'),
      departmentName: _.get(res, 'params.department_name'),
      diseaseName: _.get(res, 'params.disease_name'),
      page: _.get(res, 'params.page'),
      hospitalName: _.get(res, 'params.hospital_name'),
      doctorName: _.get(res, 'params.doctor_name'),
      hasOnline: _.get(res, 'params.fil_o_s'),
    },
    state: res.state,
  };
}

export function formatHospitalIntent(res) {
  return {
    context: _.get(res, 'text'),
    list: formatHospitalList(_.get(res, 'data')),
    url: _.get(res, 'links.next'),
    params: {
      province: _.get(res, 'params.province'),
      city: _.get(res, 'params.city'),
      lon: _.get(res, 'params.lon'),
      lat: _.get(res, 'params.lat'),
      departmentName: _.get(res, 'params.department_name'),
      departmentCode: _.get(res, 'params.department_code'),
      sortBy: _.get(res, 'params.sort_by'),
      page: _.get(res, 'params.page'),
      intent: _.get(res, 'params.intent'),
      hospitalName: _.get(res, 'params.hospital_name'),
      tag: _.get(res, 'params.tag'),
    },
    state: _.get(res, 'state'),
  };
}


export function formatHospitalIntentV2(res, params?) {
  return {
    list: formatHospitalList(_.get(res, 'data.items')),
    url: _.get(res, 'data.has_next') ? 'has' : '',
    params: params,
  };
}

function formatBaikeItem(res, type) {
  const drugTradeName = res.drug_trade_name;
  return {
    id: res.unique_id,
    name: withDefault(drugTradeName, formatText(res.name)),
    detailList: _.reject(getDetailList(res.detail), i => i.key === 'brandName' && drugTradeName),
    drugTradeName,
    key: res.key,
    type,
  };
}

function formatSuggestList(res) {
  return {
    id: res.unique_id,
    name: formatText(res.name),
    pIndex: withDefault(res.p_index, 0),
    hasQa: res.qa_list,
    hasFaq: res.pop_science_list,
    backName: res.back_name,
  };
}

function getGhCodeByAppid(appid) {
  switch (appid) {
    case 'wx65cc950f42e8fff1':
      return 'gh_598eb49157c6';
    case 'wx2eec5fb00157a603':
      return 'gh_0e163ff2ba74';
    case 'wxb032bc789053daf4':
      return 'gh_598eb49157c6';
    default:
      return;
  }
}

function formatSearch(res) {
  return {
    state: _.get(res, 'state'),
    queryCorrection: {
      tip: _.get(res, 'data.query_correction.corrected'),
      raw: _.get(res, 'data.query_correction.raw'),
    },
    covid: _.map(res?.data?.covid, c => ({
      ghCode: getGhCodeByAppid(c.app_id),
      logo: c.logo,
      name: c.name,
      path: c.path,
    })),
    entityNameList: _.map(_.get(res, 'data.entity'), i => i.entity_name),
    doctorIntent: formatDoctorIntent(_.get(res, 'data.doctor', {})),
    hospitalIntent: formatHospitalIntent(_.get(res, 'data.hospital', {})),
    drugstoreIntent: {
      context: _.get(res, 'data.drugstore.text'),
      list: formatDrugstoreList(_.get(res, 'data.drugstore.data')),
    },
    vaccineIntent: {
      context: _.get(res, 'data.vaccine_location.text'),
      list: formatVaccineList(_.get(res, 'data.vaccine_location.data')),
    },
    relatedIntent: {
      context: _.get(res, 'data.related_intent.text'),
      list: _.map(_.get(res, 'data.related_intent.data'), i => ({
        id: i.id,
        intent: i.description,
        query: i.query,
        logo: getTypeLogo(i.type),
        style: getBackgroundColorByType(i.type),
      })),
    },
    types: _.get(res, 'data.display_order', []),
    faqOpinionIntent: {
      context: _.get(res, 'data.faq_opinion.text'),
      list: formatFaqOpinionList(_.get(res, 'data.faq_opinion.data')),
    },
    baikeIntent: {
      item: formatBaikeItem(_.get(res, 'data.baike.data', {}), _.get(res, 'data.baike.type')),
    },
    departmentList: _.map(_.get(res, 'data.department'), i => ({
      ...formatSuggestList(i),
      url: i.url,
      mina: {
        appid: _.get(i, 'mina.appid'),
        path: _.get(i, 'mina.path'),
      },
      source: i.source,
    })),
    diseaseList: _.map(_.get(res, 'data.disease'), formatSuggestList),
    examineList: _.map(_.get(res, 'data.examine'), formatSuggestList),
    drugList: _.map(_.get(res, 'data.drug'), i => ({
      ...formatSuggestList(i),
      hasDrug: i.shopping_list,
      isOtc: i.is_otc,
      brandNames: _.join(i.brand_names, '、'),
    })),
    relatedList: _.chunk(formatFaqList(_.get(res, 'data.related_faq')), 5),
    commonSearch: _.get(res, 'data.common_search'),
    expectedIntent: formatExpectedIntent(res),
    kgIntent: {
      id: _.get(res, 'data.kg.data.entity_id'),
      from: _.get(res, 'data.kg.data.from'),
      entity: _.get(res, 'data.kg.data.entity'),
      predicate: _.get(res, 'data.kg.data.predicate_name'),
      text: _.get(res, 'data.kg.data.text_data'),
      logo: _.get(res, 'data.kg.data.logo'),
      type: _.get(res, 'data.kg.data.kg_type'),
    },
    intent: _.get(res, 'data.intent.data[0]') || {},
  };
}

// 高亮的样式类型
export enum HighlightStyles {
  COLOR = 1, // 字体采用特殊颜色高亮
  UNDERLINE = 1 << 1, // 添加下划线高亮
  NEWLINE = 1 << 2, // 换行符
}

// 每一个高亮的词语
export interface HighlightItem {
  style: number;
  text: string;
}

interface HighlightMeta {
  name: string;
  item: HighlightPosition;
}

interface HighlightPosition {
  start: number;
  end: number;
}

/**
 * 染色
 * @param styleList
 * @param position
 * @param style
 * @returns
 */
function dyeing(styleList: number[], style: HighlightStyles, condition: (v: number, i: number) => boolean): number[] {
  return styleList.map((v, i) => {
    if (condition(v, i)) {
      return v |= style;
    }
    return v;
  });
}

function getHighlightResult(text: string, highlightPositions: HighlightPosition[]): HighlightItem[] {
  let styleList = new Array(text.length).fill(0);
  // 处理高亮词
  highlightPositions.forEach(item => {
    styleList = dyeing(styleList, HighlightStyles.COLOR, (_, i) => i >= item.start && i < item.end);
  });

  // 处理换行符
  styleList = dyeing(styleList, HighlightStyles.NEWLINE, (_, i) => text[i] === '\n');

  const result: HighlightItem[] = [];
  let current: HighlightItem = {
    style: 0,
    text: '',
  };

  styleList.forEach((style, index) => {
    const char = text[index];
    if (current.text && current.style !== style) {
      current.text = formatText(current.text);
      result.push(current);
      current = {
        style,
        text: '',
      };
    }
    current.text += char;
    current.style = style;
  });
  // 最后一个词语
  result.push(current);
  return result;
}

function formatExpectedDoctor(res) {
  const doctor = _.get(res, 'data.best_match.doctor') || {};
  const author = _.get(res, 'data.best_match.author') || {};

  const isValidDoctor = doctor.doctor_name && doctor.hospital_name;
  const isValidAuthor = author.name && author.organization;

  if (isValidDoctor) {
    return formatDoctor(doctor);
  }

  if (isValidAuthor) {
    return formatAuthor(author);
  }

  return null;
}

function formatExpectedIntent(res) {
  const doctor = formatExpectedDoctor(res);
  if (!doctor) {
    return {};
  }
  const summary = _.get(res, 'data.best_match.content') || '';
  const highlightPositions = _.reduce(_.get(res, 'data.best_match.highlight'), (result: HighlightPosition[], h: HighlightMeta) => result.concat(h.item), []);
  return {
    id: _.get(res, 'data.best_match.unique_id'),
    title: formatText(_.get(res, 'data.best_match.title')),
    summary: formatText(summary),
    source: _.get(res, 'data.best_match.source'),
    logo: _.get(res, 'data.best_match.logo'),
    url: _.get(res, 'data.best_match.url'),
    mina: {
      appid: _.get(res, 'best_match.mina.appid'),
      path: _.get(res, 'data.best_match.mina.path'),
    },
    doctor: formatExpectedDoctor(res),
    highlightResult: getHighlightResult(summary, highlightPositions),
    isPower: _.get(res, 'data.best_match.authority_doctor'),
  };
}

export let result;

export async function search(query, direct = false, lon?, lat?) {
  try {
    generateQueryId();
    event({
      category: 'search_web',
      action: 'click',
      label: `q=${encodeURIComponent(query)}`,
    });
    const data = qsStringify({
      ...compact('query', query),
      ...compact('lon', lon),
      ...compact('lat', lat),
      corrected: +direct,
    });
    const url = `${config.API_HOST}/${os.isPc ? 'pc' : 'h5'}/v2/query${data}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    result = resp;
    return formatSearch(resp);
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function initList() {
  try {
    info(`get ${config.HOST_JAVA}/recommend_query`);
    return rq(withSign({
      url: `${config.HOST_JAVA}/recommend_query`,
    }));
  } catch (e) {
    error(e);
    throw e;
  }
}

const instructions = {
  ingredient: '成分',
  description: '药品性状',
  warning: '黑框警告',
  indications: '适应症',
  strength: '规格',
  dosage: '用法用量',
  adverse_reactions: '不良反应',
  contraindication: '药物禁忌',
  precaution: '注意事项',
  pregnancy_nursing_mothers: '孕产用药',
  pediatric_use: '儿童用药',
  geriatric_use: '老年用药',
  interactions: '相互作用',
  overdose: '药物过量',
  pharma_toxicology: '药理毒性',
  pharmacokinetics: '药代动力学',
  storage: '储藏',
};

function formatDrug(res, drugTradeName) {
  const data = _.get(res, `data`) || {};
  const result = {
    id: _.get(data, 'unique_id'),
    name: withDefault(drugTradeName, _.get(data, 'name')),
    detailList: [],
    category: _.get(data, 'category'),
    isOtc: _.get(data, 'is_otc'),
    brandNames: _.join(_.get(data, 'brand_names'), '、'),
  };
  _.forEach(instructions, function(instruction_name: string, instruction) {
    if (_.has(data, [`instruction_${instruction}_md`]) || _.has(data, [`instruction_${instruction}_text`])) {
      const type: BaikeFormatter = _.has(data, `instruction_${instruction}_md`) ? 'MD' : 'TEXT';
      result.detailList.push({
        contentType: type,
        label: instruction_name,
        value: _.get(data, `instruction_${instruction}_${type.toLowerCase()}`),
        key: instruction,
      });
    }
  });
  if (data.medicare) {
    result.detailList.push({
      label: '医保政策',
      value: data.medicare,
      key: 'medicare',
    });
  }
  result.detailList.push({
    label: '处方类型',
    value: data.is_otc ? {
      name:'非处方药',
      description:'非处方药是无需凭借医师处方，消费者可以自行判断、购买和使用的药品。其特点是安全、有效、方便、质量稳定。',
    } : {
      name:'处方药',
      description:'处方药是为了保证用药安全，需凭借医师处方才可调配、购买和使用的药品。患者需要在医生的监督指导下购买、使用。',
    },
    key: 'otc_type',
  });
  return result;
}

export async function fetchDrug(item) {
  try {
    const { id, drugTradeName } = item;
    const url = `${config.HOST_JAVA}/drug_generic/instruction/v3${qsStringify({
      drug_generic_id: id,
    })}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatDrug(resp, drugTradeName);
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function fetchVaccine(id) {
  try {
    const url = `${config.HOST_JAVA}/vaccine_location/${id}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    info(resp.data);
    // checkStatus(resp);
    return formatVaccine(_.get(resp, 'data', {}));
  } catch (e) {
    error(e);
    throw e;
  }
}

function formatDiseaseOrExamine(res) {
  return {
    id: _.get(res, 'data.unique_id'),
    name: _.get(res, 'data.name'),
    detailList: getDetailList(_.get(res, 'data.detail')),
  };
}

export async function fetchDisease(id, formatter: BaikeFormatter = 'MD') {
  try {
    const data = qsStringify({
      ...compact('disease_id', id),
      ...compact('formatter', formatter),
    });
    const url = `${config.HOST_JAVA}/disease/v2/${data}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatDiseaseOrExamine(resp);
  } catch (e) {
    error(e);
    throw e;
  }
}

function formatFaq(res) {
  let summary = res.content;
  if (res.source === TXYD) {
    summary = splitTengXunYiDian(summary);
  }
  const highlightPositions = _.reduce(res.highlight, (result: HighlightPosition[], h: HighlightMeta) => result.concat(h.item), []);
  return {
    id: res.unique_id,
    title: formatText(res.title),
    date: res.published_at,
    summary: formatText(formatMultiSpace(summary)),
    source: res.source,
    logo: res.logo,
    url: res.url,
    mina: {
      appid: _.get(res, 'mina.appid'),
      path: _.get(res, 'mina.path'),
    },
    doctor: res.doctor ? formatDoctor(res.doctor) : formatAuthor(res.author),
    category: res.tcm_type,
    faqList: _.map(res.related_faq, i => ({
      id: i.id,
      title: formatText(i.name),
    })),
    nerList: _.map(res.nerList, n => ({
      id: n.id,
      name: n.ner_name,
      type: n.type,
    })),
    highlightResult: getHighlightResult(summary, highlightPositions),
  };
}

function formatRelatedFaq(res) {
  return _.map(res, i => ({
    id: i.unique_id,
    title: formatText(i.title),
  }));
}

export async function fetchFaq(id) {
  try {
    const url = `${config.HOST_JAVA}/pop_science/${id}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatFaq(withDefault(resp.data, {}));
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function fetchRelatedFaqList(query) {
  try {
    const url = `${config.HOST_JAVA}/pop_science/related?query=${query}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatRelatedFaq(withDefault(resp.data, []));
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function fetchExamine(id, name) {
  try {
    const data = qsStringify({
      ...compact('examine_id', id),
      ...compact('examine_name', name),
    });
    const url = `${config.HOST_JAVA}/examine/${data}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatDiseaseOrExamine(resp);
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function suggest(query) {
  try {
    const url = `${config.HOST_JAVA}/suggest?query=${query}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return withDefault(resp.data, []);
  } catch (e) {
    error(e);
    throw e;
  }
}

export function formatDoctor(doctor) {
  return {
    id: formatText(doctor?.doctor_id),
    name: formatText(doctor?.doctor_name),
    hospital: formatText(doctor?.hospital_name),
    title: formatText(doctor?.title),
    department: formatText(doctor?.department_name),
    avatar: doctor?.avatar || '/images/icn-doctor-default@3x.png',
    description: formatText(doctor?.doctor_desc || doctor?.department_desc || doctor?.hospital_desc),
  };
}

export function formatAuthor(author) {
  return {
    id: formatText(author?.doctor_id),
    name: formatText(author?.name),
    hospital: formatText(author?.organization),
    title: formatText(author?.title),
    department: formatText(author?.department),
    tags: author?.department_tag || [],
    avatar: author?.avatar || '/images/icn-doctor-default@3x.png',
  };
}

function formatHybridIntent(res) {
  let list = _.map(_.get(res, 'data'), i => {
    let summary = i.summary;
    if (i.source === TXYD) {
      summary = splitTengXunYiDian(summary);
    }
    return {
      id: i.unique_id,
      type: i.type,
      title: formatText(i.title),
      summary: formatText(formatMultiSpace(summary)),
      coverUrl: i.cover_url,
      duration: i.duration,
      source: i.source,
      logo: getSourceLogo(i.logo),
      doctor: i.doctor ? formatDoctor(i.doctor) : formatAuthor(i.author),
      url: i.url,
      mina: {
        appid: _.get(i, 'mina.appid'),
        path: _.get(i, 'mina.path'),
      },
    };
  });
  if (config.sponsor.filterTXYD) {
    list = _.reject(list, l => l.source === TXYD);
  }
  return {
    list,
    url: _.get(res, 'links.next'),
  };
}

export async function fetchHybridIntent(query, pathname) {
  try {
    const data = qsStringify({
      query,
      page: 1,
    });
    const url = `${config.API_HOST}${pathname || ('/search' + data)}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatHybridIntent(resp);
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function fetchDoctorIntent(params) {
  try {
    const data = {
      ...compact('query', params.query),
      ...compact('region', params.city || params.province),
      ...compact('department_name', params.departmentName),
      ...compact('department_code', params.departmentCode),
      ...compact('disease_name', params.diseaseName),
      ...compact('disease_id', params.diseaseId),
      ...compact('hospital_id', params.hospitalId),
      ...compact('hospital_name', params.hospitalName),
      ...compact('doctor_name', params.doctorName),
      ...compact('online_service', params.hasOnline),
      ...compact('filter_special_departments', params.filterSpecialDepartments),
      page: params.page || 1,
      page_size: 10,
    };
    const url = `${config.HOST_JAVA}/doctor/search/v2${qsStringify(data)}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    let nextUrl = '';
    if (_.get(resp, 'data.has_next', false)) {
      data.page++;
      nextUrl = `${config.HOST_JAVA}/doctor/search/v2${qsStringify(data)}`;
    }
    return {
      ...formatDoctorIntentV2(resp),
      params: data,
      url: nextUrl,
    };
  } catch (e) {
    error(e);
    throw e;
  }
}

function formatDoctorIntentV2(res) {
  return {
    context: '为你推荐以下医生',
    list: _.map(res.data?.items, formatDoctorView),
    state: res.state,
  };
}

export async function fetchHospitalIntent(params) {
  try {
    const data = qsStringify({
      ...compact('lon', params.lon),
      ...compact('lat', params.lat),
      ...compact('province', params.province),
      ...compact('city', params.city),
      ...compact('department_name', params.departmentName),
      ...compact('disease_name', params.diseaseName),
      ...compact('sort_by', params.sortBy),
      ...compact('page', params.page),
      ...compact('intent', params.intent),
      ...compact('hospital_name', params.hospitalName),
      ...compact('tag', params.tag),
    });
    const url = `${config.HOST_JAVA}/hospital/search/v2${data}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatHospitalIntentV2(resp, params);
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function fetchDoctor(id) {
  try {
    const url = `${config.HOST_JAVA}/doctor/${id}/v2`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatDoctorView(resp);
  } catch (e) {
    error(e);
    throw e;
  }
}

function formatHospital(res) {
  return {
    id: res.unique_id,
    name: formatText(res.name),
    grade: res.grade,
    tags: res.tags,
    profile: res.introduction,
    address: formatText(res.address),
    summaryList: getSummaryList(res.prior_info),
    avatar: withDefault(res.avatar_url, '/images/icn-hospital-default@3x.png'),
    distance: res.distance,
    source: res.source,
    url: res.url,
    mina: {
      appid: _.get(res, 'mina.appid'),
      path: _.get(res, 'mina.path'),
    },
    geo: res.geo,
    contacts: res.contacts,
    rankInfo: {
      list: _.map(_.get(res, 'rank_info.data'), r => ({
        name: r.name,
        rank: r.rank,
        query: r.query,
      })),
      source: _.get(res, 'rank_info.meta.source'),
    },
    mpList: _.map(res.wechat_infos, formatMp),
    branchList: _.map(res.branches, i => ({
      name: formatText(i.name),
      address: formatText(i.address),
      prefix: i.branch_suffix,
      geo: i.geo,
    })),
    departmentList: _.map(res.departments, i => ({
      star: i.star,
      name: i.department_name,
    })),
  };
}

export async function fetchHospital(id) {
  try {
    const url = `${config.HOST_JAVA}/hospital/${id}/v2`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatHospital(resp?.data || {});
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function fetchQa(id) {
  try {
    const url = `${config.HOST_JAVA}/question/${id}/v2`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    info(resp.data);
    return formatQa(_.get(resp, 'data', {}));
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function fetchPaper(id) {
  try {
    const url = `${config.HOST_JAVA}/doctor/paper/${id}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    info(resp);
    return formatPaper(resp || {});
  } catch (e) {
    error(e);
    throw e;
  }
}

function formatPaper(res) {
  return {
    id: res.paper_id,
    title: formatText(res.title),
    summary: formatText(formatMultiSpace(res.summary)),
    authorList: _.map(res.author_list, i => ({
      id: i.doctor_id,
      name: i.author_name,
    })),    
    journal: res.journal_name,
    date: res.online_publish_date,
    source: _.get(res, 'source_name'),
    logo: _.get(res, 'source_name'),
    url: _.get(res, 'source_icon'),
  };
}

function formatQa(res) {
  return {
    id: res.unique_id,
    question: formatText(res.title),
    date: moment(res.published_at, 'X').format('YYYY-MM-DD'),
    answer: formatText(formatMultiSpace(res.summary)),
    source: formatText(res.source),
    url: res.url,
    mina: {
      appid: _.get(res, 'mina.app_id'),
      path: _.get(res, 'mina.path'),
    },
    logo: res.logo,
    // doctor: res.doctor ? formatDoctor(res.doctor) : formatAuthor(res.author),
    category: res.tcm_type,
    description: formatText(res.description),
    flowList: _.map(res.replies, i => ({
      type: i.type,
      content: formatText(formatMultiSpace(i.content)),
    })),
  };
}

function formatProperties(res) {
  return {
    list: _.map(res.list, i => ({
      name: i.property_name,
      text: i.text_data,
      logo: i.logo,
    })),
    contact: {
      title: _.get(res, 'contact.title'),
      list: _.get(res, 'contact.introduce', []),
      qrcode: _.get(res, 'contact.qrcode'),
    },
  };
}

function formatPredictList(res) {
  return {
    title: res.explain,
    list: _.map(res.list, (item) => ({
      id: item.entity_id,
      name: item.entity_name,
      score: item.score,
      type: item.entity_type,
      highlight: item.highlight,
      faqs: item.pop_science,
    })),
  };
}

export async function fetchProperties(id, type) {
  try {
    const data = qsStringify({
      ...compact('entity_id', id),
      ...compact('vertex_type', type),
    });
    const url = `${config.HOST_JAVA}/kg/property${data}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    info(resp.data);
    return formatProperties(_.get(resp, 'data', {}));
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function fetchPredictList(query, intentId, faqIds) {
  try {
    const url = `${config.HOST_JAVA}/predicate/entity`;
    info(`post ${url}`);
    const resp = await rq(withSign({
      method: 'post',
      url,
      data: {
        query,
        intent_code: intentId,
        pop_science_id: faqIds,
      },
    }));
    info(resp.data);
    return formatPredictList(_.get(resp, 'data', {}));
  } catch (e) {
    error(e);
    throw e;
  }
}

export function formatPredictFaqs(res) {
  return _.map(res, item => {
    const summary = item.content || '';
    const highlight = _.reduce(item.highlight, (result, h) => _.concat(result, h.item), []);
    return {
      id: item.unique_id,
      title: item.title,
      mina: item.mina,
      webView: item.webView,
      source: item.source,
      doctor: {
        id: _.get(item, 'doctor.doctor_id'),
        name: _.get(item, 'doctor.doctor_name') || _.get(item, 'author.name') || item.source,
        avatar: _.get(item, 'doctor.avatar') || _.get(item, 'author.avatar') || item.logo,
        title: _.get(item, 'doctor.title'),
        hospitalName: _.get(item, 'doctor.hospital_name'),
        hospitalId: _.get(item, 'doctor.hospital_id'),
        departmentName: _.get(item, 'doctor.department_name'),
        departmentId: _.get(item, 'doctor.department_id'),
      },
      renderList: getHighlightResult(summary, highlight),
    };
  });
}

export async function fetchPredictFaqs(query, predict) {
  try {
    const data = {
      query: query,
      pop_science: predict.faqs,
      entity_id: predict.id,
      entity_name: predict.name,
    };
    const url = `${config.HOST_JAVA}/predicate/entity/pop_science/`;
    info(`post ${url}`);
    const resp = await rq(withSign({
      url,
      method: 'post',
      data,
    }));
    info(resp.data);
    return formatPredictFaqs(resp.data || []);
  } catch (e) {
    error(e);
    throw e;
  }
}

export const fetchPredictCards = (query, predictList) => {
  return Promise.all(
    predictList.map(async (predict) => ({
      ...predict,
      faqs: await fetchPredictFaqs(query, predict),
    })),
  );
};

