import * as React from 'react';
import { fixedBody, unfixedBody } from '@utils/browser';
import { pageview } from '@libs/ga';
import stl from './style.less';
import cx from 'classnames';
import IconClose from '@assets/icon/icn-close-white.png';

export default class SidebarViews extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_mask`);
  }

  componentDidMount() {
    fixedBody();
  }

  componentWillUnmount() {
    unfixedBody();
  }

  render() {
    const { visible, children, onClose } = this.props;
    return (
      <>
        <div className={cx(stl.mask, {[stl.show]: visible})}></div>
        <div className={cx(stl.backdrop, {[stl.show]: visible})}>
          {children}
          <div className={stl.close} onClick={onClose}>
            <img className={stl.close__img} src={IconClose}/>
          </div>
        </div>
      </>
    );
  }
}

interface IProps {
  visible: boolean;
  onClose(): void;
}
