import React, { lazy, Suspense } from 'react';
import { os } from '@utils/browser';
import { pageview } from '@libs/ga';
import { fetchDailyList, fetchLatestDaily, MorningPostType } from '@services/morningPost';
import _ from 'lodash';
import config from '@config';

const Mobile = lazy(() =>
  import(/* webpackChunkName: "FaqMobile" */ '@pages/MorningPost/mobile')
);

export default class MorningPost extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    pageview('morning-post');
    this.state = {
      list: [],
    };
  }

  hasMore = true;

  async loadMore() {
    if (!this.hasMore) {
      return;
    }
    const item:MorningPostType = _.last(this.state.list);
    const id = item.id;
    const oldList = await fetchDailyList(id, 'old');
    this.setState(prevState => ({
      list: [...prevState.list, ...oldList],
    }));
    if (!oldList.length) {
      this.hasMore = false;
    }
  }

  async componentDidMount() {
    const item = await fetchLatestDaily();
    const oldList = await fetchDailyList(item.id, 'old');
    const list = [item, ...oldList];
    this.setState({
      list,
    });
  }

  render() {
    const { list } = this.state;
    return (os.isMobile && config.sponsor.hasMorningPost) && (
      <Suspense fallback>
        <Mobile list={list} loadMore={this.loadMore.bind(this)} />
      </Suspense>
    );
  }
}
