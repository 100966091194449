import * as React from 'react';
import cx from 'classnames';
import stl from './style.less';

export default class Popup extends React.PureComponent<IProps, any> {
  render() {
    const { visible, children, onClose, coverClass, customClass } = this.props;
    return visible && (
      <>
        <div className={cx(stl.mask, coverClass)} onClick={onClose} />
        <div className={cx(stl.popup, customClass)}>
          {children}
        </div>
      </>
    );
  }
}

interface IProps {
  coverClass?: string;
  customClass?: string;
  visible: boolean;

  onClose?(): void;
}
