import * as _ from 'lodash-es';
import * as React from 'react';
import stl from './style.less';
import {pageview} from '@libs/ga';
import {navigateByType, os, showAlert} from '@utils/browser';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import TextViews from '@components/TextViews';
import icnEmpty from '@images/icn-baike-empty.png';
import icnCert from '@images/icn-certified@3x.png';
import icnApprove from '@images/icn-approve.png';

export default class DiseaseTopicContent extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
    pageview(`${location.pathname}#!views_disease-topic`);
  }

  toSource = async (source) => {
    if (source.content) {
      TextViews.show({
        title: source.name,
        content: source.content,
      });
    }
    if (source.url) {
      if (os.isMina) {
        await navigator.clipboard.writeText(source.url);
        showAlert('网址已复制');
      } else {
        navigateByType({id: '', url: source.url, source});
      }
    }
  }

  render() {
    const {item} = this.props;
    if (_.isEmpty(item.article)) {
      return <div className={stl.empty}>
        <img className={stl.icon} src={icnEmpty} alt="空" />
        <div className={stl.text}>未找到相关内容</div>
      </div>;
    }
    return (
      <div className={stl.container}>
        <div className={stl.title}>{item.article.title}</div>
        <div className={stl.tag}>
          <img className={stl.tag__icn} src={icnCert}></img>
          本文已通过专业医学审核
        </div>
        <div className={stl.author}>
          <img className={stl.author__logo} src={item.article.author.logo}></img>
          <div className={stl.author__info}>
            <div className={stl.author__nameWrap}>
              <div className={stl.author__name}>{item.article.author.name}</div>
              <div className={stl.author__tag}>
                {item.article.author.approved && (
                  <img className={stl.approvedIcn} src={icnApprove}></img>
                )}
                {item.article.author.approvedTag && (
                  <div className={stl.approvedTag}>
                    <img className={stl.approvedTag__logo} src={item.article.author.approvedTag.logo} />
                    {item.article.author.approvedTag.name}
                  </div>
                )}
              </div>
            </div>
            <div className={stl.author__desc}>{item.article.author.desc}</div>
          </div>
        </div>
        <div className={stl.subtitle}>{item.article.subtitle}</div>
        <div className={stl.desc}>{item.article.summary}</div>
        {item.article.source.id && (
          <div 
            className={stl.source} 
            onClick={() => this.toSource(item.article.source)}>
            <img className={stl.source__logo} src={item.article.source.logo}></img>
            参考来源<IcnArrowRight className={stl.source__arrow} />
          </div>
        )}
      </div>
    );
  }
}

interface IProps {
  item: {
    title: string;
    article: any,
    source: any;
  };
}
