import React, { lazy, Suspense } from 'react';
import { connect } from 'dva';
import { os } from '@utils/browser';
import { qsParse } from '@utils/index';
import { pageview } from '@libs/ga';

const Pc = lazy(() => import(/* webpackChunkName: "SearchPc" */'@pages/Search/pc'));
const Mobile = lazy(() => import(/* webpackChunkName: "SearchMobile" */'@pages/Search/mobile'));
let backupQuery;
@connect()
export default class Search extends React.PureComponent<IProps, any> {
  static getDerivedStateFromProps(props) {
    const { dispatch, location } = props;
    const search = qsParse(location.search);
    dispatch({
      type: 'app/config',
      payload: search,
    });
    const q = search?.q || '';
    const d = Boolean(search?.d);
    if (q && (q !== backupQuery)) {
      backupQuery = q;
      dispatch({
        type: 'search/search',
        payload: {
          query: q,
          direct: d,
        },
      });
    }
    return null;
  }

  constructor(props) {
    super(props);
    pageview('search');
    this.state = {};
  }

  render() {
    if (os.isPc) {
      return (
        <Suspense fallback>
          <Pc />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback>
          <Mobile />
        </Suspense>
      );
    }
  }
}

interface IProps {
  dispatch?(opts): string;

  location?: any;
}
