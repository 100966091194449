// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_VH3iA {
  overflow: hidden;
  margin-top: 20px;
}
.card_VH3iA.pc_kCW6y {
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
}
.context_SXUBS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 15px;
  --bgc: #05C4B1;
  position: relative;
}
.context_SXUBS:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bgc);
  opacity: 0.05;
}
.feedback_TAX_L {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.item_b7_fe {
  position: relative;
  cursor: pointer;
}
.item_b7_fe:active {
  background-color: #F5F5F5;
}
.item_b7_fe:before {
  content: '';
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 0;
}
.item_b7_fe:last-child:before {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/MainCard/style.less"],"names":[],"mappings":"AAIA;EACE,gBAAA;EACA,gBAAA;AAHF;AAKE;EACE,mBAAA;EACA,kBAAA;EACA,qCAAA;EACA,sBAAA;AAHJ;AAOA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;AALF;AAOE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,4BAAA;EACA,aAAA;AALJ;AASA;EACE,eAAA;EACA,yBAAA;AAPF;AAUA;EACE,kBAAA;EACA,eAAA;AARF;AAUE;EACE,yBAAA;AARJ;AAWE;EACE,WAAA;EACA,4CAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,QAAA;AATJ;AAYE;EACE,aAAA;AAVJ","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.card {\n  overflow: hidden;\n  margin-top: 20px;\n\n  &.pc {\n    margin-bottom: 20px;\n    border-radius: 8px;\n    border: 1px solid @black04;\n    box-sizing: border-box;\n  }\n}\n\n.context {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 50px;\n  padding: 0 15px;\n  --bgc: #05C4B1;\n  position: relative;\n\n  &:before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: var(--bgc);\n    opacity: 0.05;\n  }\n}\n\n.feedback {\n  font-size: 13px;\n  color: @black50;\n}\n\n.item {\n  position: relative;\n  cursor: pointer;\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n\n  &:before {\n    content: '';\n    border-bottom: 1px solid @black04;\n    position: absolute;\n    bottom: 0;\n    left: 10px;\n    right: 0;\n  }\n\n  &:last-child:before {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `card_VH3iA`,
	"pc": `pc_kCW6y`,
	"context": `context_SXUBS`,
	"feedback": `feedback_TAX_L`,
	"item": `item_b7_fe`
};
export default ___CSS_LOADER_EXPORT___;
