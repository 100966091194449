import * as React from 'react';
import * as _ from 'lodash-es';
import { createRoot } from 'react-dom/client';
let root;
import SidebarViews from '@components/SidebarViews';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import { event, pageview } from '@libs/ga';
import cx from 'classnames';
import stl from './style.less';

export default class MpListViews extends React.PureComponent<any, any> {
  static show(list) {
    const elm = document.createElement('div');
    elm.id = `mplist`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<MpListViews list={list} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_mp_list`);
    this.state = {
      visible: !!props.list,
    };
  }

  toMp = evt => {
    const { url } = evt.currentTarget.dataset;
    event({
      category: 'mp-list-views_mp',
      action: 'click',
      label: `${url}`,
    });
    window.open(url);
  };

  close = () => {
    const elm = document.getElementById(`mplist`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { list } = this.props;
    const { visible } = this.state;
    return (
      <SidebarViews visible={visible} onClose={this.close}>
        {!!list && (
          <>
            <div className={cx(stl.line, stl.hints)} />
            {_.map(list, (item, index) => (
              <div key={index}>
                <div className={stl.mp}
                     onClick={this.toMp} data-url={item.url}>
                  <div style={{ overflow: 'hidden' }}>
                    <div className={stl.name}>{item.name}</div>
                    <div className={stl.source}>微信预约挂号</div>
                  </div>
                  <div className={stl.wrap}>查看</div>
                  <IcnArrowRight />
                </div>
                <div className={stl.line} />
              </div>
            ))}
          </>
        )}
      </SidebarViews>
    );
  }
}
