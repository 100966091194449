import * as React from 'react';
import SidebarViews from '@components/SidebarViews';
import { pageview } from '@libs/ga';
import { createRoot } from 'react-dom/client';
let root;
import BaikeContent from '@components/BaikeContent';

export default class BaikeViews extends React.PureComponent<IProps, any> {
  static show(item, type, key) {
    const elm = document.createElement('div');
    elm.id = `${type}_${item.id}`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<BaikeViews item={item} type={type} kLabel={key} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_baike`);
    this.state = {
      visible: !!props.item,
    };
  }

  close = () => {
    const { item, type } = this.props;
    const elm = document.getElementById(`${type}_${item.id}`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { item, kLabel } = this.props;
    const { visible } = this.state;
    return (
      <SidebarViews visible={visible} onClose={this.close}>
        <BaikeContent item={item} kLabel={kLabel} />
      </SidebarViews>
    );
  }
}

interface IProps {
  type: string;
  kLabel: string;
  item: {
    id: string;
    name: string;
    category: string;
    detailList: any[];
  };
}
