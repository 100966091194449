import React, { lazy, Suspense } from 'react';
import { connect } from 'dva';
import { os } from '@utils/browser';
import { pageview } from '@libs/ga';

const Pc = lazy(() =>
  import(/* webpackChunkName: "FaqPc" */ '@pages/DiseaseTopic/pc')
);
const Mobile = lazy(() =>
  import(/* webpackChunkName: "FaqMobile" */ '@pages/DiseaseTopic/mobile')
);
@connect(({loading}) => ({
  loading: loading.effects['disease/loadDiseaseArticle'],
}))
export default class DiseaseTopic extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    pageview('disease-topic');
    this.state = {};
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    const id = match.params.id;
    if (id) {
      dispatch({
        type: 'disease/loadDiseaseArticle',
        payload: {
          id,
        },
      });
    }
  }

  render() {
    const {loading} = this.props;
    if (loading === undefined || loading === true) {
        return null;
     }
    if (os.isPc) {
      return (
        <Suspense fallback>
          <Pc />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback>
          <Mobile />
        </Suspense>
      );
    }
  }
}

interface IProps {
  loading: boolean;
  match: any;
  dispatch?(opt): void;
}
