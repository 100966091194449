import React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import stl from './style.less';
import { connect } from 'dva';
import { memoize } from '@utils/index';
import { fetchPredictCards } from '@services/search';
import MaskViews from '@components/mobile/MaskViews';
import PredictCard from '@components/PredictCard';

const fetchCards = memoize(
  fetchPredictCards
);

export default class PredictViews extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      cards: [],
    };
  }

  sidebarElement: HTMLElement = null;
  cardElements: Map<string, HTMLElement> = new Map();

  static show({ title, current, list }) {
    const elm = document.createElement('div');
    elm.id = `predict_views`;
    document.body.appendChild(elm);
    const Connected = connect(({ search }) => ({
      query: search.query,
    }))(PredictViews);
    root = createRoot(elm);
    root.render(
      <Connected
        store={global['app']._store}
        title={title}
        list={list}
        current={current}
      />
    );
  }

  open = () => {
    setTimeout(() => {
      this.setState({
        visible: true,
      });
    }, 0);
  }

  close = () => {
    const { dispatch } = this.props;
    const elm = document.getElementById(`predict_views`);
    this.setState({
      visible: false,
    });
    dispatch({
      type: 'search/setBlur',
      payload: {
        visible: false,
      },
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  toCurrentCard = () => {
    const { current } = this.props;
    const elm = this.cardElements.get(current.id);
    elm.scrollIntoView();
  };

  async componentDidMount() {
    this.open();
    const { query, list } = this.props;
    if (!list?.length) return;
    const cards = await fetchCards(query, list);
    this.setState({ cards }, this.toCurrentCard);
  }

  render() {
    const { visible, cards } = this.state;
    const { title, dispatch } = this.props;
    return (
      <MaskViews visible={visible} onClose={this.close}>
        <div className={stl.main} onClick={this.close}>
          <div className={stl.title}>{title}</div>
          <div className={stl.cards}>
            {cards?.map((item) => (
              <div className={stl.card} key={item.id}>
                <PredictCard
                  ref={(el) => this.cardElements.set(item.id, el)}
                  item={item}
                  dispatch={dispatch}
                />
              </div>
            ))}
          </div>
        </div>
      </MaskViews>
    );
  }
}

interface IProps {
  query?: string;
  title: string;
  current: any;
  list: any[];
  dispatch: any;
}
