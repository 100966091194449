// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_OWG7Q {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: none;
  animation: delay_F_BE4 200ms;
}
.layout_OWG7Q.active_cHn4c {
  display: block;
}
.mask_kccBF {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ddd;
  opacity: 0.2;
}
.content_Po_kZ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  border: 3px solid #595959;
  border-top-color: #1890FF;
  border-radius: 50%;
}
@keyframes delay_F_BE4 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes rotate_vgB9m {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/GlobalLoading/style.module.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,aAAA;EACA,4BAAA;AADF;AAEE;EACE,cAAA;AAAJ;AAIA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,sBAAA;EACA,YAAA;AAFF;AAKA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;AAHF;AAOA;EACE;IACE,UAAA;EALF;EAOA;IACE,UAAA;EALF;AACF;AAQA;EACE;IACE,oBAAA;EANF;EAQA;IACE,yBAAA;EANF;AACF","sourcesContent":["@import (reference)'~@styles/vars';\n\n.layout {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 9999;\n  display: none;\n  animation: delay 200ms;\n  &.active {\n    display: block;\n  }\n}\n\n.mask {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: @gray-D;\n  opacity: 0.2;\n}\n\n.content {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  margin: auto;\n  width: 30px;\n  height: 30px;\n  border: 3px solid @gray-59;\n  border-top-color: @blue-18;\n  border-radius: 50%;\n//   animation: rotate linear .6s infinite;\n}\n\n@keyframes delay {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes rotate {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `layout_OWG7Q`,
	"delay": `delay_F_BE4`,
	"active": `active_cHn4c`,
	"mask": `mask_kccBF`,
	"content": `content_Po_kZ`,
	"rotate": `rotate_vgB9m`
};
export default ___CSS_LOADER_EXPORT___;
