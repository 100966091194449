import store from 'store';
import config from '../config';
import rq from './request';
import { withSign } from '@mina-modules/sign';

const host = `${window.location.protocol}//${window.location.host}`;
const loginUrl = `${host}/login`;

class User {
  isLogin(currentUrl: string) {
    store.set('callbackUrl', currentUrl);
    return !!store.get('loginInfo', {}).token;
  }

  get token() {
    return store.get('loginInfo', {}).token;
  }

  get name() {
    return store.get('userInfo', {}).nickName;
  }
  
  get avatar() {
    return store.get('userInfo', {}).avatar;
  }

  async login() {
    if (window.location.search.indexOf('?token=') > -1) {
      const token = window.location.search.slice(7);
      const resp = await this.auth(token);
      console.log('loginInfo', resp);
      store.set('loginInfo', resp);
      const userInfo = await this.getUserInfo();
      console.log('userInfo', userInfo.data);
      store.set('userInfo', userInfo.data);
      window.location.href = store.get('callbackUrl', `${host}/`);
    } else {
      console.log(config.authHost);
      window.location.href = `${
        config.authHost
      }/wechat?callback=${encodeURIComponent(loginUrl)}&appname=${
        config.appName
      }`;
    }
  }

  async auth(token) {
    const url = `${config.HOST_JAVA}/pc/login/callback?token=${token}`;
    return rq(
      withSign({
        url,
      })
    );
  }

  async getUserInfo() {
    const url = `${config.HOST_JAVA}/user/profile`;
    const resp = await rq(
      withSign({
        url,
      })
    );
    resp.data = {
      userId: resp.data.user_id,
      openId: resp.data.open_id,
      nickName: resp.data.nick_name,
      avatar: resp.data.avatar,
      roles: resp.data.roles,
    };
    return resp;
  }

  clearLoginInfo() {
    store.remove('loginInfo');
    store.remove('userInfo');
  }

  logout() {
    this.clearLoginInfo();
    window.location.href = `${
      config.authHost
    }/logout?callback=${encodeURIComponent(
      store.get('callbackUrl', `${host}/`)
    )}`;
  }
}

export default new User();
