// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon_z141J {
  display: inline-block;
  transform: scale(0.5);
  margin: -8px -32px;
}
`, "",{"version":3,"sources":["webpack://./src/components/icons/IcnAnswerText/style.less"],"names":[],"mappings":"AAIA;EACE,qBAAA;EACA,qBAAA;EACA,kBAAA;AAHF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.icon {\n  display: inline-block;\n  transform: scale(0.5);\n  margin: -8px -32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `icon_z141J`
};
export default ___CSS_LOADER_EXPORT___;
