import * as React from 'react';
import DiseaseContent from '@components/DiseaseContent';
import stl from './style.less';
import { connect } from 'dva';

@connect(({ disease }) => ({
  item: disease,
}))
export default class Disease extends React.PureComponent<any, any> {
  render() {
    const { item } = this.props;
    return (
      <div className={stl.page}>
        <DiseaseContent item={item} />
        <div style={{ height: '80px' }} />
      </div>
    );
  }
}
