import md5 from 'md5';
import * as qs from 'qs';

let _signToken;
let _host;

export function initSignV3(token, host) {
  _host = host;
  _signToken = token;
}

export function withSignV3(options: any) {
  const nonce = generateNonce();
  const timestampInMs = Date.now();
  const method = options.method || 'GET';
  options.url = decodeURIComponent(decodeURIComponent(options.url));
  const idx = options.url.indexOf('?');
  let path = options.url;
  if (idx > -1) {
    path = options.url.slice(0, idx);
  }
  path = encodeURI(path.replace(_host, ''));
  let queryParams = '';
  if (idx > -1) {
    queryParams = options.url.slice(idx + 1, options.url.length);
    const params = qs.parse(queryParams, {
      decoder: function (str) {
        return decodeURIComponent(str);
      },
    });
    queryParams = qs.stringify(params, {
      addQueryPrefix: false,
      skipNulls: true,
      indices: false,
    });
  }

  const newUrl = `${_host}${path}${queryParams ? '?' + queryParams : ''}`;
  const sign = getSign(
    method,
    path,
    queryParams,
    nonce,
    timestampInMs,
    _signToken
  );
  return {
    ...options,
    url: newUrl,
    header: {
      ...options.header,
      sign_v3: sign,
      nonce: nonce,
      timestamp: timestampInMs,
    },
  };
}

/**
 * @param method 标准的 HTTP METHOD，比如 GET、POST、PUT、DELETE
 * @param path 路径，比如 /api/v1/xxx
 * @param queryStringEncoded 没有查询字符串，传空字符串就行。查询字符串的参数名和参数值需要进行编码
       ，比如 page=1&disease_name=%E8%82%BA%E7%BB%93%E6%A0%B8
 * @param nonce 随机字符串，建议使用 UUID
 * @param timestampInSecond 时间戳，单位是秒
 * @param signToken 签名密钥，需要和服务端保持一致
 * @returns {string} 签名
 */
function getSign(
  method,
  path,
  queryStringEncoded,
  nonce,
  timestampInSecond,
  signToken
) {
  const input: any[] = [];
  input.push(method.toUpperCase());
  input.push(path);
  input.push(queryStringEncoded);
  input.push(nonce);
  input.push(timestampInSecond);
  input.push(signToken);
  const inputStr = input.join('');
  return md5Hex(inputStr);
}

function md5Hex(input) {
  return md5(input.toString());
}

/**
 * 生成 nonce, 建议使用 UUID
 */
function generateNonce() {
  return generateUUID();
}

function generateUUID() {
  const timestamp = new Date().getTime();
  const randomPart = Math.random().toString(36).substring(2);
  const seed = timestamp + randomPart;
  const md5Hash = md5(seed);

  const part1 = md5Hash.slice(0, 8);
  const part2 = md5Hash.slice(8, 12);
  const part3 = md5Hash.slice(12, 16);
  const part4 = md5Hash.slice(16, 20);
  const part5 = md5Hash.slice(20, 32);

  return `${part1}-${part2}-${part3}-${part4}-${part5}`;
}
