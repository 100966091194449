import * as React from 'react';
import FaqContent from '@components/FaqContent';
import stl from './style.less';
import { connect } from 'dva';

@connect(({ faq}) => ({
  item: faq.item,
  faqList: faq.relatedFaqList,
}))
export default class Faq extends React.PureComponent<any, any> {
  render() {
    const { item, faqList } = this.props;
    return (
      <div className={stl.page}>
        <FaqContent item={item} faqList={faqList}/>
      </div>
    );
  }
}
