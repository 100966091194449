// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg_llDSd {
  height: 8px;
  background-color: #F7F8FA;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShadowLine/style.less"],"names":[],"mappings":"AAIA;EACE,WAAA;EACA,yBAAA;AAHF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.bg {\n  height: 8px;\n  background-color: #F7F8FA;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": `bg_llDSd`
};
export default ___CSS_LOADER_EXPORT___;
