import React, { CSSProperties } from 'react';

export default function PowerTag({ style = {} }: { style?: CSSProperties }) {
  const innerStyle: CSSProperties = {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1,
    color: '#07aa99',
    padding: '3px 4px 2px',
    borderRadius: 2,
    opacity: 0.85,
    backgroundColor: 'white',
    marginLeft: 5,
    ...style,
  };

  return <div style={innerStyle}>权威专家</div>;
}
