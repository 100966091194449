// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress_gIWUW {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.08);
  height: 3px;
  overflow: hidden;
}
.progress__inner_BTG_k {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/PredictProgress/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,+BAAA;EACA,WAAA;EACA,gBAAA;AACF;AACA;EACE,YAAA;AACF","sourcesContent":[".progress {\n  border-radius: 3px;\n  background: rgba(0,0,0,0.08);\n  height: 3px;\n  overflow: hidden;\n}\n.progress__inner {\n  height: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": `progress_gIWUW`,
	"progress__inner": `progress__inner_BTG_k`
};
export default ___CSS_LOADER_EXPORT___;
