import IcnArrowRight from '@components/icons/IcnArrowRight';
import React from 'react';
import stl from './style.less';
import { set, get } from 'lodash';

export default function Comments({
  logo = null,
  reference,
  style = {},
  right = null,
  onClick = null,
}) {
  // 默认的字体颜色
  if (!get(style, 'color')) {
    set(style, 'color', '#636B7A');
  }

  return (
    <div className={stl.comments} style={style} onClick={onClick}>
      {logo && <div className={stl.logo}>{logo}</div>}
      <div className={stl.reference}>{reference}</div>
      <div className={stl.arrow}>
        {right}
        <IcnArrowRight />
      </div>
    </div>
  );
}
