import rq from '@utils/request';
import { withSign } from '@mina-modules/sign';
import config from '@config';
import { event } from '@libs/ga';
import { error, info } from '@libs/log';

export async function savePkg(query, pkg?) {
  if (!pkg) {
    return;
  }
  try {
    event({
      category: 'save_pkg',
      action: 'click',
      label: pkg,
    });
    const url = `${config.HOST_JAVA}/log/2b`;
    info(`post ${url}`);
    const resp = await rq(withSign({
      url,
      method: 'post',
      data: {
        pkg,
        query,
      },
    }));
    return resp;
  } catch (e) {
    error(e);
    throw e;
  }
}
