import * as React from 'react';
import HomeList from '@components/HomeList';
import Header from '@components/pc/IndexHeader';
import Footer from '@components/Footer';
import SearchBar from '@components/pc/SearchBar';
import Logo from '@components/Logo';
import config from '@config';
import stl from './style.less';

export default class Home extends React.PureComponent<unknown, any> {
  render() {
    return (
      <div className={stl.page}>
        {!config.sponsor.duplication && (
          <Header />
        )}
        {config.sponsor.hasProduct && (
          <Logo className={stl.logoHome} />
        )}
        <SearchBar className={stl.searchBar} autoFocus />
        <HomeList className={stl.list} />
        {config.sponsor.hasProduct && (
          <Footer className={stl.footer} />
        )}
      </div>
    );
  }
}
