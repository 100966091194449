// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_t94Qi {
  display: flex;
  justify-content: space-between;
  width: 1146px;
  margin-left: 166px;
  margin-bottom: 120px;
}
.searchResult_KD2Dt {
  width: 666px;
  position: relative;
}
.sidebar_OZb4o {
  width: 420px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Search/pc/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,8BAAA;EACA,aAAA;EACA,kBAAA;EACA,oBAAA;AAHF;AAMA;EACE,YAAA;EACA,kBAAA;AAJF;AAOA;EACE,YAAA;AALF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.content {\n  display: flex;\n  justify-content: space-between;\n  width: 1146px;\n  margin-left: 166px;\n  margin-bottom: 120px;\n}\n\n.searchResult {\n  width: 666px;\n  position: relative;\n}\n\n.sidebar {\n  width: 420px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `content_t94Qi`,
	"searchResult": `searchResult_KD2Dt`,
	"sidebar": `sidebar_OZb4o`
};
export default ___CSS_LOADER_EXPORT___;
