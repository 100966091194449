import * as React from 'react';
import * as _ from 'lodash-es';
import cx from 'classnames';
import stl from './style.less';

export default class SwiperCard extends React.PureComponent<IProps, any> {
  elm;

  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
    this.elm = React.createRef();
  }

  touchEnd = evt => {
    this.scroll.cancel();
    const { scrollLeft, clientWidth } = evt.currentTarget;
    const rest = scrollLeft % clientWidth;
    const value = scrollLeft - rest + (rest > clientWidth >> 1 ? clientWidth : 0);
    evt.currentTarget.scrollLeft = value;
    this.setState({
      current: Math.floor(value / clientWidth),
    });
  };

  scroll = _.debounce(() => {
    const { scrollLeft, clientWidth } = this.elm.current;
    const rest = scrollLeft % clientWidth;
    const value = scrollLeft - rest + (rest > clientWidth >> 1 ? clientWidth : 0);
    this.elm.current.scrollLeft = value;
    this.setState({
      current: Math.floor(value / clientWidth),
    });
  }, 800);

  render() {
    const { list, height } = this.props;
    const { current } = this.state;
    return (
      <div className={stl.card}>
        <div className={stl.list} style={{ height }}
             onTouchEnd={this.touchEnd} onScroll={this.scroll}
             ref={this.elm}>
          {_.map(list, (i, k) => (
            <div className={stl.item} key={k}>
              {i}
            </div>
          ))}
        </div>
        <div className={stl.anchorBlock}>
          {_.map(list, (__, k) => (
            <div className={cx(stl.anchor, current === k && stl.active)} key={k} />
          ))}
        </div>
      </div>
    );
  }
}

interface IProps {
  height?: string;
  list: (React.ReactElement | string)[];
}
