import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import config from '@config';

Sentry.init({
  dsn: config.SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.7,
  environment: process.env.NODE_ENV,
});
const needTrack = process.env.NODE_ENV === 'production';

export function info(...args) {
  console.info(...args);
}

export function error(...args) {
  console.error(...args);
  if (!needTrack) {
    return;
  }
  if (args.length === 1) {
    if (typeof args[0] === 'string') {
      Sentry.captureException(new Error(args[0]), { level: 'error' });
    } else {
      Sentry.captureException(new Error(JSON.stringify(args[0])), { level: 'error' });
    }
  } else {
    Sentry.captureException(new Error(JSON.stringify(args)), { level: 'error' });
  }

}
