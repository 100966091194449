import * as React from 'react';
import { connect } from 'dva';
import cx from 'classnames';
import stl from './style.module.less';

export class GlobalLoading extends React.PureComponent<any, any> {
  render() {
    const { manual, loading } = this.props;
    return (
      <div className={cx(stl.layout, { [stl.active]: manual || loading })}>
        <div className={stl.mask} />
        <div className={stl.content} />
      </div>
    );
  }
}

export default connect(({ loading }) => ({
  loading: loading.global,
}))(GlobalLoading);
