import * as React from 'react';
import * as _ from 'lodash-es';
import { createRoot } from 'react-dom/client';
let root;
import SidebarViews from '@components/SidebarViews';
import icnListCheck from '@images/icn-list-checked.png';
import { pageview } from '@libs/ga';
import cx from 'classnames';
import config from '@config';
import { error, info } from '@libs/log';
import { withSign } from '@mina-modules/sign';
import rq from '@utils/request';
import stl from './style.less';
export default class LocationViews extends React.PureComponent<IProps, any> {
  static async show({ province, city, onUpdate }) {
    const elm = document.createElement('div');
    elm.id = `location`;
    document.body.appendChild(elm);
    // todo: 这里没想好数据更新方式，先模拟小程序端的实现了。
    const provinceList = await getProvinces();
    root = createRoot(elm);
    root.render(<LocationViews provinceList={provinceList}
                                   province={province} city={city}
                                   onUpdate={onUpdate} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_location`);
    const { currentProvince, currentCity } = getCurrent(props.provinceList, props.province, props.city);
    this.state = {
      visible: !_.isEmpty(props.provinceList),
      currentProvince,
      currentCity,
    };
  }

  close = () => {
    const elm = document.getElementById(`location`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  pickProvince = (evt) => {
    const { index } = evt.currentTarget.dataset;
    const item = this.props.provinceList[index];
    if (/^全/.test(item.name)) {
      this.props.onUpdate({
        province: '',
        city: '',
      });
      this.close();
      return;
    }
    this.setState({
      currentProvince: item,
    });
  };

  pickCity = (evt) => {
    const { index } = evt.currentTarget.dataset;
    const item = this.state.currentProvince.children[index];
    const province = this.state.currentProvince.name;
    if (/^全/.test(item.name)) {
      this.props.onUpdate({
        province,
        city: '',
      });
      this.close();
      return;
    }
    this.setState({
      currentCity: item,
    });
    this.props.onUpdate({
      province,
      city: item.name,
    });
    this.close();
  };

  render() {
    const { provinceList } = this.props;
    const { visible, currentProvince, currentCity } = this.state;
    return !_.isEmpty(provinceList) && (
      <SidebarViews visible={visible} onClose={this.close}>
        <div className={stl.block}>
          <div className={stl.provinces}>
            {_.map(provinceList, (province, index) => (
              <div className={cx(stl.province, { [stl.active]: currentProvince.name === province.name })}
                   key={province.name} onClick={this.pickProvince} data-index={index}>
                {province.name}
              </div>
            ))}
          </div>
          <div className={stl.cities}>
            {_.map(currentProvince.children, (city, index) => (
              <div className={cx(stl.city, { [stl.active]: currentCity.name === city.name })}
                   key={city.name} onClick={this.pickCity} data-index={index}>
                {city.name}
                {currentCity.name === city.name && (
                  <img className={stl.checked} src={icnListCheck} />
                )}
              </div>
            ))}
          </div>
        </div>
      </SidebarViews>
    );
  }
}

interface IProps {
  provinceList: any[];
  province: string;
  city: string;

  onUpdate(obj): void;
}

let _provinces: Province[] = [];
interface Province {
  name: string,
  children: City[]
}
interface City {
  name: string
}

function formatProvinces(res) {
  const result: Province[] = [];
  if (res.current) {
    result.push({
      name: '当前城市',
      children: [{
        name: res.current.name,
      }],
    });
  }
  if (res.hot) {
    result.push({
      name: '热门',
      children: _.map(res.hot, (c) => ({
        name: c.name,
      })),
    });
  }
  result.push({
    name: '全国',
    children: [],
  });
  res.all?.forEach(i => {
    const child = _.map(i.children, (c) => ({
      name: c.name,
    }));
    const all = {
      name: `全部${i.name}`,
    };
    result.push({
      name: i.name,
      children: isMunicipalities(i.name) ? child : _.concat([all], child),
    });
  });
  return result;
}

function isMunicipalities(province) {
  return ['北京', '天津', '上海', '重庆'].includes(province);
}

async function getProvinces() {
  if (_.isEmpty(_provinces)) {
    try {
      const url = `${config.HOST_JAVA}/location`;
      info(`get ${url}`);
      const resp = await rq(withSign({
        url,
      }));
      _provinces = formatProvinces(resp.data);
    } catch (e) {
      error(e);
      throw e;
    }
  }
  return JSON.parse(JSON.stringify(_provinces));
}

function getCurrent(list, province, city) {
  for (let i = list.length - 1; i > -1; i--) {
    const item = list[i];
    if (city) {
      const target = _.find(item.children, c => c.name === city);
      if (target) {
        return {
          currentProvince: item,
          currentCity: target,
        };
      }
    } else {
      if (item.name === province) {
        return {
          currentProvince: item,
          currentCity: _.first(item.children),
        };
      }
    }
  }
  const target = _.find(list, i => i.name === '全国');
  return {
    currentProvince: target,
    currentCity: {},
  };
}
