import * as React from 'react';
import icnArrow from '@assets/icon/icn-arrow-right@2x.png';
import * as _ from 'lodash-es';
import { event } from '@libs/ga';
import cx from 'classnames';
import stl from './style.less';

export default class Ranking extends React.PureComponent<IProps, any> {
  search = (evt) => {
    const { index } = evt.currentTarget.dataset;
    const item = this.props.list[index];
    event({
      category: 'ranking_search',
      action: 'click',
      label: `${item.name}`,
    });
    this.props.onClick(item.query);
  };

  render() {
    const { list } = this.props;
    return _.map(list, (item, index) => (
      <div className={cx(stl.rank, { [stl.last]: index + 1 === list.length })} key={item.name}
           onClick={this.search} data-index={index}>
        <div className={stl.label}>{item.name}</div>
        <div className={stl.value}>{item.rank}</div>
        <img className={stl.arrow} src={icnArrow} />
      </div>
    ));
  }
}

interface IProps {
  list: {
    name: string;
    rank: number;
    query: string,
  }[];

  onClick(query): void;
}
