import React from 'react';
import * as _ from 'lodash-es';
import { connect } from 'dva';
import { os } from '@utils/browser';
import DoctorItem from '@components/DoctorItem';
import ShadowLine from '@components/ShadowLine';
import CityPicker from '@components/CityPicker';
import md5 from 'blueimp-md5';
import cx from 'classnames';
import stl from './style.less';
import icnCheckGray from '@images/icn-checked-gray.png';
import config from '@config';

export class DoctorCard extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  toDoctor = item => {
    this.props.dispatch({
      type: 'doctor/fetchDoctor',
      payload: {
        id: item.id,
      },
    });
  };

  search = params => {
    this.props.dispatch({
      type: 'search/fetchDoctorIntent',
      payload: params,
    });
  };

  loadMore = () => {
    this.search({
      page: this.props.item?.params?.page + 1,
    });
  };

  filterBy = () => {
    const value = !this.state.checked;
    this.search({
      page: 1,
      hasOnline: value,
    });
    this.setState({
      checked: value,
    });
  };

  render() {
    const { item } = this.props;
    return !_.isEmpty(item) && (
      <div className={cx(stl.card, { [stl.pc]: os.isPc })}>
        {(item.params.hospitalName || item.params.doctorName) ? (
          <div className={stl.context}>
            {item.context}
          </div>
        ) : (
          <div className={stl.filters}>
            <CityPicker key={md5(JSON.stringify(item))} onSearch={this.search}
                        province={_.get(item, 'params.province')}
                        city={_.get(item, 'params.city')} />
            {config.sponsor.hasService && (
              <div className={stl.filterOnline} onClick={this.filterBy}>
                <span className={stl.checkbox}>
                  {this.state.checked && <img className={stl.icnSelected} src={icnCheckGray} alt="icon" />}
                </span>
                <span style={{ marginLeft: 6, marginBottom: -1 }}>仅显示可线上问诊医生</span>
              </div>
            )}
          </div>
        )}
        {_.map(item.list, (doctor, index) => (
          <div className={cx(stl.item, { [stl.last]: index + 1 === item.list.length && _.isEmpty(item.url) })}
               key={doctor.id}>
            <DoctorItem item={doctor} onClick={this.toDoctor} />
          </div>
        ))}
        {!_.isEmpty(item.url) && (
          <div className={cx(stl.item, stl.last)}>
            <div className={stl.more} onClick={this.loadMore}>加载更多</div>
          </div>
        )}
        {os.isMobile && (
          <ShadowLine />
        )}
      </div>
    );
  }
}

export default connect()(DoctorCard);
