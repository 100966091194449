import md5 from 'blueimp-md5';
import _ from 'lodash';
import moment from 'moment';
import rq from '@utils/request';
import { withSign } from '@mina-modules/sign';
import config from '@config';
import { error, info } from '@libs/log';
import { formatText, qsStringify } from '@utils/index';
import icnApprove from '@images/icn-approve.png';
import icnReference from '@images/icn-reference-circle@3x.png';
import icnWeb from '@assets/icon/icn-web@2x.png';

export async function fetchDiseaseArticleTypes(diseaseId: string): Promise<any> {
  try {
    const url = `${config.HOST_JAVA}/romens/medical_topic/${diseaseId}/articles/types`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    info(resp);
    return _.map(resp?.data, (item) => ({
      name: item.typeName,
      value: item.typeValue,
    }));
  } catch (e) {
    error(e);
    throw e;
  }
}

export async function fetchDiseaseArticle(topicId: string): Promise<any> {
  try {
    const url = `${config.HOST_JAVA}/romens/medical_topic/disease/${topicId}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    info(resp);
    return formatDiseaseArticle(resp?.data || {});
  } catch (e) {
    error(e);
    throw e;
  }
}
export async function fetchDiseaseArticles(diseaseId: string, type: string, page: number, size: number): Promise<any> {
  try {
    const query = qsStringify({
      page,
      size,
    });
    const url = `${config.HOST_JAVA}/romens/medical_topic/${diseaseId}/articles/${type}${query}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    info(resp);
    return _.map(resp?.data, formatDiseaseArticle);
  } catch (e) {
    error(e);
    throw e;
  }
}

function formatDiseaseArticle(res = {} as any) {
  if (res.index_card_type === 'cms_content') {
    return {
      id: res.unique_id,
      title: res.title,
      subtitle: res.short_answer_text || '',
      summary: res.core_content_text || '',
      topic: {
        id: res.topic_id,
        type: {
          name: res.topic_type_name,
          value: res.topic_type_value,
        },
      },
      source: { 
        name: '参考文献',
        id: res.references ? md5(res.references) : '',
        logo: icnReference,
        content: res.references,
        url: '',
      },
      author: {
        logo: res.author?.avator,
        name: res.author?.name,
        desc: res.updated_at && `更新于 ${moment(res.updated_at).format('YYYY 年 M 月 D 日')}` || '',
        approved: true,
      },
    };
  }
  const author = formatFaqAuthor(res);
  return {
    id: res.unique_id,
    title: res.title,
    summary: res.content,
    topic: {
      id: res.topic_id,
      type: {
        name: res.topic_type_name,
        value: res.topic_type_value,
      },
    },
    source: { 
      id: res.source,
      name: res.source,
      logo: res.logo || icnWeb,
      url: res.url,
      mina: res.mina,
    },
    author: {
      name: _.compact([author.name, author.title]).join('・'),
      logo: res.doctor?.avatar || res.author?.avatar,
      desc: _.compact([author.hospital, author.department]).join('・'),
      approvedTag: author.tag === '权威专家' ? {
        name: '权威专家',
        logo: icnApprove,
      } : null,
    },
  };
}

function formatFaqAuthor(res) {
  const doctor = res.doctor;
  const author = res.author;
  const defaultDoctorAvatar = '/images/icn-doctor-default@3x.png';

  if (doctor) {
    return {
      id: formatText(doctor.doctor_id),
      name: formatText(doctor.doctor_name),
      hospital: formatText(doctor.hospital_name),
      title: formatText(doctor.title),
      department: formatText(doctor.department_name),
      avatar: doctor.avatar || defaultDoctorAvatar,
      isPower: res.authority_doctor,
      tag: doctor.tags?.[0] || '', // 前后端约定：目前医生的标签，只取数组的第一个
      description: formatText(
        doctor.doctor_desc || doctor.department_desc || doctor.hospital_desc,
      ),
    };
  }
  if (author) {
    return {
      id: formatText(author.doctor_id),
      name: formatText(author.name),
      hospital: formatText(author.organization),
      title: formatText(author.title),
      department: formatText(author.department),
      tags: author.department_tag || [],
      avatar: author.avatar || defaultDoctorAvatar,
    };
  }
  return {};
}

