// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_JP5nk {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  /* 60px 为 外部 Sidebar 头部高度 */
  margin: 0 -45px;
  /* 抵消外部 Sidebar 的 padding */
}
.title_N9jL2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 15px;
  color: #656C7C;
  margin-bottom: 36px;
  padding: 0 45px;
}
.cards_Grc73 {
  flex: 1;
  overflow: auto;
  padding: 0 45px;
}
.card_UYFla {
  margin-bottom: 36px;
}
.card_UYFla:last-child {
  min-height: 80%;
}
`, "",{"version":3,"sources":["webpack://./src/components/pc/PredictViews/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,2BAA2B;EAA3B,eAAA;EAEA,2BAA2B;AAC7B;AAAA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;AAEF;AACA;EACE,OAAA;EACA,cAAA;EACA,eAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAEA;EACE,eAAA;AAAF","sourcesContent":[".main {\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 60px); /* 60px 为 外部 Sidebar 头部高度 */\n  margin: 0 -45px; /* 抵消外部 Sidebar 的 padding */\n}\n\n.title {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  font-size: 15px;\n  color: #656C7C;\n  margin-bottom: 36px;\n  padding: 0 45px;\n}\n\n.cards {\n  flex: 1;\n  overflow: auto;\n  padding: 0 45px;\n}\n\n.card {\n  margin-bottom: 36px;\n}\n.card:last-child {\n  min-height: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `main_JP5nk`,
	"title": `title_N9jL2`,
	"cards": `cards_Grc73`,
	"card": `card_UYFla`
};
export default ___CSS_LOADER_EXPORT___;
