import * as React from 'react';
import IcnMiniProgram from '@components/icons/IcnMiniProgram';
import IcnArrowDown from '@components/icons/IcnArrowDown';
import PicQrcode from '@components/icons/PicQrcode';
import stl from './style.less';

import User from '@utils/user';

export default class IndexHeader extends React.PureComponent<any, any> {
  get isLogin() {
    return User.isLogin(window.location.href);
  }

  handleHeaderMenuClick = () => {
    User.logout();
  };

  render() {
    return (
      <div className={stl.header}>
        <div className={stl.item}>
          <IcnMiniProgram />
          <div className={stl.text}>微信小程序</div>
          <IcnArrowDown />
          <div className={stl.popover}>
            <div className={stl.hints}>使用微信扫一扫小程序码</div>
            <PicQrcode className={stl.qrcode} />
          </div>
        </div>
        {this.isLogin ? <div className={stl.item}>
          {User.avatar ? <img className={stl.logo} src={User.avatar} /> : <img className={stl.logo} />}
         <div className={stl.popoverSmail} onClick={this.handleHeaderMenuClick}>
            <div className={stl.hints}>退出登录</div>
          </div>
        </div> : <a href='/login'> <div className={stl.item}>
         <div className={stl.text}>登录</div>
        </div></a>}        
        {/*临时隐藏入口*/}
        {/*<div className={stl.item}>*/}
        {/*  <IcnPrivate />*/}
        {/*  <div className={stl.text}>隐私政策</div>*/}
        {/*  <IcnArrowDown />*/}
        {/*</div>*/}
      </div>
    );
  }
}
