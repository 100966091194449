import * as React from 'react';
import stl from './style.less';
import cx from 'classnames';
import { os } from '@utils/browser';
import PredictProgress from '@components/PredictProgress';

const PredictList: React.FunctionComponent<IProps> = ({title, list, onClickItem}) => {
  if(!list?.length) {
      return null;
  }

  return (
    <div className={stl.list}>
      <div className={stl.title}>{title}</div>
      <div className={cx(stl.row, { [stl.mobile]: os.isMobile })}>
        {list.map(item => (
          <div className={stl.row__cell} key={item.id}>
            <div className={stl.item} onClick={() => onClickItem(item)}>
            <div className={stl.text}>{item.name}</div>
            <PredictProgress percentage={item.score} style={{width: '50px'}}/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

interface IProps {
  title: string;
  list: IItem[];
  onClickItem?: (item: IItem) => void;
}

interface IItem {
  name: string;
  id: string;
  score: number;
}

export default PredictList;
