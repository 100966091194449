import ReactGA from 'react-ga';
import * as _ from 'lodash-es';
import { getQueryId, getUid } from '@utils/cache';
import config from '@config';

ReactGA.initialize('UA-115768895-12', {
  gaAddress: 'https://ask-ga.nocode.com/analytics.js',
  titleCase: false, // 打点名称不会大小写转译，默认值为true，坏的很
});
// gaOptions 设置 name 不行，别问，没时间了。
ReactGA.set({
  appName: config.sponsor.product,
  userId: getUid(),
});

export function event(event) {
  if (process.env.NODE_ENV === 'production') {
    if (getQueryId()) {
      // 下面的操作参考 https://developers.google.com/analytics/devguides/collection/analyticsjs/model-object-reference
      ReactGA.ga(tracker => {
        const originalSendHitTask = tracker.get('sendHitTask');
        tracker.set('sendHitTask', model => {
          let hitPayload = model.get('hitPayload');
          if (/queryid=/.test(hitPayload)) {
            hitPayload = hitPayload.replace(/queryid=.{32}/, `queryid=${getQueryId()}`);
          } else {
            hitPayload = hitPayload + `&queryid=${getQueryId()}`;
          }
          model.set('hitPayload', hitPayload, true);
          originalSendHitTask(model);
        });
      });
    }
    ReactGA.event(event);
  }
}

export function pageview(pathname) {
  if (process.env.NODE_ENV === 'production') {
    const ua = _.get(window, 'navigator.userAgent');
    ReactGA.set({ page: pathname, dimension1: ua });
    ReactGA.pageview(pathname);
  }
}
