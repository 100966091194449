import { createBrowserHistory } from 'history';
import { qsStringify } from '@utils/index';

const browserHistory = createBrowserHistory();

export default browserHistory;

export function setQuery(newQuery) {
  const newQs = qsStringify(newQuery);
  if (newQs !== location.search) {
    history.pushState({}, '', newQs);
  }
}
