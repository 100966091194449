import * as React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import * as _ from 'lodash-es';
import cx from 'classnames';
import SidebarViews from '@components/SidebarViews';
import { event, pageview } from '@libs/ga';
import ContactListViews from '@components/ContactListViews';
import stl from './style.less';
import { getTags } from '@components/VaccineItem';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import icnNav from '@assets/icon/icn-nav@2x.png';
import icnPhone from '@assets/icon/icn-phone@2x.png';
import config from '@config';

export default class VaccineViews extends React.PureComponent<any, any> {
  static show(item) {
    const elm = document.createElement('div');
    elm.id = `drugstore_${item.id}`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<VaccineViews item={item} />);
  }

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
    pageview(`${location.pathname}#!views_vaccine`);
  }

  toLocation = () => {
    const { item } = this.props;
    event({
      category: 'drugstore-views_location',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    if (item.address) {
      window.open(
        `http://api.map.baidu.com/geocoder?address=${item.address}&output=html`
      );
    }
  };

  toPhone = () => {
    const { item } = this.props;
    event({
      category: 'drugstore-views_phone',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });

    const length = item?.contacts?.length;
    if (length) {
      if (length === 1) {
        window.location.href = `tel:${_.get(item, `contacts[0].phone`)}`;
      } else {
        ContactListViews.show(item.contacts);
      }
    }
  };

  // toMp = () => {
  //   const { item } = this.props;
  //   event({
  //     category: 'drugstore-views_mp',
  //     action: 'click',
  //     label: `${item.id}_${item.name}`,
  //   });
  //   MpListViews.show(item.mpList);
  // };

  close = () => {
    const { item } = this.props;
    const elm = document.getElementById(`drugstore_${item.id}`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { item } = this.props;
    const { visible } = this.state;

    return (
      <SidebarViews visible={visible} onClose={this.close}>
        <div className={cx(stl.title, stl.bold)}>{item.name}</div>
        <div className={stl.tags}>{getTags(item)}</div>
        {item.openingHours && (
          <div className={stl.openingHours}>营业时间：{item.openingHours}</div>
        )}
        <div
          className={cx(stl.addressWrap, stl.hover)}
          onClick={this.toLocation}>
          <div className={stl.address}>{item.address}</div>
          <div className={stl.arrow}>
            <IcnArrowRight />
          </div>
        </div>
        <div className={stl.entrance}>
          {config.sponsor.hasLocation && (
            <div
              className={cx(stl.wrap, stl.nav, stl.hover, {
                [stl.disable]: !item.address,
              })}
              onClick={this.toLocation}>
              <img className={stl.icon} src={icnNav} />
            </div>
          )}
          <div
            className={cx(stl.wrap, stl.phone, stl.hover, {
              [stl.disable]: !item?.contacts?.length,
            })}
            onClick={this.toPhone}>
            <img className={stl.icon} src={icnPhone} />
          </div>
        </div>
        <div className={stl.hints}>提示：前往接种前，建议先电话咨询确认。</div>
        <div style={{ height: 60 }} />
      </SidebarViews>
    );
  }
}
