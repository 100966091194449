import sponsorConfig from '../../sponsor';

export default {
  SENTRY_DSN: 'https://da79c77cfe884e8cab48a96a89e26dd4@sentry.nocode.com/7',
  appId: 'wx431371cd870a55ac',
  sponsor: sponsorConfig(location.hostname),
  token: 'T8RnFKJQLUuju1QLw/WjecH0Y/a2Vri9',
  tokenV3: 'XmBf1Mf5w1uW!K%P0uk2#Bm1LoTN*FyC',
  secretKey: 'OcbMKvVtXwA8q77zywfeNr52uLiplSs2',
  secretId: 'AKIDpQms79dbLVaZIk4qmUBeMrhHleJi2nUv',
  cloudAppId: 1259253122,
  appName: 'ask.api',
};
