import * as React from 'react';
import * as _ from 'lodash-es';
import { createRoot } from 'react-dom/client';
let root;
import SidebarViews from '@components/SidebarViews';
import { pageview } from '@libs/ga';
import stl from './style.less';

export default class ProfileViews extends React.PureComponent<any, any> {
  static show(item) {
    const elm = document.createElement('div');
    elm.id = `profile`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<ProfileViews item={item} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_profile`);
    this.state = {
      visible: !!props.item,
    };
  }

  close = () => {
    const elm = document.getElementById(`profile`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { item } = this.props;
    const { visible } = this.state;
    return (
      <SidebarViews visible={visible} onClose={this.close}>
        {!!item && (
          <>
            <div className={stl.title}>{item.name}简介</div>
            {!_.isEmpty(item.expertiseArea) && (
              <span className={stl.expertise}>{item.expertiseArea}</span>
            )}
            {!_.isEmpty(item.profile) && (
              <span className={stl.summary}>{item.profile}</span>
            )}
            <div style={{ height: '60px' }} />
          </>
        )}
      </SidebarViews>
    );
  }
}
