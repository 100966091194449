export const ENTER_PRESS_CODE = 13;

export const TXYD = '腾讯医典';
export const YLYS = '有来医生';
export const YHJK = '怡禾健康';
export const YPZL = '药品助理';
export const JZZL = '就诊助理';
export const LCSY = '临床试验';
export const YLMT = '医联媒体';
export const JTYSZX = '家庭医生在线';
export const YXW = '医修网';
export const QYW = '求医网';
export const HSJC = '核酸检测';
export const YMYYJZ = '疫苗预约接种';
export const DQFXDJ = '地区风险等级';
export const CXZC = '出行政策';
export const JZWW = '就诊问问';

export const banners = [
  '· 中国医药信息查询平台',
  '· 有来医生',
  '· 腾讯医典',
  '· 好大夫在线',
  '· 百科名医等权威医疗信息网站',
];

export const empty_text = '暂时没有找到相关信息，我们会不断完善';
export const email = 'wuma@nocode.com';

export const tips = '此服务暂未开通或收录';
export const numMap = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
  10: '十',
  11: '十一',
  12: '十二',
};
