import * as React from 'react';
import stl from './style.less';

const QueryCorrection: React.FunctionComponent<IProps> = (props) => {
  const search = () => {
    props.onSearch(props.raw);
  };

  if (!props.tip) {
    return null;
  }

  return (
    <div className={stl.lines}>
      <div className={stl.line}>
        为你显示<span className={stl.bold}>「{props.tip}」</span>的搜索结果
      </div>
      <div className={stl.line}>
        仍然搜索
        <div className={stl.fill} onClick={search}>
          {props.raw}
        </div>
      </div>
    </div>
  );
};

interface IProps {
  raw: string; // 原始的搜索值
  tip: string; // 纠正的搜索值
  onSearch: (keywords: string) => void;
}

export default QueryCorrection;
