import * as React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import stl from './style.less';
import icon from '@styles/iconfont.less';
import checkImg from '@images/icn-medical-checked.png';
import moment from 'moment';
import { getMinaGhCodeBy } from '@utils/logo';
import WxOpenLaunchWeapp from '@components/mobile/WxOpenLaunchWeapp';
import { JZWW } from '@constants/index';
import { event } from '@libs/ga';

const offset = 20;
export default class MorningPost extends React.PureComponent<IProps, any> {
  elm: any = React.createRef();
  state = {
    current: 0,
  };
  startLeft = 0;
  onTouchStart = evt => {
    const { scrollLeft } = evt.currentTarget;
    this.startLeft = scrollLeft;
  };
  onTouchEnd = evt => {
    const { scrollLeft, clientWidth } = evt.currentTarget;
    this.updateScrollLeft(scrollLeft, clientWidth);
  };
  onForward = () => {
    const { scrollLeft, clientWidth } = this.elm.current;
    this.updateScrollLeft(scrollLeft + offset, clientWidth);
  };
  updateScrollLeft = (scrollLeft, clientWidth) => {
    const value = calcScrollLeft(this.startLeft, scrollLeft, clientWidth);
    this.elm.current.scrollLeft = value;
    const current = Math.floor(value / clientWidth);
    if (this.state.current !== current) {
      event({
        category: 'morning_post_slide',
        action: 'click',
        label: '',
      });
    }
    this.setState({
      current,
    }, () => {
      if (this.state.current + 1 === this.props.list.length) {
        this.props.loadMore();
      }
    });
  };

  render() {
    const { list } = this.props;
    return (
      <div className={stl.page}>
        <div className={stl.title}>每日健康早报</div>
        <div className={stl.list}
             onTouchStart={this.onTouchStart} onTouchEnd={this.onTouchEnd}
             ref={this.elm}>
          {_.map(list, item => (
            <div className={stl.item} key={item.id}>
              <div className={stl.card}>
                <div className={stl.card__head}>
                  <div className={stl.day}>
                    <div>{moment(item.calendar.date).format('M月D日')}</div>
                    <div>{item.calendar.weekDay}</div>
                  </div>
                </div>
                <img className={stl.tag} src={item.logo} />
                <div className={stl.card__title}>{item.title}</div>
                <div className={stl.quote}>
                  <div className={stl.quote__opinion}>{item.opinion}</div>
                  <div className={stl.quote__desc}>{item.content}</div>
                </div>
                <div className={stl.card__foot}>
                  <div className={stl.source}>
                    <img className={stl.icn} src={checkImg} />
                    <div>就诊问问医学团队审核</div>
                  </div>
                </div>
              </div>
              {false && item?.article?.title && (
                <div className={stl.card}>
                  <div className={stl.article}>
                    <div className={stl.article__left}>
                      <div className={stl.article__desc}>{item?.article?.desc}</div>
                      <div className={cx(stl.article__title, stl['one-line'])}>
                        {item?.article?.title}
                      </div>
                    </div>
                    <div className={stl.btn}>查看</div>
                  </div>
                  <WxOpenLaunchWeapp condition height={50}
                                     username={getMinaGhCodeBy(JZWW)}
                                     path={`pages/search/search?q=${encodeURIComponent(item?.article?.title)}`} />
                </div>
              )}
              {list.length > 1 && (
                <div className={stl.navigation}
                     onClick={this.onForward}>
                  前一日
                  <div className={cx(icon.iconfont, icon.icnArrow)} />
                </div>
              )}
              <div style={{ height: 80 }} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

function calcScrollLeft(startLeft, scrollLeft, clientWidth) {
  const rest = scrollLeft % clientWidth;
  if (startLeft < scrollLeft) {
    return scrollLeft - rest + (rest >= offset ? clientWidth : 0);
  }
  return scrollLeft - rest + (rest <= clientWidth - offset ? 0 : clientWidth);
}

interface IProps {
  list: any,

  loadMore(): void;
}
