// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_IuHQh {
  overflow: hidden;
  background-color: rgba(216, 216, 216, 0.1);
}
.footer_IuHQh.pc_hG7yt {
  height: 108px;
}
.footer_IuHQh.pc_hG7yt .text_lBGRw {
  font-size: 14px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}
.footer_IuHQh.mobile_CmiM9 {
  height: 160px;
}
.footer_IuHQh.mobile_CmiM9 .text_lBGRw {
  font-size: 12px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}
.text_lBGRw {
  font-size: 14px;
  color: #636B7A;
  white-space: pre-wrap;
  text-align: center;
}
.wrap_Cnrb7 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.margin_vXxBT {
  display: block;
  text-align: center;
  margin-top: 15px;
}
.logo_jN5DZ {
  margin-right: 5px;
}
.href_boZR0 {
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/style.less"],"names":[],"mappings":"AAIA;EACE,gBAAA;EACA,0CAAA;AAHF;AAKE;EACE,aAAA;AAHJ;AAEE;EAII,eAAA;EACA,gBAAA;EACA,yBAAA;AAHN;AAOE;EACE,aAAA;AALJ;AAIE;EAII,eAAA;EACA,gBAAA;EACA,yBAAA;AALN;AAUA;EACE,eAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;AARF;AAWA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AATF;AAYA;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;AAVF;AAaA;EACE,iBAAA;AAXF;AAcA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAZF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.footer {\n  overflow: hidden;\n  background-color: rgba(216, 216, 216, 0.1);\n\n  &.pc {\n    height: 108px;\n\n    .text {\n      font-size: 14px;\n      font-weight: 300;\n      color: @black90;\n    }\n  }\n\n  &.mobile {\n    height: 160px;\n\n    .text {\n      font-size: 12px;\n      font-weight: 300;\n      color: @black90;\n    }\n  }\n}\n\n.text {\n  font-size: 14px;\n  color: #636B7A;\n  white-space: pre-wrap;\n  text-align: center;\n}\n\n.wrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.margin {\n  display: block;\n  text-align: center;\n  margin-top: 15px;\n}\n\n.logo {\n  margin-right: 5px;\n}\n\n.href {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer_IuHQh`,
	"pc": `pc_hG7yt`,
	"text": `text_lBGRw`,
	"mobile": `mobile_CmiM9`,
	"wrap": `wrap_Cnrb7`,
	"margin": `margin_vXxBT`,
	"logo": `logo_jN5DZ`,
	"href": `href_boZR0`
};
export default ___CSS_LOADER_EXPORT___;
