// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_cSCC0 {
  margin: 10%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.logo_lmtd1 {
  width: 200px;
  align-self: center;
  justify-self: center;
}
.query_X5s1y {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px;
}
.query_X5s1y .btn_RuvLf {
  margin-left: 10px;
}
.answer_iwtYG {
  flex: 1;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  background-color: #f2f2f2;
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Nocobot/style.module.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;AADF;AAIA;EACE,YAAA;EACA,kBAAA;EACA,oBAAA;AAFF;AAKA;EACE,OAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AAHF;AAFA;EAQI,iBAAA;AAHJ;AAOA;EACE,OAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;AALF","sourcesContent":["@import (reference)'~@styles/vars';\n\n.body {\n  margin: 10%;\n  display: flex;\n  position: relative;\n  flex-direction: column;\n}\n\n.logo {\n  width: 200px;\n  align-self: center;\n  justify-self: center;\n}\n\n.query {\n  flex: 1;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n  margin-top: 20px;\n\n  .btn {\n    margin-left: 10px;\n  }\n}\n\n.answer {\n  flex: 1;\n  margin-top: 20px;\n  padding: 10px;\n  font-size: 16px;\n  line-height: 1.5;\n  background-color: #f2f2f2;\n  border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `body_cSCC0`,
	"logo": `logo_lmtd1`,
	"query": `query_X5s1y`,
	"btn": `btn_RuvLf`,
	"answer": `answer_iwtYG`
};
export default ___CSS_LOADER_EXPORT___;
