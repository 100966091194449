import * as React from 'react';
import * as _ from 'lodash-es';
import { event } from '@libs/ga';
import { connect } from 'dva';
import { os } from '@utils/browser';
import { withDefault } from '@utils/index';
import ShadowLine from '@components/ShadowLine';
import stl from './style.less';

export class IntentExplore extends React.PureComponent<any, any> {
  search = evt => {
    const { index } = evt.currentTarget.dataset;
    const { list, query, dispatch } = this.props;
    const intent = list[index];
    event({
      category: 'intent-explore_search',
      action: 'click',
      label: `${intent.id}`,
    });
    dispatch({
      type: 'search/search',
      payload: {
        query: withDefault(intent.query, query),
      },
    });
  };

  render() {
    const { list, context } = this.props;
    return !_.isEmpty(list) && (
      <>
        <div className={stl.header}>{context}</div>
        <div className={stl.list}>
          {_.map(list, (item, index) => (
            <div className={stl.item} key={item.id}
                 onClick={this.search} data-index={index}>
              <div className={stl.wrap} style={{ backgroundColor: item.style }}>
                <div className={stl.logo}>{item.logo}</div>
              </div>
              <div className={stl.text}>{item.intent}</div>
            </div>
          ))}
          <div className={stl.i} />
        </div>
        {os.isMobile && (
          <ShadowLine />
        )}
      </>
    );
  }
}

export default connect(({ search }) => ({
  query: search.query,
  list: search.relatedIntent.list,
  context: search.relatedIntent.context,
}))(IntentExplore);
