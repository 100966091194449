// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_TCrY7 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title_EGRpg {
  margin-top: 70px;
  width: 88px;
  height: 22px;
  opacity: 0.8;
  font-family: PingFangSC;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #000;
}
.desc_BVy6P {
  margin-top: 15px;
  width: 281px;
  height: 72px;
  opacity: 0.66;
  font-family: PingFangSC;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.71;
  text-align: center;
  color: #000;
}
.btn_zA2C1 {
  margin-top: 20px;
  width: 160px;
  height: 44px;
  border-radius: 22px;
  background-color: #519bf0;
  font-family: PingFangSC;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon_O3jij {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 5px;
}
.phone_zf19s {
  margin-top: 50px;
  width: 279px;
  height: 450px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MinaGoto/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACF;AAEA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;AAAF;AAGA;EACE,gBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AADF;AAIA;EACE,gBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFF;AAKA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;AAHF;AAMA;EACE,gBAAA;EACA,YAAA;EACA,aAAA;AAJF","sourcesContent":[".body {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.title {\n  margin-top: 70px;\n  width: 88px;\n  height: 22px;\n  opacity: 0.8;\n  font-family: PingFangSC;\n  font-size: 22px;\n  font-weight: 600;\n  line-height: 1;\n  text-align: center;\n  color: #000;\n}\n\n.desc {\n  margin-top: 15px;\n  width: 281px;\n  height: 72px;\n  opacity: 0.66;\n  font-family: PingFangSC;\n  font-size: 14px;\n  font-weight: 300;\n  line-height: 1.71;\n  text-align: center;\n  color: #000;\n}\n\n.btn {\n  margin-top: 20px;\n  width: 160px;\n  height: 44px;\n  border-radius: 22px;\n  background-color: #519bf0;\n  font-family: PingFangSC;\n  font-size: 14px;\n  font-weight: 600;\n  color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.icon {\n  width: 18px;\n  height: 18px;\n  object-fit: contain;\n  margin-right: 5px;\n}\n\n.phone {\n  margin-top: 50px;\n  width: 279px;\n  height: 450px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `body_TCrY7`,
	"title": `title_EGRpg`,
	"desc": `desc_BVy6P`,
	"btn": `btn_zA2C1`,
	"icon": `icon_O3jij`,
	"phone": `phone_zf19s`
};
export default ___CSS_LOADER_EXPORT___;
