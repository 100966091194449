import React, { Suspense } from 'react';
import { pageview, event } from '@libs/ga';
import { Button } from 'antd';
import stl from './style.module.less';
import { qsParse } from '@utils/index';
import FeedbackViews from '@components/mobile/FeedbackWxViews';

export default class MorningPost extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    pageview('hospital');
    this.state = {
      name: '',
      url: '',
    };
  }

  componentDidMount () {
    const { location } = this.props;
    const search = qsParse(location.search);
    const id = search?.id || '';
    const name = search?.name || '';
    if (id && name) {
      this.setState({
        url: `https://open.weixin.qq.com/qr/code?username=${id}`,
        name,
      });
    }
    document.title = '预约挂号';
  }

  showFeedback = () => {
    event({
      category: 'hospital_qr_showfeedback',
      action: 'click',
      label: '',
    });
    global['app']._store.dispatch({
      type: 'search/setQuery',
      payload: {
        value: `医院二维码_${this.state.name}`,
      },
    });
    FeedbackViews.show();
  };

  change = (e) => {
    this.setState({
      query: e.target.value,
    });
  };

  render() {
    const { name, url } = this.state;
    return (
      <Suspense fallback>
        <div className={stl.body}>
          <div className={stl.name}>{name}</div>
          <div className={stl.desc}>此医院官方公众号提供线上预约挂号服务。可长按识别下方二维码前往医院公众号进行预约挂号。</div>
          <div className={stl.logo}>
            <img src={url} className={stl.img} />
            <div className={stl.tip}>长按识别二维码前往挂号</div>
          </div>
          <Button type="text" onClick={this.showFeedback} className={stl.btn}>
            问题反馈
          </Button>
        </div>
        
      </Suspense>
    );
  }
}

