// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_COI2d {
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin: 14px 15px 0;
  cursor: pointer;
  position: relative;
}
.block_COI2d:active {
  background-color: #F5F5F5;
}
.logo_D_OPQ {
  flex: none;
  margin-left: 15px;
}
.name_H4T6P {
  flex: none;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text_EggBY {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 20px;
  margin-left: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.arrow_FaiLD {
  flex: none;
  display: flex;
  margin-left: 6px;
  margin-right: 15px;
}
.moreText_pp7KC {
  font-size: 13px;
  color: #636B7A;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,qCAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AAHF;AAMA;EACE,yBAAA;AAJF;AAOA;EACE,UAAA;EACA,iBAAA;AALF;AAQA;EACE,UAAA;EACA,eAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AANF;AASA;EACE,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAPF;AAUA;EACE,UAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;AARF;AAWA;EACE,eAAA;EACA,cAAA;AATF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.block {\n  display: flex;\n  align-items: center;\n  height: 50px;\n  border-radius: 8px;\n  border: 1px solid rgba(0, 0, 0, 0.06);\n  margin: 14px 15px 0;\n  cursor: pointer;\n  position: relative;\n}\n\n.block:active {\n  background-color: #F5F5F5;\n}\n\n.logo {\n  flex: none;\n  margin-left: 15px;\n}\n\n.name {\n  flex: none;\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.9);\n  margin-left: 6px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.text {\n  font-size: 13px;\n  color: rgba(0, 0, 0, 0.5);\n  padding-left: 20px;\n  margin-left: auto;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.arrow {\n  flex: none;\n  display: flex;\n  margin-left: 6px;\n  margin-right: 15px;\n}\n\n.moreText {\n  font-size: 13px;\n  color: #636B7A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `block_COI2d`,
	"logo": `logo_D_OPQ`,
	"name": `name_H4T6P`,
	"text": `text_EggBY`,
	"arrow": `arrow_FaiLD`,
	"moreText": `moreText_pp7KC`
};
export default ___CSS_LOADER_EXPORT___;
