import * as React from 'react';
import icnArrow from '@assets/icon/icn-arrow-right@2x.png';
import * as _ from 'lodash-es';
import { formatText } from '@utils/index';
import stl from './style.less';

export default class ProfileBlock extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      summary: '',
    };
  }

  static getDerivedStateFromProps(props) {
    if (_.isEmpty(props.profile)) {
      return null;
    }
    return {
      summary: formatText(props.profile.replace(/\s/g, '')).slice(0, 140).concat('...'),
    };
  }

  render() {
    const { onClick } = this.props;
    const { summary } = this.state;
    return (
      <div className={stl.block} onClick={onClick}>
        <span className={stl.profile}>{summary}</span>
        <div className={stl.wrap}>
          <span className={stl.view}>查看详情</span>
          <img className={stl.tail} src={icnArrow} />
        </div>
      </div>
    );
  }
}

interface IProps {
  profile: string;

  onClick(): void;
}
