import * as React from 'react';
import stl from './style.less';

const PredictProgress = ({percentage, style}: IProps) => {
  return (
    <div className={stl.progress} style={style}>
      <div className={stl.progress__inner} style={{
        height:'100%',
        width: `${percentage}%`,
        background: percentage >= 67 ? '#5AD0C5' : '#F4C09C',
      }}/>
    </div>
  );
};


interface IProps {
  percentage: number,
  style: React.CSSProperties,
}

export default PredictProgress;
