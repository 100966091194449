import * as React from 'react';
import * as _ from 'lodash-es';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import stl from './style.less';

export function getTags(item: IProps['item']) {
  return _.map(item.tagList, (v) => (
    <div className={stl.tag} key={v}>
      {v}
    </div>
  ));
}

export default class VaccineItem extends React.PureComponent<IProps, any> {
  get value() {
    const { distance, address } = this.props.item;
    return _.compact([distance, address]).join(' · ');
  }

  click = () => {
    const { onClick, item } = this.props;
    onClick(item);
  };

  render() {
    const { item } = this.props;
    return (
      <div className={stl.item} onClick={this.click}>
        <div style={{ overflow: 'hidden', marginLeft: '15px' }}>
          <div className={stl.name}>{item.name}</div>
          {!(_.isEmpty(item.distance) && _.isEmpty(item.address)) && (
            <div className={stl.info}>{this.value}</div>
          )}
          <div className={stl.tags}>{getTags(item)}</div>
        </div>
        <div className={stl.arrow}>
          <IcnArrowRight />
        </div>
      </div>
    );
  }
}

interface IProps {
  item: {
    id: string,
    name: string,
    address: string,
    contacts: string,
    geo: any,
    distance: string,
    tagList: string[],
  };

  onClick(item): void;
}
