import * as React from 'react';
import cx from 'classnames';
import stl from './style.less';

export default class ActivityViews extends React.PureComponent<IProps, any> {
  close = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { visible, children } = this.props;
    return (
      <>
        <div className={stl.cover} onClick={this.close} />
        <div className={cx(stl.views, { [stl.visible]: visible })}>
          {children}
        </div>
      </>
    );
  }
}

interface IProps {
  visible: boolean;

  onClose(): void;
}
