import * as qs from 'qs';
import * as _ from 'lodash-es';
import Format from '../libs/nocode-format';
import { formatMultiSpace } from '@services/search';
import React from 'react';

const cached = {};
export const registerModel = (app, model) => {
  if (!cached[model.namespace]) {
    app.model(model);
    cached[model.namespace] = true;
  }
};

export const noop = () => null;

export function stopPropagation(evt) {
  evt.stopPropagation();
}

export function compact(key, value) {
  return value
    ? {
        [key]: value,
      }
    : {};
}

export function appendQuery(payload) {
  const search = window.location.search;
  const query = qsParse(search);
  const newQuery = {
    ...query,
    ...payload,
    userUid: query.userUid,
  };
  const newQs = qsStringify(newQuery);
  history.replaceState({}, '', newQs);
}

export function qsStringify(keyValues, options?) {
  return qs.stringify(keyValues, {
    addQueryPrefix: true,
    skipNulls: true,
    arrayFormat: 'repeat',
    ...options,
  });
}

export function qsParse(search, options?) {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
    ...options,
  });
}

export function withDefault(value, defaultValue) {
  return value || defaultValue;
}

export function getDateSet(obj) {
  return _.reduce(
    obj,
    (result, value, label) => {
      if (/^data-.*/.test(label)) {
        result[label.slice(5)] = value;
      }
      return result;
    },
    {}
  );
}

export function isEmpty(value) {
  return (
    _.isEmpty(value) ||
    //every不适用于 number 类型的判断
    _.every(value, (i) => _.every(i, _.isEmpty))
  );
}

export function conditionValue(condition, value, defaultValue) {
  return condition ? value : defaultValue;
}

const ncFormat = new Format({
  nouns: [],
  disableNouns: true,
  autoSync: false,
});

export function formatText(value) {
  return ncFormat.formatText(value);
}

const regex = /[\u4E00-\u9FFF]/g;

export function calcFlexWidth(
  content,
  fontSize,
  maxWidth,
  letterSpacing = 0.4
) {
  if (maxWidth < fontSize) {
    return '100%';
  }
  const count = content.length;
  const plumpFontCount = (content.match(regex) || []).length;
  const row = Math.min(
    Math.ceil(
      count /
        Math.floor(
          (maxWidth / fontSize) * (6 / 7 + ((1 / 7) * plumpFontCount) / count)
        )
    ),
    count
  );
  const width = Math.min(
    Math.ceil(count / row) * (fontSize + letterSpacing),
    maxWidth
  );
  return `${width}px`;
}

export function getLabel(list) {
  if (!list || !list.length) {
    return '';
  }
  return list[0].label;
}

export function typeOf(obj) {
  if (_.isEmpty(obj)) {
    return '';
  }
  if (_.isArray(obj)) {
    return 'array';
  }
  return typeof obj;
}

/**
 * 把纯文本的文章转换成具有段落感的 HTML 文档
 * @param value
 * @returns
 */
export function formatArticle(value: string) {
  value = formatMultiSpace(value);

  return value.split('\n').map((v, index) => (
    <p style={{ margin: '15px 0' }} key={index}>
      {v}
    </p>
  ));
}

export function memoize(fn) {
  const cache = new Map();
  const cached = function (...args) {
    const key = JSON.stringify(args);
    if (!cache.has(key)) {
      cache.clear();
      cache.set(key, fn.call(this, ...args));
    }
    return cache.get(key);
  };
  return cached;
}

