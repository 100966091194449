import * as React from 'react';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import WxOpenLaunchWeapp from '@components/mobile/WxOpenLaunchWeapp';
import stl from './style.less';
import { getMinaGhCodeBy } from '@utils/logo';
import cx from 'classnames';
import ClipboardJS from 'clipboard';
import { os, showAlert } from '@utils/browser';

export default class Navigation extends React.PureComponent<IProps, any> {
  clipboard: any;

  componentDidMount() {
    if (os.isMina) {
      this.clipboard = new ClipboardJS(`.navigation-copy-field`);
      this.clipboard.on('success', () => {
        showAlert('网址已复制');
      });
    }
  }

  componentWillUnmount() {
    this.clipboard && this.clipboard.destroy();
  }

  render() {
    const { onClick, logo, name, text, mina, url, className } = this.props;

    return !!name && (
      <>
        <div className={cx(stl.block, className, 'navigation-copy-field')} data-clipboard-text={url} onClick={onClick}>
          <div className={stl.logo}>{logo}</div>
          <div className={stl.name}>来自{name}</div>
          <div className={stl.text}>{text}</div>
          <div className={stl.moreText}>查看更多</div>
          <div className={stl.arrow}>
            <IcnArrowRight />
          </div>
          <WxOpenLaunchWeapp condition={mina.appid || mina.path} height={50}
                             username={getMinaGhCodeBy(name)} path={mina.path} />
        </div>
      </>
    );
  }
}

interface IProps {
  logo: React.ReactElement;
  name: string;
  text?: string;
  mina: any,
  url?: string;
  className?: string;

  onClick?(): void;
}
