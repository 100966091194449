// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_nO5G0 {
  width: 640px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 50px auto;
  overflow: hidden;
}
.item_mTcOh {
  cursor: pointer;
  position: relative;
}
.item_mTcOh:before {
  content: '';
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  position: absolute;
  bottom: 0;
  left: 24px;
  right: 0;
}
.item_mTcOh:last-of-type:before {
  display: none;
}
.item_mTcOh:active {
  background-color: #F5F5F5;
}
`, "",{"version":3,"sources":["webpack://./src/components/HomeList/style.less"],"names":[],"mappings":"AAIA;EACE,YAAA;EACA,kBAAA;EACA,qCAAA;EACA,iBAAA;EACA,gBAAA;AAHF;AAMA;EACE,eAAA;EACA,kBAAA;AAJF;AAME;EACE,WAAA;EACA,4CAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,QAAA;AAJJ;AAOE;EACE,aAAA;AALJ;AAQE;EACE,yBAAA;AANJ","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.block {\n  width: 640px;\n  border-radius: 6px;\n  border: 1px solid @black05;\n  margin: 50px auto;\n  overflow: hidden;\n}\n\n.item {\n  cursor: pointer;\n  position: relative;\n\n  &:before {\n    content: '';\n    border-bottom: 1px solid @black03;\n    position: absolute;\n    bottom: 0;\n    left: 24px;\n    right: 0;\n  }\n\n  &:last-of-type:before {\n    display: none;\n  }\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `block_nO5G0`,
	"item": `item_mTcOh`
};
export default ___CSS_LOADER_EXPORT___;
