import * as React from 'react';
import cx from 'classnames';
import config from '@config';
import { os } from '@utils/browser';
import stl from './style.less';
import { email } from '@constants/index';

export default class Footer extends React.PureComponent<IProps, any> {
  get copyright() {
    return os.isPc ? config.sponsor.copyrightPc : config.sponsor.copyrightMobile;
  }

  get className() {
    return os.isPc ? stl.pc : stl.mobile;
  }

  render() {
    const { className } = this.props;
    return os.isPc ? (
      <div className={cx(stl.footer, this.className, className)}>
        <div className={stl.wrap}>
          <div className={stl.text}>{this.copyright}</div>
          &nbsp;&nbsp;&nbsp;
          <a className={stl.text} href="http://beian.miit.gov.cn/"
             target="_blank" rel="noreferrer">浙 ICP 备 17005035 号-2</a>
          &nbsp;&nbsp;&nbsp;
          <a className={stl.text} href={`mailto:${email}`} target="_blank" rel="noreferrer">联系我们</a>
        </div>
        <div className={stl.wrap}>
          <a className={stl.href} target="_blank"
             href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002963" rel="noreferrer">
            <img className={stl.logo} src="https://ask-cdn.nocode.com/gwab.png" />
            <div className={stl.text}>浙公网安备 33010902002963 号</div>
            &nbsp;&nbsp;&nbsp;
          </a>
          <div className={stl.text}>互联网药品信息服务资格证编号(浙)-经营性-2019-0071</div>
          &nbsp;&nbsp;&nbsp;
          <div className={stl.text}>浙 B2-20181004</div>
        </div>
      </div>
    ) : (
      <div className={cx(stl.footer, this.className, className)}>
        <div className={cx(stl.text, stl.margin)}>{this.copyright}</div>
        <a className={cx(stl.text, stl.margin)} href="http://beian.miit.gov.cn/"
           target="_blank" rel="noreferrer">浙 ICP 备 17005035 号-2</a>
        <a className={cx(stl.margin, stl.href)} target="_blank"
           href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002963" rel="noreferrer">
          <img className={stl.logo} src="https://ask-cdn.nocode.com/gwab.png" />
          <div className={stl.text}>浙公网安备 33010902002963 号</div>
        </a>
        <div className={cx(stl.text, stl.margin)}>互联网药品信息服务资格证编号(浙)-经营性-2019-0071</div>
        &nbsp;&nbsp;&nbsp;
        <div className={stl.text}>浙 B2-20181004</div>
      </div>
    );
  }
}

interface IProps {
  className?: string;
}
