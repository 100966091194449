// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon_D7HUO {
  width: 170px;
  height: 170px;
}
`, "",{"version":3,"sources":["webpack://./src/components/icons/PicQrcode/style.less"],"names":[],"mappings":"AAIA;EACE,YAAA;EACA,aAAA;AAHF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.icon {\n  width: 170px;\n  height: 170px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `icon_D7HUO`
};
export default ___CSS_LOADER_EXPORT___;
