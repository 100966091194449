// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rank_rMHaH {
  display: flex;
  align-items: center;
  height: 44px;
  position: relative;
}
.rank_rMHaH:active {
  background-color: #F5F5F5;
}
.rank_rMHaH:before {
  content: '';
  position: absolute;
  left: 20px;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.rank_rMHaH.last_uNFN8:before {
  display: none;
}
.label_jNbpD {
  font-size: 14px;
  font-weight: bold;
  color: #27334D;
  margin-left: 20px;
}
.value_cZXd5 {
  font-size: 14px;
  color: #27334D;
  margin-left: auto;
}
.arrow_WG1QC {
  flex: none;
  width: 5px;
  height: 10px;
  margin-left: 4px;
  margin-right: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Ranking/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AAHF;AAKE;EACE,yBAAA;AAHJ;AAME;EACE,WAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;EACA,SAAA;EACA,4CAAA;AAJJ;AAOE;EACE,aAAA;AALJ;AASA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AAPF;AAUA;EACE,eAAA;EACA,cAAA;EACA,iBAAA;AARF;AAWA;EACE,UAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AATF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.rank {\n  display: flex;\n  align-items: center;\n  height: 44px;\n  position: relative;\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n\n  &:before {\n    content: '';\n    position: absolute;\n    left: 20px;\n    right: 0;\n    bottom: 0;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.04);\n  }\n\n  &.last:before {\n    display: none;\n  }\n}\n\n.label {\n  font-size: 14px;\n  font-weight: bold;\n  color: #27334D;\n  margin-left: 20px;\n}\n\n.value {\n  font-size: 14px;\n  color: #27334D;\n  margin-left: auto;\n}\n\n.arrow {\n  flex: none;\n  width: 5px;\n  height: 10px;\n  margin-left: 4px;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rank": `rank_rMHaH`,
	"last": `last_uNFN8`,
	"label": `label_jNbpD`,
	"value": `value_cZXd5`,
	"arrow": `arrow_WG1QC`
};
export default ___CSS_LOADER_EXPORT___;
