import * as React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import ActivityViews from '@components/ActivityViews';
import moment from 'moment';
import IcnCheck from '@components/icons/IcnCheck';
import { event, pageview } from '@libs/ga';
import stl from './style.less';

const placeholder =
  `希望您能详细描述认为的问题，例如：\n` +
  `未理解我的意图；\n` +
  `没有解决我的问题；\n` +
  `数据内容有误（具体的错误）等`;
export default class FeedbackViews extends React.PureComponent<any, any> {
  static show() {
    const elm = document.createElement('div');
    elm.id = `feedback`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<FeedbackViews />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_feedback`);
    this.state = {
      visible: true,
      date: moment().format('YYYY.MM.DD HH:mm'),
      comment: '',
    };
  }

  close = () => {
    const elm = document.getElementById(`feedback`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  change = evt => {
    const { value } = evt.currentTarget;
    this.setState({
      comment: value,
    });
  };

  submit = () => {
    const { comment } = this.state;
    event({
      category: 'feedback-views_submit',
      action: 'click',
      label: comment,
    });
    global['app']._store.dispatch({
      type: 'feedback/submit',
      payload: {
        comment: comment,
      },
    });
    setTimeout(() => {
      this.close();
    }, 800);
  };

  render() {
    const { visible, date } = this.state;
    return (
      <ActivityViews visible={visible} onClose={this.close}>
        <>
          <div className={stl.title}>结果有问题，我要反馈</div>
          <div className={stl.log}>
            <div>
              <div className={stl.desc}>当前页面数据状态已记录</div>
              <div className={stl.date}>记录于 {date}</div>
            </div>
            <div className={stl.checkWrapper}>
              <IcnCheck className={stl.check} />
            </div>
          </div>
          <div className={stl.textareaWrapper}>
            <textarea className={stl.textarea}
                      placeholder={placeholder}
                      onChange={this.change} maxLength={1000} />
          </div>
          <div className={stl.submit} onClick={this.submit}>
            提交
          </div>
        </>
      </ActivityViews>
    );
  }
}
