// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover_C3BVn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 9;
}
.views_RiENX {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9;
}
.views_RiENX.visible_vzm0M {
  animation: fade-in_QpCdX linear 200ms forwards;
}
.views_RiENX.hidden_FRQIQ {
  animation: fade-out_aanpo linear 200ms forwards;
}
@keyframes fade-in_QpCdX {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes fade-out_aanpo {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/mobile/ActivityViews/style.less"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,qCAAA;EACA,UAAA;AAHF;AAMA;EACE,eAAA;EACA,OAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;EACA,0BAAA;EACA,qCAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,UAAA;AAJF;AAME;EACE,8CAAA;AAJJ;AAOE;EACE,+CAAA;AALJ;AASA;EACE;IACE,2BAAA;EAPF;EASA;IACE,wBAAA;EAPF;AACF;AAUA;EACE;IACE,wBAAA;EARF;EAUA;IACE,2BAAA;EARF;AACF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.cover {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: @black25;\n  z-index: 9;\n}\n\n.views {\n  position: fixed;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  background-color: #fff;\n  border-radius: 8px 8px 0 0;\n  border: 1px solid @black05;\n  box-sizing: border-box;\n  overflow-x: hidden;\n  overflow-y: auto;\n  z-index: 9;\n\n  &.visible {\n    animation: fade-in linear 200ms forwards;\n  }\n\n  &.hidden {\n    animation: fade-out linear 200ms forwards;\n  }\n}\n\n@keyframes fade-in {\n  from {\n    transform: translateY(100%);\n  }\n  to {\n    transform: translateY(0);\n  }\n}\n\n@keyframes fade-out {\n  from {\n    transform: translateY(0);\n  }\n  to {\n    transform: translateY(100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cover": `cover_C3BVn`,
	"views": `views_RiENX`,
	"visible": `visible_vzm0M`,
	"fade-in": `fade-in_QpCdX`,
	"hidden": `hidden_FRQIQ`,
	"fade-out": `fade-out_aanpo`
};
export default ___CSS_LOADER_EXPORT___;
