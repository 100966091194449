import * as React from 'react';
import cx from 'classnames';
import stl from './style.less';
import { event, pageview } from '@libs/ga';
import { getSourceLogo } from '@utils/logo';
import { navigateByType } from '@utils/browser';
import { HighlightItem, HighlightStyles } from '@services/search';
import ChineseMedicineHints from '@components/ChineseMedicineHints';
import DoctorItemMiniCard from '@components/DoctorItemMiniCard';
import Navigation from '@components/Navigation';

export default class FaqContent extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
    pageview(`${location.pathname}#!views_faq`);
  }

  get doctor() {
    const { item } = this.props;
    return item?.doctor || {};
  }

  toDoctor = () => {
    global['app']._store.dispatch({
      type: 'doctor/fetchDoctor',
      payload: {
        id: this.doctor.id,
      },
    });
  };

  toFaq = (item) => {
    event({
      category: 'faq-views_tofaq',
      action: 'click',
      label: `${item.id}_${item.title}_${item.source}`,
    });
    navigateByType(item, global['app']._store.dispatch, 'search/fetchFaq');
  };

  toBaike = (item) => {
    const map = {
      disease: 'search/fetchDisease',
      drug: 'search/fetchDrug',
      examine: 'search/fetchExamine',
    };
    navigateByType(item, global['app']._store.dispatch, map[item.type]);
  };

  render() {
    const { item, faqList } = this.props;
    return (
      <div className={stl.container}>
        <div className={stl.scrollBox}>
          <div
            className={cx(stl.title, {
              [stl.withHints]: item.category === '中药',
            })}>
            {item.title}
          </div>
          {item.category === '中药' && <ChineseMedicineHints />}
          <div className={stl.date}>{item.date}</div>
          {this.doctor.name && (
            <DoctorItemMiniCard item={this.doctor} onClick={this.toDoctor} />
          )}
          <div className={stl.summary}>
            {item.highlightResult?.map((v, i) =>
              v.style & HighlightStyles.COLOR ? (
                <span className={cx(stl.underline)} key={i}>
                  {v.text}
                </span>
              ) : v.style & HighlightStyles.NEWLINE ? (
                <div className={cx(stl.newline)} key={i}></div>
              ) : (
                v.text
              )
            )}
          </div>
          {!!item.nerList?.length ||
            (!!faqList.length && (
              <>
                <div className={stl.more}>探索更多内容</div>
                {faqList.map((faq) => (
                  <div
                    className={stl.faqWrap}
                    key={faq.id}
                    onClick={() => this.toFaq(faq)}>
                    <div className={cx(stl.faq, stl.oneLine)}>{faq.title}</div>
                  </div>
                ))}
                <div className={stl.wrap}>
                  {item.nerList?.map((ner) => (
                    <div
                      className={stl.ner}
                      key={ner.id}
                      onClick={() => this.toBaike(ner)}>
                      <div className={cx(stl.collapse, stl.oneLine)}>
                        {ner.name}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ))}
        </div>
        <div className={stl.viewMoreBox}>
          {item.url && (
            <Navigation
              name={item.source}
              logo={getSourceLogo(item.logo)}
              mina={item.mina}
              url={item.url}
            />
          )}
        </div>
      </div>
    );
  }
}

interface IProps {
  faqList: any[];
  item: {
    id: string,
    title: string,
    summary: string,
    category: string,
    date: string,
    nerList: any[],
    url: string,
    mina: any,
    source: string,
    logo: any,
    doctor: any,
    highlightResult: HighlightItem[],
  };
}
