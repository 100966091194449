const year = new Date().getFullYear();

interface ISponsorConfig {
  duplication: boolean;
  search: boolean;
  hasProduct: boolean;
  hasService: boolean;
  hasLocation: boolean;
  hasMorningPost: boolean;
  filterTXYD: boolean;
  feedback: boolean;
  product: string;
  logo: string;
  copyrightPc: string;
  copyrightMobile: string;
  themeBG: string,
  themeFG: string,
}

const defaultConfig: ISponsorConfig = {
  product: '就诊问问',
  logo: 'https://ask-cdn.nocode.com/logo-ask.png',
  copyrightPc: `© ${year} NoCode   无码科技（杭州）有限公司`,
  copyrightMobile: `© ${year} NoCode   无码科技（杭州）有限公司`,
  duplication: false,
  feedback: true,
  search: true,
  themeBG: '',
  themeFG: '',
  hasProduct: true,
  hasService: true,
  hasLocation: true,
  filterTXYD: false,
  hasMorningPost: true,
};
export default function(hostname): ISponsorConfig {
  switch (hostname) {
    case 'ask-malgo.nocode.com':
      return {
        ...defaultConfig,
        product: 'AICoach',
        duplication: true,
        search: false,
        themeBG: '#1BD37C',
        themeFG: '#16B86C',
        hasProduct: false,
        hasService: false,
        filterTXYD: true,
      };
    case 'ask-kktj.nocode.com':
      return {
        ...defaultConfig,
        product: 'AICoach',
        duplication: true,
        search: false,
        hasProduct: false,
        hasService: false,
        filterTXYD: true,
      };
    case 'ask-romens.nocode.com':
      return {
        ...defaultConfig,
        product: '健康问问',
        logo: 'https://ask-cdn.nocode.com/logo-jkww.png',
        copyrightPc: `© ${year} 雨诺股份 & 无码科技（杭州）有限公司`,
        copyrightMobile: `© ${year} 雨诺股份 & 无码科技（杭州）有限公司`,
        duplication: true,
        search: false,
        hasMorningPost: false,
      };
    case 'ask-romens-mina.nocode.com':
      return {
        ...defaultConfig,
        product: '健康问问',
        logo: 'https://ask-cdn.nocode.com/logo-jkww.png',
        hasProduct: false,
        feedback: false,
        hasService: false,
        hasLocation: false,
        duplication: true,
        hasMorningPost: false,
      };
    case 'ask.nocode.com':
    default:
      return defaultConfig;
  }
}
