// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover_MlfmH {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.views_YEPl1 {
  position: fixed;
  top: 0;
  right: 0;
  width: 570px;
  height: 100%;
  padding: 30px 45px 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform linear 200ms;
}
.views_YEPl1.visible_UfHf0 {
  box-shadow: -10px 0 30px 0 rgba(0, 0, 0, 0.02);
  transform: translateX(0);
}
.close_FeLID {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  cursor: pointer;
}
.closeBox_NGfT5 {
  position: sticky;
  top: 0;
  margin: -30px -45px 0;
  transform: translateY(-30px);
  height: 60px;
  background-color: white;
  overflow: hidden;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/pc/SidebarViews/style.less"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AAHF;AAMA;EACE,eAAA;EACA,MAAA;EACA,QAAA;EACA,YAAA;EACA,YAAA;EACA,oBAAA;EACA,sBAAA;EACA,qCAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,2BAAA;EACA,kCAAA;AAJF;AAME;EACE,8CAAA;EACA,wBAAA;AAJJ;AAQA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,eAAA;AANF;AASA;EACE,gBAAA;EACA,MAAA;EACA,qBAAA;EACA,4BAAA;EACA,YAAA;EACA,uBAAA;EACA,gBAAA;EACA,UAAA;AAPF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.cover {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.views {\n  position: fixed;\n  top: 0;\n  right: 0;\n  width: 570px;\n  height: 100%;\n  padding: 30px 45px 0;\n  background-color: #fff;\n  border: 1px solid @black05;\n  box-sizing: border-box;\n  overflow-x: hidden;\n  overflow-y: auto;\n  transform: translateX(100%);\n  transition: transform linear 200ms;\n\n  &.visible {\n    box-shadow: -10px 0 30px 0 @black02;\n    transform: translateX(0);\n  }\n}\n\n.close {\n  position: absolute;\n  top: 0;\n  left: 0;\n  padding: 20px;\n  cursor: pointer;\n}\n\n.closeBox {\n  position: sticky;\n  top: 0;\n  margin: -30px -45px 0;\n  transform: translateY(-30px);\n  height: 60px;\n  background-color: white;\n  overflow: hidden;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cover": `cover_MlfmH`,
	"views": `views_YEPl1`,
	"visible": `visible_UfHf0`,
	"close": `close_FeLID`,
	"closeBox": `closeBox_NGfT5`
};
export default ___CSS_LOADER_EXPORT___;
