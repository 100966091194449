import * as React from 'react';
import { withDefault } from '@utils/index';
import * as _ from 'lodash-es';
import cx from 'classnames';
import stl from './style.less';
import Comments from '@components/Comments';
import PowerTag from '@components/PowerTag';

export default class EasyItem extends React.PureComponent<IProps, any> {
  get reference() {
    const { item } = this.props;
    return (
      <div className={stl.powerWrap}>
        <span className={stl.oneLine}>{withDefault(_.compact([item.doctor.name, item.doctor.hospital, item.doctor.department]).join(' · '), item.source)}</span>
        {item.isPower ? <PowerTag style={{flexShrink: 0, border: '1px solid currentColor'}} /> : null}
      </div>
    );
  }

  get tagList() {
    const { item } = this.props;
    return _.get(item, 'doctor.tags');
  }

  click = () => {
    const { onClick, item } = this.props;
    onClick(item);
  };

  render() {
    const { item } = this.props;
    return (
      <div onClick={this.click}>
        <div className={stl.wrap}>
          <div className={stl.label}>原文：{item.title}</div>
        </div>
        <div className={stl.value}>{item.value}</div>
        <Comments logo={item.logo} reference={this.reference} />
        {!_.isEmpty(this.tagList) && (
          <div className={stl.tags}>
            {_.map(this.tagList, (i, index) => (
              <div className={cx(stl.tagItem, index === this.tagList.length - 1 && stl.oneLine)}
                   key={index}>
                {i}
              </div>
            ))}
          </div>
        )}
        <div style={{ height: '20px' }} />
      </div>
    );
  }
}

interface IProps {
  item: {
    doctor: any;
    tag: string;
    title: string;
    value: string;
    source: string;
    logo: React.ReactElement;
    isPower?: boolean;
  };

  onClick(item): void;
}
