import React from 'react';
import stl from './style.less';
import IconQuote from '@images/icn-quote.png';
import IconDoctorDefault from '@images/icn-doctor-default@3x.png';
import Comments from '@components/Comments';
import { getSourceLogo } from '@utils/logo';
import config from '@config';
import PowerTag from '@components/PowerTag';
import { HighlightStyles } from '@services/search';

export function ExpectCard({ item, onClick, style = null }) {
  const handleClick = () => onClick(item);

  return (
    <div className={stl.card} onClick={handleClick} style={style}>
      <div className={stl.doctorWrap} style={{ backgroundColor: config.sponsor.themeBG }}>
        <div className={stl.title}>{item.title}</div>
        <div className={stl.nameWrap}>
          <div className={stl.powerWrap}>
            <div className={stl.name}>{item.doctor?.name} {item.doctor?.title}</div>
            {item.isPower && <PowerTag style={{flexShrink: 0}} />}
          </div>
          <div className={stl.info}>{item.doctor?.hospital} · {item.doctor?.department}</div>
          {item.doctor?.description && <div className={stl.info}>{item.doctor?.description}</div>}
          <img className={stl.avatar} src={item.doctor?.avatar || IconDoctorDefault} />
        </div>
      </div>
      <div className={stl.summaryWrap}>
        <img className={stl.quote} src={IconQuote} />
        <span className={stl.summary}>
          {item.highlightResult?.map((v, i) =>
            v.style & HighlightStyles.COLOR ? (
              <span className={stl.strong} key={i}>
                {v.text}
              </span>
            ) : (
              v.text
            )
          )}
        </span>
      </div>
      <div className={stl.commentsBox}>
        <Comments logo={getSourceLogo(item.logo)} reference={`来自${item.source}`} style={{ flex: 1 }} />
      </div>
    </div>
  );
}
