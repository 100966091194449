import * as React from 'react';
import * as _ from 'lodash-es';
import cx from 'classnames';
import TabViews from '@components/TabViews';
import Navigation from '@components/Navigation';
import ChineseMedicineHints from '@components/ChineseMedicineHints';
import { navigateByType } from '@utils/browser';
import { event } from '@libs/ga';
import stl from './style.less';
import emptyImg from '@images/icn-baike-empty.png';

export default class BaikeContent extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      current: Math.max(_.findIndex(_.get(props.item, 'detailList'), i => i.key === props.kLabel), 0),
    };
  }

  get item() {
    const { item } = this.props;
    const { current } = this.state;
    return _.get(item, `detailList[${current}]`, {});
  }

  change = current => {
    this.setState({
      current,
    });
  };

  toView = () => {
    event({
      category: 'baike-content_navigation',
      action: 'click',
      label: `${this.item.source}_${this.item.url}`,
    });
    navigateByType(this.item);
  };

  render() {
    const { item } = this.props;
    const { current } = this.state;
    return _.isEmpty(item) ? (
      <div className={stl.empty}>
        <img className={stl.icon} src={emptyImg} alt="空" />
        <div className={stl.text}>未找到相关内容</div>
      </div>
    ) : (
      <>
        <div className={cx(stl.title, { [stl.withHints]: item.category === '中药' })}>
          {item.name}
        </div>
        {item.category === '中药' && (
          <ChineseMedicineHints />
        )}
        <TabViews current={current} list={item.detailList}
                  onChange={this.change} idPrefix={'BaikeViews'} />
        <Navigation name={this.item.source} text={this.item.name}
                    logo={this.item.logo} onClick={this.toView}
                    mina={this.item.mina}
                    url={this.item.url} />
      </>
    );
  }
}

interface IProps {
  kLabel?: string;
  item: {
    id: string;
    name: string;
    category: string;
    detailList: any[];
  };
}
