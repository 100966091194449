import React, { lazy, Suspense } from 'react';
import { connect } from 'dva';
import { os } from '@utils/browser';
import { pageview } from '@libs/ga';
import { qsParse } from '@utils/index';

const Pc = lazy(() => import(/* webpackChunkName: "BaikePc" */'@pages/Baike/pc'));
const Mobile = lazy(() => import(/* webpackChunkName: "BaikeMobile" */'@pages/Baike/mobile'));
@connect(({loading}) => ({
  loading: loading.effects['baike/search'],
}))
export default class Baike extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    pageview('baike');
    this.state = {};
  }

  componentDidMount () {
    const { dispatch, location } = this.props;
    const search = qsParse(location.search);
    const q = search?.q || '';
    if (q) {
      dispatch({
        type: 'baike/search',
        payload: {
          query: q,
        },
      });
    }
  }

  render() {
    const {loading} = this.props;
    if (loading === undefined || loading === true) {
        return null;
      }
    if (os.isPc) {
      return (
        <Suspense fallback>
          <Pc />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback>
          <Mobile />
        </Suspense>
      );
    }
  }
}

interface IProps {
  loading: boolean;
  location: Location;
  dispatch?(opt): void;
}
