import * as React from 'react';
import { fixedBody, os, unfixedBody } from '@utils/browser';
import SidebarViewsPc from '@components/pc/SidebarViews';
import SidebarViewsMobile from '@components/mobile/SidebarViews';
import { pageview } from '@libs/ga';

let Component;
export default class SidebarViews extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_sidebar`);
    Component = os.isPc ? SidebarViewsPc : SidebarViewsMobile;
  }

  componentDidMount() {
    fixedBody();
  }

  componentWillUnmount() {
    unfixedBody();
  }

  render() {
    const { visible, children, onClose, onScrollToLower } = this.props;
    return (
      <Component visible={visible} onClose={onClose}
                 onScrollToLower={onScrollToLower}>
        {children}
      </Component>
    );
  }
}

interface IProps {
  visible: boolean;

  onClose(): void;

  onScrollToLower?(): void;
}
