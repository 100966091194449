// 由于项目的 React 版本为 react@16x，而某些依赖（如 dva 使用的 react-router-dom 使用了 react@18x
// 导致该文件下许多的类型检查无法通过（如 Router, Switch, Route 的返回类型与 @types/react@16x 不兼容）
// 因此当前文件不做 TS 的静态检查，除非后期项目升级为 react@18
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import '@styles/normalize.less';
import { Route, Router, Switch } from 'dva/router';
import React from 'react';
import Home from '@pages/Home';
import Search from '@pages/Search';
import * as Sentry from '@sentry/react';
import Baike from '@pages/Baike';
import Disease from '@pages/Disease';
import DiseaseTopic from '@pages/DiseaseTopic';
import Faq from '@pages/Faq';
import MorningPost from '@pages/MorningPost';
import Nocobot from '@pages/Nocobot';
import Hospital from '@pages/Hospital';
import Login from '@pages/Login';

function FallbackComponent() {
  return (
    <div>An error has occurred</div>
  );
}

export default ({ history }) => (
  <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/bk" component={Baike} />
        <Route exact path="/disease" component={Disease} />
        <Route exact path="/disease-topic/:id" component={DiseaseTopic} />
        <Route exact path="/morning-post" component={MorningPost} />
        <Route exact path="/nocobot" component={Nocobot} />
        <Route exact path="/hospital" component={Hospital} />
        <Route exact path="/faq/:id" component={Faq} />
      </Switch>
    </Router>
  </Sentry.ErrorBoundary>
);
