import * as React from 'react';
import * as _ from 'lodash-es';
import { event } from '@libs/ga';
import RestItem from '@components/RestItem';
import { os } from '@utils/browser';
import { connect } from 'dva';
import FeedbackViews from '@components/mobile/FeedbackViews';
import cx from 'classnames';
import ShadowLine from '@components/ShadowLine';
import stl from './style.less';
import VaccineItem from '@components/VaccineItem';

export class VaccineCard extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: true,
    };
  }

  toVaccine = (item) => {
    event({
      category: 'vaccine-card_drugstore',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    this.props.dispatch({
      type: 'search/fetchVaccine',
      payload: {
        id: item.id,
      },
    });
  };

  expand = () => {
    event({
      category: 'vaccine-card_expand',
      action: 'click',
    });
    this.setState({
      isCollapse: false,
    });
  };

  showFeedback = () => {
    event({
      category: 'vaccinecard_showfeedback',
      action: 'click',
      label: '',
    });
    FeedbackViews.show();
  };

  render() {
    const { expandable, list, context } = this.props;
    const { isCollapse } = this.state;
    return (
      <div className={cx(stl.card, { [stl.pc]: os.isPc })}>
        <div className={stl.context}>
          {context}
          {os.isMobile && (
            <div className={stl.feedback} onClick={this.showFeedback}>
              问题反馈
            </div>
          )}
        </div>
        {_.map(
          list,
          (item, index) =>
            (expandable || !isCollapse || index < 6) && (
              <div className={stl.item} key={item.id}>
                <VaccineItem item={item} onClick={this.toVaccine} />
              </div>
            )
        )}
        {!expandable && isCollapse && list.length > 6 && (
          <div className={stl.item}>
            <RestItem count={list.length - 6} onClick={this.expand} />
          </div>
        )}
        {os.isMobile && <ShadowLine />}
      </div>
    );
  }
}

export default connect()(VaccineCard);
