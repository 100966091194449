import * as React from 'react';
import cx from 'classnames';
import stl from './style.less';

export default class Insurance extends React.PureComponent<IProps, any> {
  render() {
    const { item } = this.props;
    return !!item && (
      <>
        <div className={stl.row}>
          <div className={stl.label}>医保类别</div>
          <div className={stl.value}>{item.level}类</div>
        </div>
        <div className={cx(stl.row, stl.limit)}>
          <div className={stl.label}>支付说明</div>
          <div className={stl.value}>{item.pay_limit}</div>
        </div>
        <div className={stl.comments}>信息来源：{item.source_name}</div>
      </>
    );
  }
}

interface IProps {
  item: {
    level: string;
    pay_limit: string;
    source_name: string;
  };
}
