import * as React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import * as _ from 'lodash-es';
import SidebarViews from '@components/SidebarViews';
import { event, pageview } from '@libs/ga';
import icnArrow from '@assets/icon/icn-arrow-right@2x.png';
import icnConsultant from '@images/icn-online-consultation.png';
import icnMp from '@images/icn-mp-weixin.png';
import cx from 'classnames';
import ShadowLine from '@components/ShadowLine';
import { tips, TXYD } from '@constants/index';
import MpListViews from '@components/MpListViews';
import history from '@utils/history';
import ProfileBlock from '@components/ProfileBlock';
import ProfileViews from '@components/ProfileViews';
import { navigateByType, os, showAlert } from '@utils/browser';
import FaqItem from '@components/FaqItem';
import QaItem from '@components/QaItem';
import { compact, formatText, qsStringify } from '@utils/index';
import { error, info } from '@libs/log';
import config from '@config';
import rq from '@utils/request';
import { withSign } from '@mina-modules/sign';
import { formatMultiSpace, splitTengXunYiDian } from '@services/search';
import { getSourceLogo } from '@utils/logo';
import PaperItem from '@components/PaperItem';
import stl from './style.less';
import ConsultantListViews from '@components/ConsultantListViews';
import moment from 'moment';

export default class DoctorViews extends React.PureComponent<IProps, any> {
  static show(item) {
    const elm = document.createElement('div');
    elm.id = `doctor_${item.id}`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<DoctorViews item={item} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_doctor`);
    this.state = {
      visible: !!props.item,
      tab: getCurrentTab(props.item),
      faq: {},
      qa: {},
      paper: {},
    };
  }

  toHospital = () => {
    event({
      category: 'doctor-views_tohospital',
      action: 'click',
      label: this.props.item.hospitalId,
    });
    global['app']._store.dispatch({
      type: 'hospital/fetchHospital',
      payload: {
        id: this.props.item.hospitalId,
      },
    });
  };

  toConsultant = () => {
    event({
      category: 'doctor-views_toconsultant',
      action: 'click',
      label: '',
    });
    const { consultantList } = this.props.item;
    if (!consultantList.length) {
      return showAlert(tips);
    }
    return ConsultantListViews.show(consultantList);
  };

  toMp = () => {
    event({
      category: 'doctor-views_tomp',
      action: 'click',
      label: '',
    });
    const { mpList } = this.props.item;
    if (!mpList.length) {
      return showAlert(tips);
    }
    if (mpList.length > 1) {
      return MpListViews.show(mpList);
    }
    window.open(mpList[0].regUrl || mpList[0].url);
  };

  search = (evt) => {
    event({
      category: 'doctor-views_search',
      action: 'click',
      label: '',
    });
    const { index } = evt.currentTarget.dataset;
    const { item } = this.props;
    const ner = item.nerList[index];
    history.push('/search');
    global['app']._store.dispatch({
      type: 'search/search',
      payload: {
        query: ner.query,
      },
    });
    setTimeout(() => {
      this.close();
    }, 800);
  };

  toProfile = () => {
    event({
      category: 'doctor-views_toprofile',
      action: 'click',
      label: this.props.item.id,
    });
    ProfileViews.show(this.props.item);
  };

  tap1 = () => {
    this.setState({
      tab: 1,
    });
  };

  tap2 = () => {
    this.setState({
      tab: 2,
    });
  };

  tap3 = () => {
    this.setState({
      tab: 3,
    });
  };

  toFaq = item => {
    event({
      category: 'doctor-views_tofaq',
      action: 'click',
      label: `${item.id}_${item.title}_${item.source}`,
    });
    navigateByType(item, global['app']._store.dispatch, 'search/fetchFaq');
  };

  toQa = item => {
    event({
      category: 'doctor-views_toqa',
      action: 'click',
      label: `${item.id}_${item.title}_${item.source}`,
    });
    navigateByType(item, global['app']._store.dispatch, 'search/fetchQa');
  };

  toPaper = item => {
    event({
      category: 'doctor-views_topaper',
      action: 'click',
      label: `${item.id}_${item.title}_${item.source}`,
    });
    navigateByType(item, global['app']._store.dispatch, 'search/fetchPaper');
  };

  loadFaq = async () => {
    const { item } = this.props;
    const { faq } = this.state;
    const res = await fetchDoctorFaqList(item, faq.page);
    this.setState({
      faq: {
        list: _.concat(faq.list, res.list),
        page: res.page,
      },
    });
  };

  loadQa = async () => {
    const { item } = this.props;
    const { qa } = this.state;
    const res = await fetchDoctorQaList(item, qa.page);
    this.setState({
      qa: {
        list: _.concat(qa.list, res.list),
        page: res.page,
      },
    });
  };

  loadPaper = async () => {
    const { item } = this.props;
    const { paper } = this.state;
    const res = await fetchDoctorPaperList(item.id, paper.page);
    this.setState({
      paper: {
        list: _.concat(paper.list, res.list),
        page: res.page,
      },
    });
  };

  scrollToLower = () => {
    const { tab, faq, qa, paper } = this.state;
    switch (tab) {
      case 1:
        if (faq.page) {
          this.loadFaq();
        }
        break;
      case 2:
        if (qa.page) {
          this.loadQa();
        }
        break;
      case 3:
        if (paper.page) {
          this.loadPaper();
        }
        break;
    }
  };

  close = () => {
    const { item } = this.props;
    const elm = document.getElementById(`doctor_${item.id}`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  async componentDidMount() {
    const { item } = this.props;
    if (item?.tabTag?.faq) {
      const faq = await fetchDoctorFaqList(item);
      this.setState({
        faq,
      });
    }
    if (item?.tabTag?.qa) {
      const qa = await fetchDoctorQaList(item);
      this.setState({
        qa,
      });
    }
    if (item?.tabTag?.paper) {
      const paper = await fetchDoctorPaperList(item.id);
      this.setState({
        paper,
      });
    }
  }

  render() {
    const { item } = this.props;
    const { visible, tab, faq, qa, paper } = this.state;
    return (
      <SidebarViews visible={visible} onClose={this.close}
                    onScrollToLower={this.scrollToLower}>
        {!!item && (
          <>
            <div className={stl.info}>
              <div style={{ overflow: 'hidden' }}>
                <div className={stl.name}>{item.name}</div>
                {!_.isEmpty(item.tags) && (
                  <div className={stl.tags}>
                    {_.map(item.tags, tag => (
                      <div className={stl.tag} key={tag}>{tag}</div>
                    ))}
                  </div>
                )}
                <div className={stl.hospitalWrap} onClick={this.toHospital}>
                  <span className={stl.hospital}>{item.hospital}</span>
                  <img className={stl.arrow} src={icnArrow} />
                </div>
              </div>
              <img className={stl.logo} src={item.avatar} />
            </div>
            {!_.isEmpty(item.departmentInfo) && (
              <div className={stl.department}>{item.departmentInfo}</div>
            )}
            {config.sponsor.hasService ? (
              <div className={stl.entrance}>
                <div className={cx(stl.wrap, stl.consultant, { [stl.disable]: !item?.consultantList?.length })}
                     onClick={this.toConsultant}>
                  <img className={stl.icon} src={icnConsultant} />
                </div>
                <div className={cx(stl.wrap, stl.mp, { [stl.disable]: !item?.mpList?.length })}
                     onClick={this.toMp}>
                  <img className={stl.icon} src={icnMp} />
                </div>
              </div>
            ) : (
              <div style={{ height: '25px' }} />
            )}
            {os.isMobile && (
              <ShadowLine />
            )}
            {!(_.isEmpty(item.profile) && _.isEmpty(item.nerList)) && (
              <>
                <div className={stl.label}>简介及擅长领域</div>
                {!_.isEmpty(item.profile) && (
                  <ProfileBlock profile={item.profile} onClick={this.toProfile} />
                )}
                {!_.isEmpty(item.nerList) && (
                  <div className={stl.nerList}>
                    {_.map(item.nerList, (ner, index) => (
                      <div className={stl.ner} key={ner.id}
                           onClick={this.search} data-index={index}>
                        {ner.name}
                      </div>
                    ))}
                  </div>
                )}
                {os.isMobile && (
                  <ShadowLine />
                )}
              </>
            )}
            {tab > 0 && (
              <div className={stl.tabs}>
                {item.tabTag.faq && (
                  <div className={cx(stl.tab, { [stl.active]: tab === 1 })}
                       onClick={this.tap1}>
                    科普文章
                  </div>
                )}
                {item.tabTag.qa && (
                  <div className={cx(stl.tab, { [stl.active]: tab === 2 })}
                       onClick={this.tap2}>
                    问诊咨询
                  </div>
                )}
                {item.tabTag.paper && (
                  <div className={cx(stl.tab, { [stl.active]: tab === 3 })}
                       onClick={this.tap3}>
                    论文期刊
                  </div>
                )}
              </div>
            )}
            {tab === 1 &&
            _.map(faq.list, i => (
              <div className={stl.case} key={i.id}>
                <FaqItem item={i} onClick={this.toFaq} />
              </div>
            ))
            }
            {tab === 2 &&
            _.map(qa.list, i => (
              <div className={stl.case} key={i.id}>
                <QaItem item={i} onClick={this.toQa} />
              </div>
            ))}
            {tab === 3 &&
            _.map(paper.list, i => (
              <div className={stl.case} key={i.id}>
                <PaperItem item={i} onClick={this.toPaper} />
              </div>
            ))}
            <div style={{ height: '60px' }} />
          </>
        )}
      </SidebarViews>
    );
  }
}

interface IProps {
  item: any;
}

function formatDoctorFaqList(res, page, doctor) {
  return {
    list: _.map(res.data, e => {
      let summary = e.summary;
      const source = e.source;
      if (source === TXYD) {
        summary = splitTengXunYiDian(summary);
      }
      return {
        id: e.unique_id,
        title: formatText(e.title),
        summary: formatText(formatMultiSpace(summary)),
        date: moment(e.published_at, 'X').format('YYYY-MM-DD'),
        source,
        logo: getSourceLogo(e.logo),
        doctor: getSimpleDoctor(doctor),
        mina: {
          appid: _.get(e, 'mina.app_id'),
          path: _.get(e, 'mina.path'),
        },
        url: e.url,
      };
    }),
    page: res.data?.length > 0 ? page + 1 : 0,
  };
}

async function fetchDoctorFaqList(doctor, page = 1) {
  try {
    const data = qsStringify({
      ...compact('doctor_id', doctor.id),
      page: page,
      size: 20,
    });
    const url = `${config.HOST_JAVA}/doctor/pop_science/list/v2${data}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatDoctorFaqList(resp, page, doctor);
  } catch (e) {
    error(e);
    throw e;
  }
}

function formatDoctorQaList(res, page, doctor) {
  return {
    list: _.map(res.data, e => {
      let summary = e.first_reply;
      const source = e.source;
      if (source === TXYD) {
        summary = splitTengXunYiDian(summary);
      }
      return {
        id: e.unique_id,
        title: formatText(e.title),
        summary: formatText(formatMultiSpace(summary)),
        date: moment(e.published_at, 'X').format('YYYY-MM-DD'),
        source,
        logo: getSourceLogo(e.logo),
        doctor: getSimpleDoctor(doctor),
        mina: {
          appid: _.get(e, 'mina.app_id'),
          path: _.get(e, 'mina.path'),
        },
        url: e.url,
        webView: e.web_view,
      };
    }),
    page: res.data?.length > 0 ? page + 1 : 0,
  };
}

function getSimpleDoctor(res) {
  return {
    id: res.id,
    name: res.name,
    avatar: res.avatar,
    hospital: res.hospital,
    title: res.title,
    department: res.department,
  };
}

async function fetchDoctorQaList(doctor, page = 1) {
  try {
    const data = qsStringify({
      ...compact('doctor_id', doctor.id),
      page: page,
      size: 20,
    });
    const url = `${config.HOST_JAVA}/doctor/question/list/v2${data}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatDoctorQaList(resp, page, doctor);
  } catch (e) {
    error(e);
    throw e;
  }
}

function formatDoctorPaperList(res, page) {
  return {
    list: _.map(res.data, e => {
      return {
        id: e.paper_id,
        title: formatText(e.title),
        summary: formatText(formatMultiSpace(e.summary)),
        source: e.source_name,
        date: e.online_publish_date,
        logo: getSourceLogo(e.source_icon),
        url: e.source_url,
      };
    }),
    page: res.data?.length > 0 ? page + 1 : 0,
  };
}

export async function fetchDoctorPaperList(id, page = 1) {
  try {
    const data = qsStringify({
      page: page,
      page_size: 10,
    });
    const url = `${config.HOST_JAVA}/doctor/${id}/paper/v2${data}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatDoctorPaperList(resp, page);
  } catch (e) {
    error(e);
    throw e;
  }
}

function getCurrentTab(item) {
  if (item.tabTag.faq) {
    return 1;
  }
  if (item.tabTag.qa) {
    return 2;
  }
  if (item.tabTag.paper) {
    return 3;
  }
  return 0;
}
