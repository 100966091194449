import * as React from 'react';
import * as _ from 'lodash-es';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import stl from './style.less';
import cx from 'classnames';

export function getTags(item: IProps['item']) {
  const result = [];

  if (item.businessState) {
    result.push(
      <div className={cx(stl.tag, {strong: item.businessState==='未营业'})} key="1">
        {item.businessState}
      </div>
    );
  }
  if (item.isMedicare) {
    result.push(
      <div className={stl.tag} key="2">医保定点</div>
    );
  }

  return result;
}

export default class DrugstoreItem extends React.PureComponent<IProps, any> {
  get value() {
    const { distance, address } = this.props.item;
    return _.compact([distance, address]).join(' · ');
  }

  click = () => {
    const { onClick, item } = this.props;
    onClick(item);
  };

  render() {
    const { item } = this.props;
    return (
      <div className={stl.item} onClick={this.click}>
        <div style={{ overflow: 'hidden', marginLeft: '15px' }}>
          <div className={stl.name}>{item.name}</div>
          {!(_.isEmpty(item.distance) && _.isEmpty(item.address)) && (
            <div className={stl.info}>{this.value}</div>
          )}
          <div className={stl.tags}>
            {getTags(item)}
          </div>
        </div>
        <div className={stl.arrow}>
          <IcnArrowRight />
        </div>
      </div>
    );
  }
}

interface IProps {
  item: {
    id: string,
    name: string,
    address: string,
    contacts: string,
    geo: any,
    distance: string,
    businessState: string,
    isMedicare: boolean,
    openingHours: any,
  };

  onClick(item): void;
}
