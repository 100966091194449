import * as React from 'react';

export default class WxOpenLaunchWeapp extends React.PureComponent<IProps, any> {
  click = evt => {
    evt.stopPropagation();
  };

  render() {
    const { condition, height, username, path } = this.props;
    return condition ? (
      <wx-open-launch-weapp
        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        onClick={this.click}
        username={username}
        path={path}>
        <script type="text/wxtag-template">
          <div style={{ height: `${height}px` }} />
        </script>
      </wx-open-launch-weapp>
    ) : null;
  }
}

interface IProps {
  height: number;
  condition: boolean;
  username: string;
  path: string;
}
