// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hints_mnQjk {
  margin-top: 55px;
}
.line_RYa9P {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  margin-left: 15px;
  margin-right: 15px;
}
.mp_Q_8Zx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}
.mp_Q_8Zx:active {
  background-color: #F5F5F5;
}
.name_GiCqz {
  font-size: 16px;
  font-weight: bold;
  color: #2C7EDB;
}
.source_UySDM {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.66);
  margin-top: 12px;
}
.wrap_DyjHD {
  flex: none;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/components/MpListViews/style.less"],"names":[],"mappings":"AAIA;EACE,gBAAA;AAHF;AAMA;EACE,4CAAA;EACA,iBAAA;EACA,kBAAA;AAJF;AAOA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AALF;AAOE;EACE,yBAAA;AALJ;AASA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AAPF;AAUA;EACE,eAAA;EACA,0BAAA;EACA,gBAAA;AARF;AAWA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,iBAAA;EACA,iBAAA;AATF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.hints {\n  margin-top: 55px;\n}\n\n.line {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.04);\n  margin-left: 15px;\n  margin-right: 15px;\n}\n\n.mp {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 80px;\n  padding-left: 15px;\n  padding-right: 15px;\n  cursor: pointer;\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n}\n\n.name {\n  font-size: 16px;\n  font-weight: bold;\n  color: #2C7EDB;\n}\n\n.source {\n  font-size: 15px;\n  color: rgba(0, 0, 0, 0.66);\n  margin-top: 12px;\n}\n\n.wrap {\n  flex: none;\n  display: flex;\n  align-items: center;\n  font-size: 13px;\n  color: rgba(0, 0, 0, 0.5);\n  margin-left: auto;\n  margin-right: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hints": `hints_mnQjk`,
	"line": `line_RYa9P`,
	"mp": `mp_Q_8Zx`,
	"name": `name_GiCqz`,
	"source": `source_UySDM`,
	"wrap": `wrap_DyjHD`
};
export default ___CSS_LOADER_EXPORT___;
