import * as React from 'react';
import cx from 'classnames';
import IcnClose from '@components/icons/IcnClose';
import * as _ from 'lodash-es';
import stl from './style.less';

export default class SidebarViews extends React.PureComponent<IProps, any> {
  close = () => {
    const { onClose } = this.props;
    onClose();
  };

  scroll = evt => {
    const isBottom = evt.target.scrollHeight - evt.target.scrollTop === evt.target.clientHeight;
    if (isBottom) {
      const { onScrollToLower } = this.props;
      if (_.isFunction(onScrollToLower)) {
        onScrollToLower();
      }
    }
  };

  render() {
    const { visible, children } = this.props;
    return (
      <>
        <div className={stl.cover} onClick={this.close} />
        <div className={cx(stl.views, { [stl.visible]: visible })}
             onScroll={this.scroll}>
          <div className={stl.closeBox}>
            <div className={stl.close} onClick={this.close}>
              <IcnClose />
            </div>
          </div>
          {children}
        </div>
      </>
    );
  }
}

interface IProps {
  visible: boolean;

  onClose(): void;

  onScrollToLower?(): void;
}
