import React from 'react';
import cx from 'classnames';
import SidebarViews from '@components/SidebarViews';
import { pageview } from '@libs/ga';
import { createRoot } from 'react-dom/client';
let root;
import stl from './style.less';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import BusinessViews from '@components/BusinessViews';
import imgFocus from '@assets/icn-focus.png';
import imgAuthority from '@assets/icn-authority.png';
import imgSimple from '@assets/icn-simple.png';

export default class NextAIViews extends React.PureComponent<any, any> {
  static show(item) {
    const elm = document.createElement('div');
    elm.id = `NextAIViews`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<NextAIViews item={item} />);
  }
  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_next_ai`);
    this.state = {
      visible: !!props.item,
    };
  }

  close = () => {
    const elm = document.getElementById(`NextAIViews`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  toBusiness = () => {
    BusinessViews.show(this.props.item);
  };

  render() {
    const { visible } = this.state;
    return (
      <SidebarViews visible={visible} onClose={this.close}>
        <div className={stl.english}>Next AI</div>
        <div className={stl.chinese}>无码科技医疗知识图谱</div>
        <div className={stl.specialWrap}>
          <div className={cx(stl.special, stl.focus)}>
            <img className={stl.icon} src={imgFocus} />
          </div>
          <div className={cx(stl.special, stl.authority)}>
            <img className={stl.icon} src={imgAuthority} />
          </div>
          <div className={cx(stl.special, stl.simple)}>
            <img className={stl.icon} src={imgSimple} />
          </div>
        </div>
        <div className={stl.desc}>
          Next AI
          是无码科技基于知识图谱的专业医疗知识引擎，致力于为用户提供精准、权威且简洁的医疗知识。
        </div>
        <div className={stl.desc}>
          Next AI
          内的数据、内容与模型源于权威医学材料，经过算法模型和专业医生校验，以保证内容可靠性。
        </div>
        <div className={stl.business} onClick={this.toBusiness}>
          商务合作
          <IcnArrowRight className={stl.iconArrow} />
        </div>
        <div style={{ height: 80 }} />
      </SidebarViews>
    );
  }
}
