import * as React from 'react';
import * as _ from 'lodash-es';
import { createRoot } from 'react-dom/client';
let root;
import SidebarViews from '@components/SidebarViews';
import IcnPhone from '@components/icons/IcnPhone';
import { pageview } from '@libs/ga';
import stl from './style.less';

export default class ContactListViews extends React.PureComponent<any, any> {
  static show(list) {
    const elm = document.createElement('div');
    elm.id = `contactlist`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<ContactListViews list={list} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_contact_list`);
    this.state = {
      visible: !!props.list,
    };
  }

  close = () => {
    const elm = document.getElementById(`contactlist`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { list } = this.props;
    const { visible } = this.state;
    return (
      <SidebarViews visible={visible} onClose={this.close}>
        {!!list && (
          <div className={stl.block}>
            <div className={stl.line} />
            {_.map(list, (item, index) => (
              <div key={index}>
                <a className={stl.contact} href={`tel:${item.phone}`}>
                  <div>
                    {!!item.note && (
                      <div className={stl.name}>{item.note}</div>
                    )}
                    <div className={stl.phone}>{item.phone}</div>
                  </div>
                  <div className={stl.wrap}>
                    <IcnPhone />
                  </div>
                </a>
                <div className={stl.line} />
              </div>
            ))}
          </div>
        )}
      </SidebarViews>
    );
  }
}
