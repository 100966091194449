import md5 from 'blueimp-md5';

let _queryId = '';

function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

let _uid = '';

export function getUid(): string {
  if (!_uid) {
    _uid = guid();
  }
  return _uid;
}

export function generateQueryId(): void {
  const uid = getUid();
  _queryId = md5(`${uid}_${Date.now()}`);
}

export function getQueryId(): string {
  return _queryId;
}

