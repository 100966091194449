import React, { Suspense } from 'react';
import { pageview } from '@libs/ga';
import { fetchNocoBotQuery } from '@services/bot';
import { Input, Button } from 'antd';
import stl from './style.module.less';
import logo from '@assets/nocobot.png';

export default class MorningPost extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    pageview('nocobot');
    this.state = {
      answer: '',
      query: '',
    };
  }

  query = async () => {
    this.setState({
      answer: '正在查询中...',
    });
    const answer = await fetchNocoBotQuery(this.state.query);
    this.setState({
      answer,
    });
  };

  change = (e) => {
    this.setState({
      query: e.target.value,
    });
  };

  render() {
    const { answer } = this.state;
    return (
      <Suspense fallback>
        <div className={stl.body}>
          <img src={logo} className={stl.logo} />
          <div className={stl.query}>
            <Input size="large" placeholder="请输入" allowClear onChange={this.change} onPressEnter={this.query}/>
            <Button type="primary" size="large" onClick={this.query} className={stl.btn}>
              查询
            </Button>
          </div>
          {answer && (
            <div className={stl.answer}>{answer}</div>
          )}
        </div>
        
      </Suspense>
    );
  }
}
