import React from 'react';
import stl from './style.less';
import icnLoading from '@assets/loading.gif';

export default class ContainerLoading extends React.PureComponent<any, any> {
  render() {
    return <img className={stl.icon} src={icnLoading}></img>;
  }
}

