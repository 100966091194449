import React, { lazy, Suspense } from 'react';
import { connect } from 'dva';
import { os } from '@utils/browser';
import { pageview } from '@libs/ga';
import { qsParse } from '@utils/index';

const Pc = lazy(() =>
  import(/* webpackChunkName: "FaqPc" */ '@pages/Faq/pc')
);
const Mobile = lazy(() =>
  import(/* webpackChunkName: "FaqMobile" */ '@pages/Faq/mobile')
);
@connect()
export default class Faq extends React.PureComponent<IProps, any> {
  static getDerivedStateFromProps(props) {
    const { dispatch, location, match } = props;
    const search = qsParse(location.search);
    const id = match.params.id;
    if (id) {
      dispatch({
        type: 'faq/fetchFaq',
        payload: {
          id,
        },
      });
    }
    if (search.q) {
      dispatch({
        type: 'faq/fetchRelatedFaqList',
        payload: {
          query: search.q,
        },
      });
    }
    return null;
  }

  constructor(props) {
    super(props);
    pageview('faq');
    this.state = {};
  }

  render() {
    if (os.isPc) {
      return (
        <Suspense fallback>
          <Pc />
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback>
          <Mobile />
        </Suspense>
      );
    }
  }
}

interface IProps {
  dispatch?(opt): void;
}
