import * as React from 'react';
import cx from 'classnames';
import stl from './style.less';

export default function PicQrcode({ className }: IProps) {
  return (
    <img className={cx(stl.icon, className)} alt={'小程序码'}
         src={'https://ask-cdn.nocode.com/icn-mina.png'} />
  );
}

interface IProps {
  className?: string;
}
