// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_CMZDA {
  position: absolute;
  top: 60px;
  bottom: 20px;
  left: 0;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/FaqViews/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;AACF","sourcesContent":[".container {\n  position: absolute;\n  top: 60px;\n  bottom: 20px;\n  left: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container_CMZDA`
};
export default ___CSS_LOADER_EXPORT___;
