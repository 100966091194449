// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pc_L7QJM {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.item_mENVQ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  position: relative;
  cursor: pointer;
}
.item_mENVQ:active {
  background-color: #F5F5F5;
}
.text_FYBZv {
  font-size: 15px;
  font-weight: bold;
  color: #27334D;
  margin-left: 20px;
}
.icon_r7pc5 {
  flex: none;
  margin-right: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SuggestList/style.less"],"names":[],"mappings":"AAIA;EACE,kBAAA;EACA,qCAAA;AAHF;AAMA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;EACA,sBAAA;EACA,4CAAA;EACA,kBAAA;EACA,eAAA;AAJF;AAME;EACE,yBAAA;AAJJ;AASA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AAPF;AAUA;EACE,UAAA;EACA,kBAAA;AARF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.pc {\n  border-radius: 4px;\n  border: 1px solid @black06;\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 50px;\n  background-color: #fff;\n  border-bottom: 1px solid @black04;\n  position: relative;\n  cursor: pointer;\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n}\n\n\n.text {\n  font-size: 15px;\n  font-weight: bold;\n  color: #27334D;\n  margin-left: 20px;\n}\n\n.icon {\n  flex: none;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pc": `pc_L7QJM`,
	"item": `item_mENVQ`,
	"text": `text_FYBZv`,
	"icon": `icon_r7pc5`
};
export default ___CSS_LOADER_EXPORT___;
