import * as React from 'react';
import * as _ from 'lodash-es';
import icnNav from '@assets/icon/icn-nav@2x.png';
import icnPhone from '@assets/icon/icn-phone@2x.png';
import icnMp from '@images/icn-mp-weixin.png';
import { event } from '@libs/ga';
import ContactListViews from '@components/ContactListViews';
import MpListViews from '@components/MpListViews';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import cx from 'classnames';
import stl from './style.less';
import config from '@config';

export default class HospitalItem extends React.PureComponent<IProps, any> {
  get info() {
    const { item, isSuggested } = this.props;
    const result = isSuggested ?
      _.compact(_.concat([item.distance], (item.summaryList.length ? _.map(item.summaryList, i => i.value) : [item.priorInfo]), [item.serviceTag])).join('・') :
      _.compact(_.concat([item.distance, item.grade], item.tags, [item.serviceTag])).join('・');

    return result;
  }

  click = () => {
    const { onClick, item } = this.props;
    onClick(item);
  };

  toMp = (e) => {
    e.stopPropagation();
    const { item } = this.props;
    event({
      category: 'hospital-views_mp',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    MpListViews.show(item.mpList);
  };

  toPhone = (e) => {
    e.stopPropagation();
    const { item } = this.props;
    event({
      category: 'hospital-views_phone',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    ContactListViews.show(item.contacts);
  };

  toLocation = (e) => {
    e.stopPropagation();
    const { item } = this.props;
    event({
      category: 'hospital-views_location',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    window.open(`http://api.map.baidu.com/geocoder?address=${item.address}&output=html`);
  };

  render() {
    const { item, isExpand } = this.props;

    return (
      <div className={cx(stl.block, { [stl.expand]: isExpand })} onClick={this.click}>
        <div className={stl.item}>
          <img className={stl.logo} src={item.avatar} />
          <div style={{ overflow: 'hidden' }}>
            <div className={stl.name}>{item.name}</div>
            <div className={stl.info}>{this.info}</div>
          </div>
          <div className={stl.arrow}>
            <IcnArrowRight />
          </div>
        </div>
        {isExpand &&
        (<div className={stl.collapse}>
          {!_.isEmpty(item.branchList)
            ?
            _.map(item.branchList, branch => (
              <div className={stl.address} key={branch.address}>
                {`${branch.prefix}：${branch.address}`}
              </div>
            ))
            :
            (<div className={stl.address}>
              {item.address}
            </div>)
          }
          <div className={stl.entrance}>
            {config.sponsor.hasLocation && (
              <div className={cx(stl.wrap, stl.nav)} onClick={this.toLocation}>
                <img className={stl.icon} src={icnNav} />
              </div>
            )}
            <div className={cx(stl.wrap, stl.phone)} onClick={this.toPhone}>
              <img className={stl.icon} src={icnPhone} />
            </div>
            {!_.isEmpty(item.mpList) && (
              <div className={cx(stl.wrap, stl.mp)} onClick={this.toMp}>
                <img className={stl.icon} src={icnMp} />
              </div>
            )}
          </div>
        </div>)}
      </div>
    );
  }
}

interface IProps {
  item: {
    id: any;
    avatar: string;
    address: string;
    branchList: any[];
    contacts: { note: string; phone: string }[];
    name: string;
    distance: string;
    grade: string;
    summaryList: any[];
    serviceTag: any;
    mpList: any[];
    tags: string[];
    geo: {
      lon: number;
      lat: number;
    };
    priorInfo: string;
  };
  isExpand: boolean;
  isSuggested: boolean;

  onClick(item): void;
}
