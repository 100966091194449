import * as React from 'react';
import icon from '@assets/icon/icn-checked@2x.png';
import cx from 'classnames';
import stl from './style.less';

export default function IcnCheck({ className }: IProps) {
  return (
    <img className={cx(stl.icon, className)} src={icon} alt={'打勾'} />
  );
}

interface IProps {
  className?: string;
}
