// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_NCLjO {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.mina_p53u4 {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.mina_p53u4:active {
  opacity: 0.6;
}
.logo__BDgl {
  flex: none;
  width: 45px;
  height: 45px;
}
.text_q12mb {
  font-size: 12px;
  color: #26334D;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CovidCard/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;AAHF;AAMA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AAJF;AAME;EACE,YAAA;AAJJ;AAQA;EACE,UAAA;EACA,WAAA;EACA,YAAA;AANF;AASA;EACE,eAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AAPF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.block {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 20px;\n}\n\n.mina {\n  flex-basis: 25%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n  cursor: pointer;\n\n  &:active {\n    opacity: 0.6;\n  }\n}\n\n.logo {\n  flex: none;\n  width: 45px;\n  height: 45px;\n}\n\n.text {\n  font-size: 12px;\n  color: #26334D;\n  text-align: center;\n  margin-top: 12px;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `block_NCLjO`,
	"mina": `mina_p53u4`,
	"logo": `logo__BDgl`,
	"text": `text_q12mb`
};
export default ___CSS_LOADER_EXPORT___;
