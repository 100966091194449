import * as React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import ActivityViews from '@components/ActivityViews';
import { event, pageview } from '@libs/ga';
import stl from './style.less';
import { Input } from 'antd';

const { TextArea } = Input;

const placeholder =
  `希望您能详细描述认为的问题，例如：\n` +
  `数据内容有误（具体的错误）等`;
export default class FeedbackViews extends React.PureComponent<any, any> {
  static show() {
    const elm = document.createElement('div');
    elm.id = `feedback`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<FeedbackViews />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_feedback`);
    this.state = {
      visible: true,
      comment: '',
    };
  }

  close = () => {
    const elm = document.getElementById(`feedback`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        try {
          root.unmount();
          document.body.removeChild(elm);
        } catch (error) {
          console.error(error);
        }
      }, 200);
    }
  };

  change = evt => {
    const { value } = evt.currentTarget;
    this.setState({
      comment: value,
    });
  };

  submit = () => {
    const { comment } = this.state;
    event({
      category: 'feedback-wx-views_submit',
      action: 'click',
      label: comment,
    });
    global['app']._store.dispatch({
      type: 'feedback/submit',
      payload: {
        comment: comment,
      },
    });
    setTimeout(() => {
      this.close();
    }, 800);
  };

  render() {
    const { visible } = this.state;
    return (
      <ActivityViews visible={visible} onClose={this.close}>
        <>
          <div className={stl.title}>结果有问题，我要反馈</div>
          <div className={stl.textareaWrapper}>
            <TextArea rows={4} className={stl.textarea} placeholder={placeholder} onChange={this.change} maxLength={400} bordered={false}/>
          </div>
          <div className={stl.submit} onClick={this.submit}>
            提交
          </div>
        </>
      </ActivityViews>
    );
  }
}
