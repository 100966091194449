import * as React from 'react';
import { ENTER_PRESS_CODE } from '@constants/index';
import IcnDelete from '@components/icons/IcnDelete';
import IcnSearch from '@components/icons/IcnSearch';
import cx from 'classnames';
import { withDefault } from '@utils/index';
import SuggestList from '@components/SuggestList';
import { connect } from 'dva';
import history from '@utils/history';
import * as _ from 'lodash-es';
import { event } from '@libs/ga';
import stl from './style.less';

export class SearchBar extends React.PureComponent<any, any> {
  anchor;
  input;
  backup;

  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.anchor = React.createRef();
    this.state = {
      visible: false,
      isFocus: false,
      hasCover: false,
    };
  }

  debounceSuggest = _.debounce(value => {
    this.props.dispatch({
      type: 'search/suggest',
      payload: {
        value,
      },
    });
  }, 300);

  change = (evt) => {
    const { value } = evt.currentTarget;
    this.debounceSuggest(value);
    this.props.dispatch({
      type: 'search/setQuery',
      payload: {
        value,
      },
    });
    this.setState({
      visible: !!value,
    });
  };

  delete = () => {
    event({
      category: 'search-bar_delete',
      action: 'click',
      label: '',
    });
    this.props.dispatch({
      type: 'search/setQuery',
      payload: {
        value: '',
      },
    });
    this.props.dispatch({
      type: 'search/setSuggestList',
      payload: {
        list: [],
      },
    });
    this.input.current.focus();
  };

  pressKey = (evt) => {
    if (evt.keyCode === ENTER_PRESS_CODE) {
      evt.currentTarget.blur();
      this.onSearch();
    }
  };

  focus = () => {
    const { query } = this.props;
    this.backup = withDefault(this.backup, query);
    this.setState({
      visible: !!query,
      isFocus: true,
      hasCover: true,
    });
    setTimeout(() => {
      this.anchor.current?.scrollIntoView();
    }, 100);
  };

  blur = () => {
    this.setState({
      hasCover: false,
    });
    setTimeout(() => {
      this.anchor.current?.scrollIntoView(false);
      this.setState({
        isFocus: false,
      });
    }, 50);
  };

  onSearch = (evt?) => {
    const value = _.get(evt, 'currentTarget.dataset.value');
    event({
      category: 'search-bar_onsearch',
      action: 'click',
      label: value,
    });
    this.debounceSuggest.cancel();
    this.setState({
      visible: false,
      isFocus: false,
    });
    const { query, dispatch } = this.props;
    if (_.isEmpty(value) && _.isEmpty(query)) {
      return;
    }
    history.push('/search');
    dispatch({
      type: 'search/search',
      payload: {
        query: withDefault(value, query),
      },
    });
  };

  cancel = () => {
    event({
      category: 'search-bar_cancel',
      action: 'click',
      label: '',
    });
    this.props.dispatch({
      type: 'search/setQuery',
      payload: {
        value: this.backup,
      },
    });
    this.props.dispatch({
      type: 'search/setSuggestList',
      payload: {
        list: [],
      },
    });
    this.setState({
      visible: false,
      isFocus: false,
    });
  };

  render() {
    const { query, className } = this.props;
    const { visible, isFocus, hasCover } = this.state;
    return (
      <div className={cx(stl.block, className)}>
        {hasCover && (
          <div className={stl.cover} />
        )}
        <div ref={this.anchor} style={{ height: '20px' }} />
        <div className={stl.wrap}>
          <div className={stl.searchBar}>
            <form className={stl.form} action="" method="post" target="nm_iframe">
              <input className={stl.input} placeholder={'描述你的问题'}
                     onChange={this.change} value={query}
                     onKeyDown={this.pressKey} ref={this.input}
                     type='search' onFocus={this.focus} onBlur={this.blur} />
            </form>
            <iframe name="nm_iframe" style={{ display: 'none' }} />
            {visible ? (
              <div className={stl.icon} onClick={this.delete}>
                <IcnDelete />
              </div>
            ) : (
              <div className={stl.icon} onClick={this.onSearch}>
                <IcnSearch />
              </div>
            )}
          </div>
          {isFocus && !!this.backup && (
            <div className={stl.cancel} onClick={this.cancel}>取消</div>
          )}
        </div>
        <div className={stl.list}>
          {isFocus && (
            <SuggestList onClick={this.onSearch} />
          )}
        </div>
      </div>
    );
  }
}

export default connect(({ search }) => ({
  query: search.query,
}))(SearchBar);
