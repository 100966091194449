// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag_pxPBp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 228px;
  background-color: #FAEFE6;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-left: 15px;
}
.warning_CxHhN {
  flex: none;
  margin-right: 5px;
}
.text_VnoIv {
  font-size: 14px;
  color: #AC3C0C;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChineseMedicineHints/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;AAHF;AAMA;EACE,UAAA;EACA,iBAAA;AAJF;AAOA;EACE,eAAA;EACA,cAAA;AALF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.tag {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 36px;\n  width: 228px;\n  background-color: #FAEFE6;\n  border-radius: 4px;\n  margin-bottom: 20px;\n  margin-left: 15px;\n}\n\n.warning {\n  flex: none;\n  margin-right: 5px;\n}\n\n.text {\n  font-size: 14px;\n  color: #AC3C0C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `tag_pxPBp`,
	"warning": `warning_CxHhN`,
	"text": `text_VnoIv`
};
export default ___CSS_LOADER_EXPORT___;
