import * as React from 'react';
import stl from './style.less';
import { withSign } from '@mina-modules/sign';
import rq from '@utils/request';
import config from '@config';

export default class Logo extends React.PureComponent<any, any> {
  submit = async () => {
    const url = await getUrlScheme();
    window.location.href = url;
  };
  render() {
    return (
      <>
      <div className={stl.body}>
        <div className={stl.title}>
          就诊问问
        </div>
        <div className={stl.desc}>
          医疗搜索引擎。专注医疗健康问题，整合线上线下资源，通过智能分析、匹配和甄选，为用户找到精准、有效、优质的答案。
        </div>
        <div className={stl.btn} onClick={this.submit}>
           <img className={stl.icon} src='https://medical-cdn.nocode.com/medical/ask/upload/da5c6975476c1c1cbe6c1b89d7c89bb8.png' />
          打开小程序
        </div>
        <img className={stl.phone} src='https://medical-cdn.nocode.com/medical/ask/upload/00ea1d76fcf7e8eb7e07e4e39d137d46.png' />
      </div>
      </>
    );
  }
}

async function getUrlScheme() {
  try {
    const url = `${config.HOST_JAVA}/mina/url_scheme`;
    const resp = await rq(withSign({
      url,
    }));
    return resp.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}
