// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon_DBCHp {
  width: 12px;
  height: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/icons/IcnType/style.less"],"names":[],"mappings":"AAIA;EACE,WAAA;EACA,YAAA;AAHF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.icon {\n  width: 12px;\n  height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `icon_DBCHp`
};
export default ___CSS_LOADER_EXPORT___;
