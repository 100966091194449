// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_iQ5hX {
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin: 20px 0;
}
.desc_UhEvZ {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  margin-top: 60px;
}
.phone_M79gD {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  width: 170px;
  height: 44px;
  background-color: #4992E6;
  border-radius: 8px;
  margin: 30px auto;
}
.quote_Zpnp3 {
  font-size: 16px;
  color: #AFB8C3;
  text-align: center;
  margin: 60px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/SuicideCard/style.less"],"names":[],"mappings":"AAIA;EACE,sBAAA;EACA,yCAAA;EACA,4CAAA;EACA,cAAA;AAHF;AAMA;EACE,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AAJF;AAOA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;AALF;AAQA;EACE,eAAA;EACA,cAAA;EACA,kBAAA;EACA,cAAA;AANF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.card {\n  background-color: #fff;\n  border-top: 1px solid @black06;\n  border-bottom: 1px solid @black06;\n  margin: 20px 0;\n}\n\n.desc {\n  font-size: 16px;\n  color: @black90;\n  text-align: center;\n  margin-top: 60px;\n}\n\n.phone {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 15px;\n  font-weight: bold;\n  color: #fff;\n  width: 170px;\n  height: 44px;\n  background-color: #4992E6;\n  border-radius: 8px;\n  margin: 30px auto;\n}\n\n.quote {\n  font-size: 16px;\n  color: #AFB8C3;\n  text-align: center;\n  margin: 60px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `card_iQ5hX`,
	"desc": `desc_UhEvZ`,
	"phone": `phone_M79gD`,
	"quote": `quote_Zpnp3`
};
export default ___CSS_LOADER_EXPORT___;
