import defaultConfig from './default';
import development from './development';
import production from './production';

let config;
switch (process.env.NODE_ENV) {
  case 'development':
    config = development;
    break;
  case 'production':
    config = production;
    break;
  default:
    config = development;
    break;
}

export default {
  ...defaultConfig,
  ...config,
};
