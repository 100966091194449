// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorCode_WqME5 {
  margin-top: 100px;
  text-align: center;
  font-size: 300px;
  color: #999;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/style.module.less"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;AADF","sourcesContent":["@import (reference)'~@styles/vars';\n\n.errorCode {\n  margin-top: 100px;\n  text-align: center;\n  font-size: 300px;\n  color: @gray-9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorCode": `errorCode_WqME5`
};
export default ___CSS_LOADER_EXPORT___;
