import * as React from 'react';
import * as _ from 'lodash-es';
import { event } from '@libs/ga';
import RestItem from '@components/RestItem';
import DrugstoreItem from '@components/DrugstoreItem';
import EasyItem from '@components/EasyItem';
import { navigateByType, os } from '@utils/browser';
import { connect } from 'dva';
import IcnAnswerText from '@components/icons/IcnAnswerText';
import FeedbackViews from '@components/mobile/FeedbackViews';
import cx from 'classnames';
import ShadowLine from '@components/ShadowLine';
import stl from './style.less';
import config from '@config';

export class MainCard extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    event({
      category: 'main-card_init',
      action: 'click',
      label: `${_.map(props.list, i => i.id)}_faq`,
    });
    this.state = {
      isCollapse: true,
    };
  }

  toView = item => {
    const index = _.findIndex(this.props.list, i => i.id === item.id);
    event({
      category: 'main-card_toview',
      action: 'click',
      label: `${index}_${item.id}_faq`,
    });

    // TODO: 是否需要第三个参数？
    return navigateByType(item, this.props.dispatch, 'search/fetchFaq');
  };

  toDrugstore = item => {
    event({
      category: 'main-card_drugstore',
      action: 'click',
      label: `${item.id}_${item.name}`,
    });
    this.props.dispatch({
      type: 'hospital/fetchHospital',
      payload: {
        id: item.id,
      },
    });
  };

  expand = () => {
    event({
      category: 'main-card_expand',
      action: 'click',
    });
    this.setState({
      isCollapse: false,
    });
  };

  showFeedback = () => {
    event({
      category: 'maincard_showfeedback',
      action: 'click',
      label: '',
    });
    FeedbackViews.show();
  };

  render() {
    const { expandable, list, type } = this.props;
    const { isCollapse } = this.state;
    return (
      <div className={cx(stl.card, { [stl.pc]: os.isPc })}>
        <div className={stl.context} style={{ ['--bgc' as any]: config.sponsor.themeBG }}>
          <IcnAnswerText />
          {os.isMobile && (
            <div className={stl.feedback} onClick={this.showFeedback}>问题反馈</div>
          )}
        </div>
        {_.map(list, (item, index) => (expandable || !isCollapse || index < 6) && (
          <div className={stl.item} key={item.id}>
            {type === 'faqOpinion' && (
              <EasyItem item={item} onClick={this.toView} />
            )}
            {type === 'drugstore' && (
              <DrugstoreItem item={item} onClick={this.toDrugstore} />
            )}
          </div>
        ))}
        {!expandable && isCollapse && list.length > 6 && (
          <div className={stl.item}>
            <RestItem count={list.length - 6} onClick={this.expand} />
          </div>
        )}
        {os.isMobile && (
          <ShadowLine />
        )}
      </div>
    );
  }
}

export default connect()(MainCard);
