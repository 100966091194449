// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_Ldq2B {
  display: flex;
  align-items: center;
  height: 52px;
  border-bottom: 1px solid rgba(214, 218, 242, 0.35);
  margin-left: 20px;
}
.label_AfieA {
  flex: none;
  font-size: 16px;
  line-height: 1.53;
  color: #26334D;
  margin-right: 5px;
}
.value_cAN8b {
  font-size: 16px;
  line-height: 1.8;
  color: #26334D;
  margin: 12px 20px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Formula/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kDAAA;EACA,iBAAA;AAHF;AAMA;EACE,UAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AAJF;AAOA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;AALF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.header {\n  display: flex;\n  align-items: center;\n  height: 52px;\n  border-bottom: 1px solid rgba(214, 218, 242, 0.35);\n  margin-left: 20px;\n}\n\n.label {\n  flex: none;\n  font-size: 16px;\n  line-height: 1.53;\n  color: #26334D;\n  margin-right: 5px;\n}\n\n.value {\n  font-size: 16px;\n  line-height: 1.8;\n  color: #26334D;\n  margin: 12px 20px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_Ldq2B`,
	"label": `label_AfieA`,
	"value": `value_cAN8b`
};
export default ___CSS_LOADER_EXPORT___;
