import * as React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import ActivityViews from '@components/ActivityViews';
import { getMinaCodeBy } from '@utils/logo';
import { pageview } from '@libs/ga';
import stl from './style.less';

export default class MinaCodeViews extends React.PureComponent<any, any> {
  static show(source) {
    const elm = document.createElement('div');
    elm.id = `minacode`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<MinaCodeViews source={source} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_mina_code`);
    this.state = {
      visible: true,
    };
  }

  get src() {
    const { source } = this.props;
    return getMinaCodeBy(source);
  }

  close = () => {
    const elm = document.getElementById(`minacode`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { visible } = this.state;
    return (
      <ActivityViews visible={visible} onClose={this.close}>
        <>
          <div className={stl.title}>保存图片到手机，使用微信扫码后体验</div>
          <img className={stl.qrcode} src={this.src} alt="小程序码" />
        </>
      </ActivityViews>
    );
  }
}
