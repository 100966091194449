import { error } from '@libs/log';
import { feedback } from '@services/feedback';
import { result } from '@services/search';

export default {
  namespace: 'feedback',
  state: {},
  effects: {
    * submit({ payload: { comment } }, { select, call }) {
      try {
        const { query } = yield select(state => state.search);
        yield call(feedback, query, result, comment);
      } catch (e) {
        error(e);
        throw e;
      }
    },
  },
  reducers: {},
};
