import _ from 'lodash';
import * as React from 'react';
import stl from './style.less';

export default class SpecialMedication extends React.PureComponent<IProps, any> {
  render() {
    const { item } = this.props;
    if (typeof item === 'string') return (
      <div className={stl.text}>{item}</div>
    );
    if (_.isEmpty(item)) return null;
    return (
      <>
        {item.child_use && (
          <div className={stl.row}>
            <div className={stl.label}>儿童用药</div>
            <div className={stl.value}>{item.child_use}</div>
          </div>
        )}
        {item.old_use && (
          <div className={stl.row}>
            <div className={stl.label}>老年用药</div>
            <div className={stl.value}>{item.old_use}</div>
          </div>
        )}
        {item.pregnancy_use && (
          <div className={stl.row}>
            <div className={stl.label}>孕产用药</div>
            <div className={stl.value}>{item.pregnancy_use}</div>
          </div>
        )}
      </>
    );
  }
}

interface IProps {
  item: string | { child_use: string; old_use: string; pregnancy_use: string };
}

