import * as React from 'react';
import cx from 'classnames';
import IcnWarning from '@components/icons/IcnWarning';
import stl from './style.less';

export default class ChineseMedicineHints extends React.PureComponent<IProps, any> {
  render() {
    const { className } = this.props;
    return (
      <div className={cx(stl.tag, className)}>
        <div className={stl.warning}>
          <IcnWarning />
        </div>
        <div className={stl.text}>此药品为中成药，请谨慎使用</div>
      </div>
    );
  }
}

interface IProps {
  className?: string;
}
