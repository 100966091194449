import * as React from 'react';
import IcnNotNow from '@components/icons/IcnNotNow';
import stl from './style.less';

export default class UnknownResult extends React.PureComponent<unknown, any> {
  render() {
    return (
      <>
        <div className={stl.icon}>
          <IcnNotNow />
        </div>
        <div className={stl.tips}>我现在还不能直接回答此问题</div>
        <div className={stl.tips}>再给我点时间成长下</div>
      </>
    );
  }
}
