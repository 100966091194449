// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_F0WAo {
  height: 100vh;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: env(safe-area-inset-bottom, 20px);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Faq/mobile/style.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,iDAAA;AACF","sourcesContent":[".page {\n  height: 100vh;\n  box-sizing: border-box;\n  padding-top: 8px;\n  padding-bottom: env(safe-area-inset-bottom, 20px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `page_F0WAo`
};
export default ___CSS_LOADER_EXPORT___;
