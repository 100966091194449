// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row_uKv4y {
  display: flex;
  align-items: baseline;
  margin: 18px 20px 0;
}
.row_uKv4y.limit_kmgjR {
  margin-top: 10px;
}
.label_CdZca {
  flex: none;
  font-size: 16px;
  line-height: 1.53;
  color: #636B7A;
  margin-right: 12px;
}
.value_qlDB3 {
  font-size: 16px;
  line-height: 1.53;
  color: #090F1B;
}
.comments_Qqwon {
  font-size: 16px;
  line-height: 1.07;
  color: #636B7A;
  margin: 18px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Insurance/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AAHF;AAKE;EACE,gBAAA;AAHJ;AAOA;EACE,UAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AALF;AAQA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AANF;AASA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AAPF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.row {\n  display: flex;\n  align-items: baseline;\n  margin: 18px 20px 0;\n\n  &.limit {\n    margin-top: 10px;\n  }\n}\n\n.label {\n  flex: none;\n  font-size: 16px;\n  line-height: 1.53;\n  color: #636B7A;\n  margin-right: 12px;\n}\n\n.value {\n  font-size: 16px;\n  line-height: 1.53;\n  color: #090F1B;\n}\n\n.comments {\n  font-size: 16px;\n  line-height: 1.07;\n  color: #636B7A;\n  margin: 18px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `row_uKv4y`,
	"limit": `limit_kmgjR`,
	"label": `label_CdZca`,
	"value": `value_qlDB3`,
	"comments": `comments_Qqwon`
};
export default ___CSS_LOADER_EXPORT___;
