import * as React from 'react';
import * as _ from 'lodash-es';
import { withDefault } from '@utils/index';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import IcnAsk from '@components/icons/IcnAsk';
import stl from './style.less';

export default class QaItem extends React.PureComponent<IProps, any> {
  click = () => {
    const { item, onClick } = this.props;
    onClick(item);
  };

  render() {
    const { item } = this.props;
    return (
      <div onClick={this.click}>
        <div className={stl.question}>
          <div className={stl.icon}>
            <IcnAsk />
          </div>
          <div className={stl.name}>{item.title}</div>
        </div>
        <div className={stl.summary}>{item.summary}</div>
        <div className={stl.comments}>
          <div className={stl.logo}>{item.logo}</div>
          <div className={stl.reference}>
            {withDefault(_.compact([item.doctor.name, item.doctor.hospital]).join(' · '), item.source)}
          </div>
          <div className={stl.arrow}>
            <IcnArrowRight />
          </div>
        </div>
      </div>
    );
  }
}

interface IProps {
  item: {
    title: string;
    summary: string;
    logo: React.ReactElement;
    source: string;
    doctor: any;
  };

  onClick(item): void;
}
