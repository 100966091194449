// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_QwZ0E {
  padding: 30px 45px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Disease/pc/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF","sourcesContent":[".page {\n  padding: 30px 45px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `page_QwZ0E`
};
export default ___CSS_LOADER_EXPORT___;
