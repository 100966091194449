import React, { useState } from 'react';
import cx from 'classnames';
import stl from './style.less';
import { dotMix } from '@utils/format';
import PredictProgress from '@components/PredictProgress';
import {HighlightStyles} from '@services/search';
import IconMore from '@assets/icon/icn-arrow-down@2x.png';

const getHighlightClass = (style: number) => {
  return {
    [HighlightStyles.COLOR]: 'strong',
  }[style];
};

// 交互设计要求，点击非按钮外的元素，预期是关闭移动端展示的遮罩弹窗
// 因此需要阻止按钮的点击事件冒泡
const stopBubling = (e: React.MouseEvent) => {
  e.stopPropagation();
};

const PredictCard = React.forwardRef<HTMLDivElement, IProps>(({ item, dispatch }, ref) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(true);

  const displayDrugBaike = (item: IItem) => {
    dispatch({
      type:'search/fetchDrug',
      payload: {
        item,
      },
    });
  };

  const displayExamineBaike = (item: IItem) => {
    dispatch({
      type:'search/fetchExamine',
      payload: {
        item,
      },
    });
  };

  const displayDiseaseBaike = (item: IItem) => {
    dispatch({
      type:'search/fetchDisease',
      payload: {
        item,
      },
    });
  };

  const displayBaike = (e: React.MouseEvent, item: IItem) => {
    stopBubling(e);
    const typeMap = {
      drug_generic: 'drug',
      disease: 'disease',
      examine: 'examine',
    };
    switch(typeMap[item.type]){
      case 'drug': 
        displayDrugBaike(item);
        break;
      case 'disease': 
        displayDiseaseBaike(item);
        break;
      case 'examine': 
        displayExamineBaike(item);
        break;
    }
  };


  const displayFaq = (e: React.MouseEvent, faq: {id: string}) => {
    stopBubling(e);
    dispatch({
      type:'search/fetchFaq',
      payload: {
        id: faq.id,
      },
    });
  };

  const showMore = (e: React.MouseEvent) => {
    stopBubling(e); 
    setIsCollapse(false);
  };

  return (
    <div className={stl.card} ref={ref}>
      <div className={stl.header}>
        <div className={stl.header__left}>
          <div className={cx(stl.header__title, stl.oneLineDisplay)}>
            {item.name}
          </div>
          <div className={stl.header__desc}>预测值 {item.score}%</div>
          <PredictProgress percentage={item.score} style={{ width: '90px' }} />
        </div>
        <div className={stl.header__right}>
          {item.type === 'drug_generic' || item.type === 'disease' || item.type === 'examine' ? (
            <div
              className={cx(stl.header__btn, stl.clickable)}
              onClick={(e) => displayBaike(e, item)}>
              详情
            </div>
          ) : null}
        </div>
      </div>
      {item.faqs?.map((faq, faqIdx) => (
        <div
          className={stl.doctor}
          hidden={item.faqs.length > 3 && isCollapse && faqIdx > 1}
          key={faq.id}>
          <img className={stl.doctor__avatar} src={faq.doctor?.avatar} onClick={stopBubling}/>
          <div className={stl.doctor__right}>
            <div
              className={cx(stl.tooltip, stl.corner, stl.clickable)}
              onClick={(e) => displayFaq(e, faq)}>
              <div className={stl.doctor__header}>
                <div className={stl.doctor__name}>{faq.doctor?.name}</div>
                <div className={stl.doctor__hospital}>
                  {dotMix(faq.doctor?.hospitalName, faq.doctor?.departmentName)}
                </div>
              </div>
              <div className={stl.doctor__content}>
                <div className={stl.summary}>
                  {faq.renderList?.map((chip) => (
                    <span key={chip.text} className={stl[getHighlightClass(chip.style)]}>
                      {chip.text}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            {item.faqs?.length > 3 && isCollapse && faqIdx === 1 ? (
              <div
                className={cx(stl.tooltip, stl.more, stl.clickable)}
                onClick={showMore}>
                余下 {item.faqs.length - 2} 项
                <img
                  className={stl.more__img}
                  src={IconMore}
                />
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
});

interface IProps {
  item: IItem;
  dispatch: any;
}

interface IItem {
  id: string;
  highlight?: string;
  name: string;
  type: string;
  score: number;
  faqs: any[];
}

export default PredictCard;
