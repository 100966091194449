// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_pOcpj {
  font-size: 22px;
  font-weight: bold;
  color: #26334D;
  margin: 0 15px 25px;
}
.title_pOcpj.withHints_P0hFR {
  margin-bottom: 10px;
}
.empty_przPa {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}
.icon_ZICvr {
  display: block;
  width: 80px;
  height: 80px;
  margin: auto;
}
.text_Hkd_c {
  font-size: 17px;
  color: #636B7A;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/BaikeContent/style.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAGA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,iCAAA;AADF;AAIA;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AAFF;AAKA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAHF","sourcesContent":[".title {\n  font-size: 22px;\n  font-weight: bold;\n  color: #26334D;\n  margin: 0 15px 25px;\n}\n\n.title.withHints {\n  margin-bottom: 10px;\n}\n\n.empty {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -100%);\n}\n\n.icon {\n  display: block;\n  width: 80px;\n  height: 80px;\n  margin: auto;\n}\n\n.text {\n  font-size: 17px;\n  color: #636B7A;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `title_pOcpj`,
	"withHints": `withHints_P0hFR`,
	"empty": `empty_przPa`,
	"icon": `icon_ZICvr`,
	"text": `text_Hkd_c`
};
export default ___CSS_LOADER_EXPORT___;
