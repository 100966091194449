import * as React from 'react';
import * as _ from 'lodash-es';
import FaqItem from '@components/FaqItem';
import { event } from '@libs/ga';
import { navigateByType } from '@utils/browser';
import RelatedFaq from '@components/RelatedFaq';
import cx from 'classnames';
import { connect } from 'dva';
import stl from './style.less';

const insertPosition = 3;

export class HybridCard extends React.PureComponent<any, any> {
  toFaq = item => {
    event({
      category: 'hybrid-card_faq',
      action: 'click',
      label: `${item.id}_${item.title}_${item.source}`,
    });
    navigateByType(item, this.props.dispatch, 'search/fetchFaq');
  };

  loadMore = () => {
    event({
      category: 'hybrid-card_loadmore',
      action: 'click',
      label: '',
    });
    this.props.dispatch({
      type: 'search/fetchHybridIntent',
    });
  };

  render() {
    const { list, hasMore } = this.props;
    return !_.isEmpty(list) && (
      <>
        <div className={stl.header}>全部搜索结果</div>
        {_.map(list, (item, index) => (
          <div key={item.id}>
            <div className={stl.item}>
              {item.type === 'faq' && (
                <FaqItem item={item} onClick={this.toFaq} />
              )}
            </div>
            {index === insertPosition && (
              <div className={cx(stl.item, stl.inactive)}>
                <RelatedFaq />
                <div style={{ height: '25px' }} />
              </div>
            )}
          </div>
        ))}
        {hasMore && (
          <div className={stl.item} onClick={this.loadMore}>
            <div className={stl.more}>加载更多</div>
          </div>
        )}
      </>
    );
  }
}

export default connect(({ search }) => ({
  hasMore: !!search.hybridIntent.url,
  list: search.hybridIntent.list,
}))(HybridCard);
