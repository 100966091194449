import React from 'react';
import _ from 'lodash-es';
import cx from 'classnames';
import ChineseMedicineHints from '@components/ChineseMedicineHints';
import SidebarViews from '@components/SidebarViews';
import { pageview } from '@libs/ga';
import { createRoot } from 'react-dom/client';
let root;
import stl from './style.less';
import { getSourceLogo } from '@utils/logo';
import DoctorItemMiniCard from '@components/DoctorItemMiniCard';
import Navigation from '@components/Navigation';
export default class QaViews extends React.PureComponent<IProps, any> {
  static show(item, type) {
    const elm = document.createElement('div');
    elm.id = `${type}_${item.id}`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<QaViews item={item} type={type} />);
  }

  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_faq`);
    this.state = {
      visible: !!props.item,
    };
  }

  get doctor() {
    const { item } = this.props;
    return _.get(item, `doctor`, {});
  }

  close = () => {
    const { item, type } = this.props;
    const elm = document.getElementById(`${type}_${item.id}`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  toDoctor = () => {
    global['app']._store.dispatch({
      type: 'doctor/fetchDoctor',
      payload: {
        id: this.doctor.id,
      },
    });
  };

  render() {
    const { item } = this.props;
    const { visible } = this.state;
    return (
      <>
        <SidebarViews visible={visible} onClose={this.close}>
          {!!item && (
            <div className={cx(stl.container)}>
              <div className={stl.scrollBox}>
                <div
                  className={cx(stl.title, {
                    [stl.withHints]: item.category === '中药',
                  })}>
                  {item.question}
                </div>
                {item.category === '中药' && <ChineseMedicineHints />}
                <div className={stl.date}>{item.date}</div>
                {item.description && (
                  <div className={stl.desc}>{item.description}</div>
                )}
                {this.doctor.name && (
                  <DoctorItemMiniCard
                    item={this.doctor}
                    onClick={this.toDoctor}
                  />
                )}
                {item.answer && <div className={stl.answer}>{item.answer}</div>}
                {item.flowList.map((flow, index) => {
                  return (
                    <div key={flow.id}>
                      {index < 10 &&
                        (flow.type === 'patient' &&
                        _.get(item, `flowList[${index} - 1].type`) !==
                          'patient' ? (
                          <div className={cx(stl.label, stl.patient)}>
                            用户追问
                          </div>
                        ) : (
                          <div className={cx(stl.label, stl.doc)}>医生回复</div>
                        ))}
                      <div
                        className={cx(
                          stl.value,
                          flow.type === 'patient' ? stl.patient : stl.doc
                        )}>
                        {flow.content}
                      </div>
                      {flow.type !==
                        _.get(item, `flowList[${index} + 1].type`) && (
                        <div className={stl.tail} />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={stl.viewMoreBox}>
                {item.url && (
                  <Navigation
                    name={item.source}
                    logo={getSourceLogo(item.logo)}
                    mina={item.mina}
                    url={item.url}
                  />
                )}
              </div>
            </div>
          )}
        </SidebarViews>
      </>
    );
  }
}

interface IProps {
  type: string;
  item: {
    id: string,
    question: string,
    category: string,
    date: string,
    url: string,
    mina: any,
    source: string,
    logo: any,
    description: string,
    answer: string,
    flowList: any[],
  };
}
