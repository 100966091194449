// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_vN0i5 {
  display: flow-root;
  top: 30%;
  color: #333;
  padding: 20px;
}
.message_tW9_I {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  max-width: 80vw;
  min-height: 100px;
}
.close_KrIaG {
  position: relative;
  float: right;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  padding: 4px 8px;
}
.close_KrIaG:active {
  background-color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/components/AlertViews/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,aAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;AAAF;AAGA;EACE,kBAAA;EACA,YAAA;EACA,eAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;AADF;AAIA;EACE,yBAAA;AAFF","sourcesContent":[".popup{\n  display: flow-root;\n  top: 30%;\n  color: #333;\n  padding: 20px;\n}\n\n.message{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 300px;\n  max-width: 80vw;\n  min-height: 100px;\n}\n\n.close {\n  position: relative;\n  float: right;\n  cursor: pointer;\n  border: 1px solid rgba(0,0,0,0.6);\n  border-radius: 4px;\n  padding: 4px 8px;\n}\n\n.close:active{\n  background-color: #f5f5f5;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `popup_vN0i5`,
	"message": `message_tW9_I`,
	"close": `close_KrIaG`
};
export default ___CSS_LOADER_EXPORT___;
