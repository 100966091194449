import { error } from '@libs/log';
import { fetchBaikeId } from '@services/baike';
import { fetchDisease, fetchDrug } from '@services/search';

export default {
  namespace: 'baike',
  state: {
    item: {},
  },
  effects: {
    * search({ payload }, { call, put }) {
      try {
        const { id, type } = yield call(fetchBaikeId, payload.query);
        let res = {};
        switch (type) {
          case 'drug_encyclopedia':
            res = yield call(fetchDrug, { id });
            break;
          case 'disease_encyclopedia':
            res = yield call(fetchDisease, id);
            break;
        }
        yield put({
          type: 'update',
          payload: res,
        });
      } catch (e) {
        error(e);
        throw e;
      }
    },
  },
  reducers: {
    update(state, { payload }) {
      return {
        ...state,
        item: payload,
      };
    },
  },
};
