// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_tsbn3 {
  display: flow-root;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom, 20px);
  background-color: #DBF0FF;
  height: 100vh;
  overflow-y: auto;
}
.card_z_VR4 {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  padding: 0 18px;
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/DiseaseTopic/mobile/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,sBAAA;EACA,iDAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;AACF;AAEA;EACE,4CAAA;EACA,qCAAA;EACA,kBAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AAAF","sourcesContent":[".page {\n  display: flow-root;\n  box-sizing: border-box;\n  padding-bottom: env(safe-area-inset-bottom, 20px);\n  background-color: #DBF0FF;\n  height: 100vh;\n  overflow-y: auto;\n}\n\n.card {\n  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.02);\n  border: 1px solid rgba(0, 0, 0, 0.05); \n  border-radius: 8px;\n  background-color: #fff;\n  overflow: hidden;\n  padding: 0 18px;\n  margin: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `page_tsbn3`,
	"card": `card_z_VR4`
};
export default ___CSS_LOADER_EXPORT___;
