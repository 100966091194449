// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_z8zCE {
  font-family: 'PingFang SC';
  width: 100%;
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 50px;
}
.logo_tr939 {
  flex: 1;
  align-self: center;
  justify-self: center;
  box-sizing: border-box;
  margin: 24px 18px 0;
  border: solid 1px #f3f3f3;
  background-color: #fff;
}
.logo_tr939 .img_P3vjT {
  width: 100%;
  height: 100%;
}
.logo_tr939 .tip_ZEIoA {
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  color: #1b1b1b;
  margin-top: 10px;
  margin-bottom: 35px;
}
.name_K02F5 {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: #1b1b1b;
  margin: 30px 18px 20px;
}
.desc_TmSP2 {
  font-size: 17px;
  line-height: 1.76;
  letter-spacing: 0.5px;
  color: #1b1b1b;
  margin: 0 18px;
}
.btn_udqXa {
  width: fit-content;
  margin: 0 auto;
  margin-top: 60px;
  font-size: 15px;
  text-align: center;
  color: #808080;
  line-height: 1;
  letter-spacing: 0.5px;
}
.btn_udqXa span {
  text-decoration: underline;
  text-underline-offset: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Hospital/style.module.less"],"names":[],"mappings":"AAEA;EACE,0BAAA;EACA,WAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,oBAAA;AADF;AAIA;EACE,OAAA;EACA,kBAAA;EACA,oBAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;EACA,sBAAA;AAFF;AALA;EAWI,WAAA;EACA,YAAA;AAHJ;AATA;EAgBI,eAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AAJJ;AAQA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;EACA,sBAAA;AANF;AASA;EACE,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;EACA,cAAA;AAPF;AAUA;EACE,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;EACA,cAAA;EACA,qBAAA;AARF;AAAA;EAWI,0BAAA;EACA,0BAAA;AARJ","sourcesContent":["@import (reference)'~@styles/vars';\n\n.body {\n  font-family: 'PingFang SC';\n  width: 100%;\n  box-sizing: border-box;\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  padding-bottom: 50px;\n}\n\n.logo {\n  flex: 1;\n  align-self: center;\n  justify-self: center;\n  box-sizing: border-box;\n  margin: 24px 18px 0;\n  border: solid 1px #f3f3f3;\n  background-color: #fff;\n\n\n  .img {\n    width: 100%;\n    height: 100%;\n  }\n\n  .tip {\n    font-size: 15px;\n    line-height: 1;\n    letter-spacing: 0.5px;\n    text-align: center;\n    color: #1b1b1b;\n    margin-top: 10px;\n    margin-bottom: 35px;\n  }\n}\n\n.name {\n  font-size: 22px;\n  font-weight: bold;\n  line-height: 1.15;\n  letter-spacing: 0.5px;\n  color: #1b1b1b;\n  margin: 30px 18px 20px;\n}\n\n.desc {\n  font-size: 17px;\n  line-height: 1.76;\n  letter-spacing: 0.5px;\n  color: #1b1b1b;\n  margin: 0 18px;\n}\n\n.btn {\n  width: fit-content;\n  margin: 0 auto;\n  margin-top: 60px;\n  font-size: 15px;\n  text-align: center;\n  color: #808080;\n  line-height: 1;\n  letter-spacing: 0.5px;\n\n  span {\n    text-decoration: underline;\n    text-underline-offset: 2px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `body_z8zCE`,
	"logo": `logo_tr939`,
	"img": `img_P3vjT`,
	"tip": `tip_ZEIoA`,
	"name": `name_K02F5`,
	"desc": `desc_TmSP2`,
	"btn": `btn_udqXa`
};
export default ___CSS_LOADER_EXPORT___;
