import * as React from 'react';
import * as _ from 'lodash-es';
import { connect } from 'dva';
import { os } from '@utils/browser';
import ShadowLine from '@components/ShadowLine';
import CityPicker from '@components/CityPicker';
import md5 from 'blueimp-md5';
import cx from 'classnames';
import HospitalItem from '@components/HospitalItem';
import stl from './style.less';

const queryIntent = '医院查询';

export class HospitalCard extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    const state = props?.item?.params?.sortBy;
    this.state = {
      state,
    };
  }

  toHospital = item => {
    this.props.dispatch({
      type: 'hospital/fetchHospital',
      payload: {
        id: item.id,
      },
    });
  };

  search = params => {
    this.props.dispatch({
      type: 'search/fetchHospitalIntent',
      payload: params,
    });
  };

  loadMore = () => {
    this.search({
      page: this.props.item?.params?.page + 1,
    });
  };

  pickState = (evt) => {
    const { state } = evt.currentTarget.dataset;
    this.setState({
      state,
    });
    this.search({
      sortBy: state,
      page: 1,
    });
  };

  render() {
    const { item } = this.props;
    const { state } = this.state;
    return !_.isEmpty(item) && (
      <div className={cx(stl.card, { [stl.pc]: os.isPc })}>
        {(item.params.intent === queryIntent) ? (
          <div className={stl.context}>
            可前往以下医院
          </div>
        ) : (
          <div className={stl.filters}>
            <CityPicker key={md5(JSON.stringify(item))} onSearch={this.search}
                        province={_.get(item, 'params.province')}
                        city={_.get(item, 'params.city')} />
            <div className={stl.states}>
              <div className={cx(stl.state, { [stl.active]: state === 'score' })}
                   onClick={this.pickState} data-state="score">
                权威优先
              </div>
              <div className={stl.line} />
              <div className={cx(stl.state, { [stl.active]: state === 'distance' })}
                   onClick={this.pickState} data-state="distance">
                离我最近
              </div>
            </div>
          </div>
        )}
        {_.map(item.list, (hospital, index) => (
          <div className={cx(stl.item, { [stl.last]: index + 1 === item.list.length && _.isEmpty(item.url) })}
               key={hospital.id}>
            <HospitalItem item={hospital} isSuggested={item.params.intent === '医院推荐'}
                          isExpand={item.params.intent === '医院查询' && index === 0} onClick={this.toHospital} />
          </div>
        ))}
        {!_.isEmpty(item.url) && (
          <div className={cx(stl.item, stl.last)}>
            <div className={stl.more} onClick={this.loadMore}>加载更多</div>
          </div>
        )}
        {os.isMobile && (
          <ShadowLine />
        )}
      </div>
    );
  }
}

export default connect()(HospitalCard);
