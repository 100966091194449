import md5 from 'blueimp-md5';
import rq from '@utils/request';
import { error, info } from '@libs/log';

export async function fetchNocoBotQuery(query: string): Promise<any> {
  try {
    const token = 'UB4DEMpoYXqog3j5oCO8g5cwbaJKpWrM';
    const appid = 'dfWN4oEQahnea2KK';
    const timestamp = new Date().getTime();
    const md5str = md5(`${appid}${token}${timestamp}`);
    const sign = md5str.toLowerCase();
    const url = `https://medical.nocode.com/open/v2/nc.ms.smart.answer?query=${query}`;
    info(`get ${url}`);
    const resp = await rq({
        url,
        header: {
            appid,
            sign,
            timestamp,
        },
      });
    info(resp);
    return resp?.data?.content || '';
  } catch (e) {
    error(e);
    throw e;
  }
}