// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_vX3TQ {
  position: relative;
  z-index: 1;
}
.searchBar_mmTsc {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 10px;
  border-radius: 8px;
  border: 1px solid #EBECF0;
  overflow: hidden;
  box-sizing: border-box;
}
.input_tlhO9 {
  flex: auto;
  font-size: 15px;
  color: #26334D;
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  outline: none;
  background-color: inherit;
}
.input_tlhO9::placeholder {
  opacity: 0.66;
  line-height: normal;
}
.input_tlhO9::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.icon_VLIms {
  flex: none;
  padding: 12px;
  position: relative;
  cursor: pointer;
}
.icon_VLIms:active {
  background-color: #F5F5F5;
}
.list_qjmmW {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/pc/SearchBar/style.less"],"names":[],"mappings":"AAIA;EACE,kBAAA;EACA,UAAA;AAHF;AAMA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;EACA,sBAAA;AAJF;AAOA;EACE,UAAA;EACA,eAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;AALF;AAOE;EACE,aAAA;EACA,mBAAA;AALJ;AAQE;EACE,wBAAA;AANJ;AAUA;EACE,UAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;AARF;AAUE;EACE,yBAAA;AARJ;AAYA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AAVF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.block {\n  position: relative;\n  z-index: 1;\n}\n\n.searchBar {\n  display: flex;\n  align-items: center;\n  height: 40px;\n  padding-left: 10px;\n  border-radius: 8px;\n  border: 1px solid #EBECF0;\n  overflow: hidden;\n  box-sizing: border-box;\n}\n\n.input {\n  flex: auto;\n  font-size: 15px;\n  color: #26334D;\n  width: 100%;\n  height: 100%;\n  padding: 0;\n  border: none;\n  outline: none;\n  background-color: inherit;\n\n  &::placeholder {\n    opacity: 0.66;\n    line-height: normal;\n  }\n\n  &::-webkit-search-cancel-button {\n    -webkit-appearance: none;\n  }\n}\n\n.icon {\n  flex: none;\n  padding: 12px;\n  position: relative;\n  cursor: pointer;\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n}\n\n.list {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `block_vX3TQ`,
	"searchBar": `searchBar_mmTsc`,
	"input": `input_tlhO9`,
	"icon": `icon_VLIms`,
	"list": `list_qjmmW`
};
export default ___CSS_LOADER_EXPORT___;
