import rq from '@utils/request';
import { withSign } from '@mina-modules/sign';
import config from '@config';
import { event } from '@libs/ga';
import { error, info } from '@libs/log';
import { qsStringify } from '@utils/index';
import * as _ from 'lodash-es';

export function getColorByType(type) {
  switch (type) {
    case 'symptom':
      return '#B4E7CC';
    case 'drug':
      return '#d3d7e0';
    case 'department':
      return '#EED2BF';
    case 'examine':
      return '#C7D7FC';
    case 'disease':
      return '#F5E1A4';
    default:
      return '#F00';
  }
}

function formatGraph(res) {
  return {
    nodes: _.map(_.get(res, 'data.nodes'), i => {
      const fontSize = i.core ? 24 : 12;
      const rows = _.map(_.chunk(i.name, 5), j => j.join(''));
      return {
        id: i.name,
        size: i.size * 2,
        clickable: i.click,
        style: {
          label: {
            value: rows.join('\n'),
            position: 'center',
            offset: [0, rows.length * fontSize / 2],
            fontSize,
            fill: '#000',
            fillOpacity: i.is_active ? 0.75 : 0.08,
          },
          keyshape: {
            size: i.size,
            stroke: '',
            fill: getColorByType(i.type),
            fillOpacity: i.is_active ? 1 : 0.2,
            cursor: i.click ? 'pointer' : 'not-allowed',
          },
          status: {
            hover: {
              halo: {
                visible: i.click,
              },
            },
          },
        },
      };
    }),
    edges: _.map(_.get(res, 'data.edges'), i => ({
      source: i.source,
      target: i.target,
      style: {
        keyshape: {
          stroke: '#EBEBEB',
          strokeOpacity: i.is_active ? 1 : 0.1,
          endArrow: {
            path: '',
          },
        },
      },
    })),
  };
}

export async function createGraph(idList, nodeList, currentNodes) {
  try {
    event({
      category: 'createGraph',
      action: 'click',
      label: qsStringify({ idList, nodeList, currentNodes }, {
        addQueryPrefix: false,
      }),
    });
    const url = `${config.HOST_JAVA}/entity/graph`;
    const data = {
      history_nodes: nodeList,
      current_nodes: currentNodes,
      article_ids: idList,
    };
    info(`post ${url}`);
    const resp = await rq(withSign({
      url,
      method: 'post',
      data,
    }));
    return formatGraph(resp);
  } catch (e) {
    error(e);
    throw e;
  }
}
