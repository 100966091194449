// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_TEkwd {
  padding: 15px 10px;
  background-color: #F5F5F5;
  border-radius: 6px;
  margin: 15px;
  cursor: pointer;
}
.profile_OcLWJ {
  font-size: 14px;
  line-height: 1.8;
  color: #27334D;
}
.wrap_qXteG {
  display: inline-block;
  white-space: nowrap;
}
.view_Ei3Qo {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.8;
  color: #2C7EDB;
}
.tail_wK0N_ {
  width: 5px;
  height: 10px;
  margin-left: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProfileBlock/style.less"],"names":[],"mappings":"AAIA;EACE,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AAHF;AAMA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAJF;AAOA;EACE,qBAAA;EACA,mBAAA;AALF;AAQA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AANF;AASA;EACE,UAAA;EACA,YAAA;EACA,gBAAA;AAPF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.block {\n  padding: 15px 10px;\n  background-color: #F5F5F5;\n  border-radius: 6px;\n  margin: 15px;\n  cursor: pointer;\n}\n\n.profile {\n  font-size: 14px;\n  line-height: 1.8;\n  color: #27334D;\n}\n\n.wrap {\n  display: inline-block;\n  white-space: nowrap;\n}\n\n.view {\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 1.8;\n  color: #2C7EDB;\n}\n\n.tail {\n  width: 5px;\n  height: 10px;\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `block_TEkwd`,
	"profile": `profile_OcLWJ`,
	"wrap": `wrap_qXteG`,
	"view": `view_Ei3Qo`,
	"tail": `tail_wK0N_`
};
export default ___CSS_LOADER_EXPORT___;
