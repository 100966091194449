// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_qSMwp {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  color: #26334D;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-left: 20px;
  margin-top: 40px;
}
.desc_Y90wU {
  font-size: 16px;
  line-height: 1.69;
  color: #26334D;
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/TextViews/style.less"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;EACA,4CAAA;EACA,iBAAA;EACA,gBAAA;AAHF;AAMA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAJF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.header {\n  font-size: 18px;\n  font-weight: bold;\n  line-height: 1.56;\n  color: #26334D;\n  padding-bottom: 20px;\n  border-bottom: 1px solid @black05;\n  margin-left: 20px;\n  margin-top: 40px;\n}\n\n.desc {\n  font-size: 16px;\n  line-height: 1.69;\n  color: #26334D;\n  margin-left: 20px;\n  margin-top: 20px;\n  margin-right: 20px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_qSMwp`,
	"desc": `desc_Y90wU`
};
export default ___CSS_LOADER_EXPORT___;
