import * as React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import ActivityViews from '@components/ActivityViews';
import stl from './style.less';

export default class TextViews extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  static show({title, content}) {
    const elm = document.createElement('div');
    elm.id = `text-views`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<TextViews title={title} content={content} />);
  }

  close = () => {
    const elm = document.getElementById(`text-views`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { visible } = this.state;
    const {title, content} = this.props;
    return (
      <ActivityViews visible={visible} onClose={this.close}>
        <div style={{height: '80vh', overflowY: 'auto'}}>
          <div className={stl.header}>{title}</div>
          <div className={stl.desc}>{content}</div>
          <div style={{height: 120}}></div>
        </div>
      </ActivityViews>
    );
  }
}

interface IProps {
  title: string;
  content: string;
}
