import rq from '@utils/request';
import { withSign } from '@mina-modules/sign';
import config from '@config';
import { error, info } from '@libs/log';
import { formatText, qsStringify } from '@utils/index';
import { getMonth, getWeekday, getMonthDay } from '@utils/date';
import { numMap } from '@constants/index';
import _ from 'lodash';

function formatWeekday(weekDay: number) {
  const ranges = ['日', '一', '二', '三', '四', '五', '六'];
  return `星期${ranges[weekDay]}`;
}

function formatMonth(month: number) {
  return `${numMap[month]}月`;
}
export type MorningPostType = ReturnType<typeof formatMorningPost>;
export function formatMorningPost(res: any = {}) {
  return {
    id: res.unique_id,
    logo: res.icon_url,
    title: res.title,
    opinion: formatText(res.short_content),
    content: formatText(res.content),
    date: res.daily_date,
    calendar: {
      month: formatMonth(getMonth(res.daily_date)),
      date: res.daily_date,
      weekDay: formatWeekday(getWeekday(res.daily_date)),
      monthDay: getMonthDay(res.daily_date),
    },
    source: res.source,
    article: {
      title: formatText(res.sub_content?.title),
      desc: formatText(res.sub_content?.desc),
      mina: {
        appId: res.sub_content?.app_id,
        path: res.sub_content?.app_path,
      },
    },
  };
}

export async function fetchLatestDaily() {
  try {
    const url = `${config.HOST_JAVA}/daily/_latest`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatMorningPost(resp.data);
  } catch (e) {
    error(e);
    throw e;
  }
}

function formatMorningPostList(res: any = []) {
  return _.map(res, formatMorningPost);
}

export async function fetchDailyList(id, type = 'new', page = 1, size = 10) {
  try {
    const url = `${config.HOST_JAVA}/daily/${type}/${qsStringify({ unique_id: id, page, size })}`;
    info(`get ${url}`);
    const resp = await rq(withSign({
      url,
    }));
    return formatMorningPostList(resp.data);
  } catch (e) {
    error(e);
    throw e;
  }
}
