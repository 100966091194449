import * as React from 'react';
import SearchBar from '@components/mobile/SearchBar';
import HomeList from '@components/HomeList';
import Footer from '@components/Footer';
import Logo from '@components/Logo';
import MinaGoto from '@components/MinaGoto';
import { connect } from 'dva';
import stl from './style.less';
import config from '@config';

@connect()
export default class Home extends React.PureComponent<any, any> {
  get isMainHost() {
    return window.location.host === 'ask.nocode.com';
  }

  render() {
    if (this.isMainHost) {
      return (
        <div className={stl.minaPage}>
          <MinaGoto className={stl.minaGoto}/>
        </div>
      );
    } else {
      return (
        <div className={stl.page}>
          {config.sponsor.hasProduct && (
            <Logo className={stl.logoHome} />
          )}
          <SearchBar className={stl.searchBar} />
          <HomeList className={stl.list} />
          {config.sponsor.hasProduct && (
            <Footer className={stl.footer} />
          )}
        </div>
      );
    }
  }
}
