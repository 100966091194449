import config from '@config';
import { initSign } from '@mina-modules/sign';
import { info } from '@libs/log';
import User from '@utils/user';
import { initSignV3, withSignV3 } from './sign';

const fetch = require('dva/fetch');
initSign(config.token, config.API_HOST, true);
initSignV3(config.tokenV3, config.HOST_JAVA);
let retryCount = 0;

const host = `${window.location.protocol}//${window.location.host}`;
const loginUrl = `${host}/login`;

async function checkStatus(res) {
  const code = res.statusCode || res.status;
  if (code == 403 || code == 401) {
    retryCount++;
    if (retryCount > 3) {
      throw new Error();
    }
    window.location.href = loginUrl;
    throw new Error();
  }
  if (code >= 400) {
    const error = await res.json();
    throw new Error(error);
  }
}

async function request(obj: IRequest) {
  if (obj.url.includes(config.HOST_JAVA)) {
    obj = withSignV3(obj);
  }
  const method = (obj.method || 'get').toLowerCase();
  let headers = obj.header;
  const body = obj.data ? JSON.stringify(obj.data) : undefined;
  const url = obj.url;
  const token = User.token;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (
    method === 'post' ||
    method === 'put' ||
    method === 'patch'
  ) {
    headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...headers,
    };
  }
  const res = await fetch(`${url}`, {
    method,
    headers,
    body,
  });
  await checkStatus(res);
  const text = await res.text();
  const result = JSON.parse(text);
  info(text);
  return result;
}

interface IRequest {
  url: string;
  method?: string;
  data?: any;
  header?: any;
}

export default request;
