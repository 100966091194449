import * as React from 'react';
import ActivityViewsPc from '@components/pc/ActivityViews';
import ActivityViewsMobile from '@components/mobile/ActivityViews';
import { fixedBody, os, unfixedBody } from '@utils/browser';
import { pageview } from '@libs/ga';

let Component;
export default class ActivityViews extends React.PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_activity`);
    Component = os.isPc ? ActivityViewsPc : ActivityViewsMobile;
  }

  componentDidMount() {
    fixedBody();
  }

  componentWillUnmount() {
    unfixedBody();
  }

  render() {
    const { visible, children, onClose } = this.props;
    return (
      <Component visible={visible} onClose={onClose}>{children}</Component>
    );
  }
}

interface IProps {
  visible: boolean;

  onClose(): void;
}
