import { noop } from '@utils/index';
import MinaCodeViews from '@components/MinaCodeViews';
import AlertViews from '@components/AlertViews';
import { YHJK } from '@constants/index';

export const os = (function() {
  const ua = navigator.userAgent;
  const isWindowsPhone = /(?:Windows Phone)/.test(ua);
  const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
  const isAndroid = /(?:Android)/.test(ua);
  const isWechat = /(?:micromessenger)/i.test(ua);
  const isMina = /(?:miniProgram)/i.test(ua) || (window as any).__wxjs_environment === 'miniprogram';
  const isFireFox = /(?:Firefox)/.test(ua);
  const isTablet =
    /(?:iPad|PlayBook)/.test(ua) ||
    (isAndroid && !/(?:Mobile)/.test(ua)) ||
    (isFireFox && /(?:Tablet)/.test(ua));
  const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
  const isSmallScreen = screen.width < 640;
  const isPc = !isPhone && !isAndroid && !isSymbian && !isSmallScreen;
  return {
    isPhone,
    isAndroid,
    isWechat,
    isMina,
    isPc,
    isMobile: !isPc,
  };
})();

const availablePrefixs = ['moz', 'ms', 'webkit'];

function requestAnimationFramePolyfill() {
  let lastTime = 0;
  return (callback) => {
    const currTime = new Date().getTime();
    const timeToCall = Math.max(0, 16 - (currTime - lastTime));
    const id = window.setTimeout(() => {
      callback(currTime + timeToCall);
    }, timeToCall);
    lastTime = currTime + timeToCall;
    return id;
  };
}

export function getRequestAnimationFrame() {
  if (typeof window === 'undefined') {
    return noop;
  }
  if (window.requestAnimationFrame) {
    return window.requestAnimationFrame.bind(window);
  }

  const prefix = availablePrefixs.filter(
    (key) => `${key}RequestAnimationFrame` in window
  )[0];

  return prefix
    ? window[`${prefix}RequestAnimationFrame`]
    : requestAnimationFramePolyfill();
}

export function cancelRequestAnimationFrame(id) {
  if (typeof window === 'undefined') {
    return null;
  }
  if (window.cancelAnimationFrame) {
    return window.cancelAnimationFrame(id);
  }
  const prefix = availablePrefixs.filter(
    (key) =>
      `${key}CancelAnimationFrame` in window ||
      `${key}CancelRequestAnimationFrame` in window
  )[0];

  return prefix
    ? (
      window[`${prefix}CancelAnimationFrame`] ||
      window[`${prefix}CancelRequestAnimationFrame`]
    ).call(this, id)
    : clearTimeout(id);
}

export function scrollTop() {
  if (document.documentElement && document.documentElement.scrollTop) {
    return document.documentElement.scrollTop;
  } else if (document.body) {
    return document.body.scrollTop;
  }
}

const easeInOutCubic = (t: number, b: number, c: number, d: number) => {
  const cc = c - b;
  t /= d / 2;
  if (t < 1) {
    return (cc / 2) * t * t * t + b;
  } else {
    return (cc / 2) * ((t -= 2) * t * t + 2) + b;
  }
};

export const getCurrentScrollTop = () => {
  const targetNode = window;
  if (targetNode === window) {
    return (
      window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop
    );
  }
  return window.document.body.scrollTop;
};

const reqAnimFrame = getRequestAnimationFrame();

export const setScrollTop = (value: number) => {
  document.body.scrollTop = value;
  document.documentElement.scrollTop = value;
};

export const scrollToTop = () => {
  /** TODO: 统一处理翻页时滚动到顶部 */
  const scrollTop = getCurrentScrollTop();
  const startTime = Date.now();
  const frameFunc = () => {
    const timestamp = Date.now();
    const time = timestamp - startTime;
    setScrollTop(easeInOutCubic(time, scrollTop, 0, 450));
    if (time < 450) {
      reqAnimFrame(frameFunc);
    }
  };
  reqAnimFrame(frameFunc);
  // (this.props.onClick || noop)(e);
};

export function contains(root, el) {
  if (root.compareDocumentPosition) {
    // tslint:disable-next-line:no-bitwise
    return root === el || !!(root.compareDocumentPosition(el) & 16);
  }
  if (root.contains && el.nodeType === 1) {
    return root.contains(el) && root !== el;
  }

  el = el.parentNode;
  while (el) {
    if (el === root) {
      return true;
    }
    el = el.parentNode;
  }
  return false;
}

export function showAlert(message){
  AlertViews.show(message);
}

export function openWindow(url, target = '_blank'){
  window.open(url, target);
  // if (!canOpen) {
  //   showAlert(`请授权允许弹窗，跳转网址：${url}`);
  // }
}

export function navigateByType(
  {
    id,
    url,
    source,
  }: { id: string, url: string, source: string },
  dispatch?: any,
  dispatchType?: string
) {

  if (dispatch && (os.isMina || source === YHJK) && dispatchType) {
    return dispatch({
      type: dispatchType,
      payload: {
        id: id,
      },
    });
  }

  if(os.isMina){
    return; 
  }

  if(url){
    return openWindow(url);
  }

  if(source){
    return MinaCodeViews.show(source);
  }

}

export function fixedBody() {
  document.body.className = document.body.className.concat(' fixed');
}

export function unfixedBody() {
  document.body.className = document.body.className.replace(/\s+fixed/, '');
}

export function isDev() {
  return process.env.NODE_ENV === 'development';
}
