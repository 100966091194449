import * as React from 'react';
import GlobalLoading from '../../components/GlobalLoading/index';
import { connect } from 'dva';
import User from '../../utils/user';
import stl from './style.module.less';

export class Login extends React.PureComponent<any, any> {
  componentDidMount() {
    this.props.dispatch({
      type: 'user/login',
    });
  }

  logout = () => User.logout();

  render() {
    const { error } = this.props;
    return error ? (
      <div className={stl.errorCode}>{error}</div>
    ) : (
      <GlobalLoading />
    );
  }
}

export default connect(({ user }) => {
  return {
    error: user.error,
  };
})(Login);
