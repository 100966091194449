// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon_PN6xt {
  display: block;
  width: 86px;
  height: 86px;
  margin: 95px auto 30px;
}
.tips_pmLLD {
  font-size: 17px;
  color: rgba(18, 33, 46, 0.35);
  text-align: center;
  margin-bottom: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/UnknownResult/style.less"],"names":[],"mappings":"AAIA;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;AAHF;AAMA;EACE,eAAA;EACA,6BAAA;EACA,kBAAA;EACA,mBAAA;AAJF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.icon {\n  display: block;\n  width: 86px;\n  height: 86px;\n  margin: 95px auto 30px;\n}\n\n.tips {\n  font-size: 17px;\n  color: rgba(18, 33, 46, 0.35);\n  text-align: center;\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `icon_PN6xt`,
	"tips": `tips_pmLLD`
};
export default ___CSS_LOADER_EXPORT___;
