import * as React from 'react';
import * as _ from 'lodash-es';
import IcnArrowRight from '@components/icons/IcnArrowRight';
import stl from './style.less';

export default class PaperItem extends React.PureComponent<IProps, any> {
  get author() {
    const { item } = this.props;
    let author = _.get(item, 'authorList[0]');
    if (_.get(item, 'authorList.length') > 1) {
      author += `等 ${item.authorList.length} 人`;
    }
    return author;
  }

  click = () => {
    const { item, onClick } = this.props;
    onClick(item);
  };

  render() {
    const { item } = this.props;
    return (
      <div onClick={this.click}>
        <div className={stl.name}>{item.title}</div>
        {!_.isEmpty(item.date) && (
          <div className={stl.date}>{item.date}</div>
        )}
        {!_.isEmpty(item.summary) && (
          <div className={stl.summary}>{item.summary}</div>
        )}
        <div className={stl.comments}>
          <div className={stl.logo}>{item.logo}</div>
          <div className={stl.reference}>
            {item.source}
          </div>
          <div className={stl.arrow}>
            <IcnArrowRight />
          </div>
        </div>
      </div>
    );
  }
}

interface IProps {
  item: {
    title: string;
    date: string;
    summary: string;
    logo: React.ReactElement;
    journal: string;
    source: string;
    authorList: string[];
  };

  onClick(item): void;
}
