import * as React from 'react';
import cx from 'classnames';
import stl from './style.less';

export default function IcnOtc({ className }: IProps) {
  return (
    <i className={cx(stl.icon, className)} />
  );
}

interface IProps {
  className?: string;
}
