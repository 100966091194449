// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_DScBP {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.27;
  color: #26334D;
  margin-left: 15px;
  margin-bottom: 15px;
}
.expertise_ecH4d {
  display: block;
  font-size: 16px;
  line-height: 1.75;
  color: #27334D;
  word-break: break-word;
  padding: 15px 10px;
  background-color: #F5F5F5;
  border-radius: 6px;
  margin: 15px;
}
.summary_kbqGN {
  display: block;
  font-size: 16px;
  line-height: 2;
  color: #090F1B;
  word-break: break-word;
  margin: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProfileViews/style.less"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;AAHF;AAMA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,sBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;AAJF;AAOA;EACE,cAAA;EACA,eAAA;EACA,cAAA;EACA,cAAA;EACA,sBAAA;EACA,YAAA;AALF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.title {\n  font-size: 22px;\n  font-weight: 600;\n  line-height: 1.27;\n  color: #26334D;\n  margin-left: 15px;\n  margin-bottom: 15px;\n}\n\n.expertise {\n  display: block;\n  font-size: 16px;\n  line-height: 1.75;\n  color: #27334D;\n  word-break: break-word;\n  padding: 15px 10px;\n  background-color: #F5F5F5;\n  border-radius: 6px;\n  margin: 15px;\n}\n\n.summary {\n  display: block;\n  font-size: 16px;\n  line-height: 2;\n  color: #090F1B;\n  word-break: break-word;\n  margin: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `title_DScBP`,
	"expertise": `expertise_ecH4d`,
	"summary": `summary_kbqGN`
};
export default ___CSS_LOADER_EXPORT___;
