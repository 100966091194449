import React from 'react';
import IcnArrowDown from '@components/icons/IcnArrowDown';
import SearchBar from '@components/pc/SearchBar';
import IcnMiniProgram from '@components/icons/IcnMiniProgram';
import PicQrcode from '@components/icons/PicQrcode';
import Logo from '@components/Logo';
import config from '@config';
import icnKG from '@images/icn-explore.png';
import { connect } from 'dva';
import * as _ from 'lodash-es';
import cx from 'classnames';
import stl from './style.less';
import ExploreKG from '../ExploreKG';
import User from '@utils/user';

const doubleClickDuration = 300;
let timestamp;

export class SearchHeader extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      popupShow: false,
      entranceShow: false,
    };
  }

  get isLogin() {
    return User.isLogin(window.location.href);
  }

  handleHeaderMenuClick = () => {
    User.logout();
  };

  doubleClick = () => {
    const current = Date.now();
    if (current - timestamp < doubleClickDuration) {
      return this.setState({
        entranceShow: true,
      });
    }
    timestamp = current;
  };
  show = () => {
    if (!this.state.entranceShow) {
      return this.doubleClick();
    }
    const { faqOpinionIntent, entityNameList, dispatch } = this.props;
    const idList = _.map(faqOpinionIntent.list, i => i.id);
    const currentNodes = entityNameList;
    dispatch({
      type: 'kg/createGraph',
      payload: {
        idList,
        nodelist: [],
        currentNodes,
      },
    });
    this.setState({
      popupShow: true,
    });
  };
  hide = () => {
    this.setState({
      popupShow: false,
    });
  };

  render() {
    const { popupShow, entranceShow } = this.state;
    return (
      <div className={stl.header}>
        <a href={'/'}>
          <Logo className={stl.logoHome} />
        </a>
        <SearchBar className={stl.searchBar} />

          <div className={stl.entrances}>
          {!config.sponsor.duplication && (
            <>
              <div className={cx(stl.item, { [stl.hide]: !entranceShow })}
                  onClick={this.show}>
                <img className={stl.icon} src={icnKG} alt="知识图谱" />
                <div className={stl.text}>图谱探索</div>
                <IcnArrowDown />
              </div>
              <div className={stl.item}>
                <IcnMiniProgram />
                <div className={stl.text}>微信小程序</div>
                <IcnArrowDown />
                <div className={stl.popover}>
                  <div className={stl.hints}>使用微信扫一扫小程序码</div>
                  <PicQrcode className={stl.qrcode} />
                </div>
              </div>
            </>
          )}
          {this.isLogin ? <div className={stl.item}>
          {User.avatar ? <img className={stl.logo} src={User.avatar} /> : <img className={stl.logo} />}
          <div className={stl.popoverSmail} onClick={this.handleHeaderMenuClick}>
              <div className={stl.hints}>退出登录</div>
            </div>
          </div> : <a href='/login'> <div className={stl.item}>
          <div className={stl.text}>登录</div>
          </div></a>}
        </div>
        <ExploreKG visible={popupShow} onClose={this.hide} />
      </div>
    );
  }
}

export default connect(({ search }) => ({
  faqOpinionIntent: search.faqOpinionIntent,
  entityNameList: search.entityNameList,
}))(SearchHeader);
