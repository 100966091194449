// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block_rhdjW {
  display: flex;
  height: calc(100% - 55px);
  border-top: 1px solid rgba(0, 0, 0, 0.04);
}
.provinces_THmfA {
  flex: none;
  height: 100%;
  width: 120px;
  background-color: #F7F8FA;
  overflow-y: auto;
}
.province_O21Yy {
  font-size: 14px;
  color: #27334D;
  padding: 20px;
  cursor: pointer;
}
.province_O21Yy.active_oBeTT {
  font-weight: bold;
  color: #2C7EDB;
}
.province_O21Yy:active {
  background-color: #F5F5F5;
}
.cities_tu8_c {
  flex: auto;
  height: 100%;
  overflow-y: auto;
}
.city_NPDSP {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #27334D;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.city_NPDSP.active_oBeTT {
  font-weight: bold;
  color: #2C7EDB;
}
.city_NPDSP:active {
  background-color: #F5F5F5;
}
.checked_xmzsl {
  flex: none;
  width: 18px;
  height: 18px;
  margin: -2px -4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/LocationViews/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,yBAAA;EACA,yCAAA;AAHF;AAMA;EACE,UAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,gBAAA;AAJF;AAOA;EACE,eAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;AALF;AAOE;EACE,iBAAA;EACA,cAAA;AALJ;AAQE;EACE,yBAAA;AANJ;AAUA;EACE,UAAA;EACA,YAAA;EACA,gBAAA;AARF;AAWA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,4CAAA;EACA,eAAA;AATF;AAWE;EACE,iBAAA;EACA,cAAA;AATJ;AAYE;EACE,yBAAA;AAVJ;AAcA;EACE,UAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAZF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.block {\n  display: flex;\n  height: ~'calc(100% - 55px)';\n  border-top: 1px solid rgba(0, 0, 0, 0.04);\n}\n\n.provinces {\n  flex: none;\n  height: 100%;\n  width: 120px;\n  background-color: #F7F8FA;\n  overflow-y: auto;\n}\n\n.province {\n  font-size: 14px;\n  color: #27334D;\n  padding: 20px;\n  cursor: pointer;\n\n  &.active {\n    font-weight: bold;\n    color: #2C7EDB;\n  }\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n}\n\n.cities {\n  flex: auto;\n  height: 100%;\n  overflow-y: auto;\n}\n\n.city {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 14px;\n  color: #27334D;\n  padding: 20px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.04);\n  cursor: pointer;\n\n  &.active {\n    font-weight: bold;\n    color: #2C7EDB;\n  }\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n}\n\n.checked {\n  flex: none;\n  width: 18px;\n  height: 18px;\n  margin: -2px -4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `block_rhdjW`,
	"provinces": `provinces_THmfA`,
	"province": `province_O21Yy`,
	"active": `active_oBeTT`,
	"cities": `cities_tu8_c`,
	"city": `city_NPDSP`,
	"checked": `checked_xmzsl`
};
export default ___CSS_LOADER_EXPORT___;
