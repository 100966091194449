// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_aGZo_ {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.logoHome_ieE71 {
  flex: none;
  height: 50px;
  margin: 100px auto 40px;
}
.searchBar_Y2_RO {
  width: 640px;
  margin: 0 auto;
}
.list_Q_PEP {
  flex: none;
}
.footer_ZU5_P {
  flex: none;
  margin-top: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/pc/style.less"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AAHF;AAMA;EACE,UAAA;EACA,YAAA;EACA,uBAAA;AAJF;AAOA;EACE,YAAA;EACA,cAAA;AALF;AAQA;EACE,UAAA;AANF;AASA;EACE,UAAA;EACA,gBAAA;AAPF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.page {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n}\n\n.logoHome {\n  flex: none;\n  height: 50px;\n  margin: 100px auto 40px;\n}\n\n.searchBar {\n  width: 640px;\n  margin: 0 auto;\n}\n\n.list {\n  flex: none;\n}\n\n.footer {\n  flex: none;\n  margin-top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `page_aGZo_`,
	"logoHome": `logoHome_ieE71`,
	"searchBar": `searchBar_Y2_RO`,
	"list": `list_Q_PEP`,
	"footer": `footer_ZU5_P`
};
export default ___CSS_LOADER_EXPORT___;
