import * as React from 'react';
import IcnPutInBox from '@components/icons/IcnPutInBox';
import * as _ from 'lodash-es';
import { connect } from 'dva';
import stl from './style.less';
import { os } from '@utils/browser';
import cx from 'classnames';

export class SuggestList extends React.PureComponent<any, any> {
  click = evt => {
    const { onClick } = this.props;
    onClick(evt);
  };

  render() {
    const { list } = this.props;
    return !_.isEmpty(list) && (
      <div className={cx({ [stl.pc]: os.isPc })}>
        {_.map(list, item => (
          <div className={stl.item} key={item}
               onClick={this.click} data-value={item}>
            <div className={stl.text}>{item}</div>
            <div className={stl.icon}>
              <IcnPutInBox />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default connect(({ search }) => ({
  list: search.suggestList,
}))(SuggestList);
