// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_lPydy {
  font-size: 14px;
  color: #26334D;
  padding-top: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  margin-left: 15px;
}
.item_Qz40v {
  position: relative;
  cursor: pointer;
}
.item_Qz40v:active {
  background-color: #F5F5F5;
}
.item_Qz40v:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.item_Qz40v.inactive_KyeFe:active {
  background-color: unset;
}
.more_BRCGu {
  font-size: 14px;
  color: #2C7EDB;
  text-align: center;
  padding: 23px 0;
  margin-bottom: 42px;
}
`, "",{"version":3,"sources":["webpack://./src/components/HybridCard/style.less"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,cAAA;EACA,iBAAA;EACA,oBAAA;EACA,4CAAA;EACA,iBAAA;AAHF;AAMA;EACE,kBAAA;EACA,eAAA;AAJF;AAME;EACE,yBAAA;AAJJ;AAOE;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,QAAA;EACA,4CAAA;AALJ;AAQE;EACE,uBAAA;AANJ;AAUA;EACE,eAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;AARF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.header {\n  font-size: 14px;\n  color: #26334D;\n  padding-top: 25px;\n  padding-bottom: 15px;\n  border-bottom: 1px solid @black04;\n  margin-left: 15px;\n}\n\n.item {\n  position: relative;\n  cursor: pointer;\n\n  &:active {\n    background-color: #F5F5F5;\n  }\n\n  &:before {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 15px;\n    right: 0;\n    border-bottom: 1px solid @black04;\n  }\n\n  &.inactive:active {\n    background-color: unset;\n  }\n}\n\n.more {\n  font-size: 14px;\n  color: #2C7EDB;\n  text-align: center;\n  padding: 23px 0;\n  margin-bottom: 42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_lPydy`,
	"item": `item_Qz40v`,
	"inactive": `inactive_KyeFe`,
	"more": `more_BRCGu`
};
export default ___CSS_LOADER_EXPORT___;
