// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_olUh5 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  color: #26334D;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-left: 20px;
  margin-top: 40px;
}
.desc_IERrd {
  font-size: 16px;
  line-height: 1.69;
  color: #26334D;
  margin-left: 36px;
  margin-top: 20px;
  margin-right: 20px;
  position: relative;
}
.desc_IERrd:before {
  content: '';
  position: absolute;
  top: 10px;
  left: -16px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}
.contact_w2eb8 {
  font-size: 16px;
  line-height: 1.69;
  color: #26334D;
  padding-bottom: 60px;
  margin: 20px;
}
.strong_kthlc {
  color: #2C7EDB;
}
`, "",{"version":3,"sources":["webpack://./src/components/DutyViews/style.less"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;EACA,4CAAA;EACA,iBAAA;EACA,gBAAA;AAHF;AAMA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAJF;AAME;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,oCAAA;AAJJ;AAQA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;EACA,YAAA;AANF;AASA;EACE,cAAA;AAPF","sourcesContent":["@import (reference)'~@styles/vars';\n@import (reference)'~@styles/common';\n@import (reference)'~@assets/sprite/sprite.less';\n\n.header {\n  font-size: 18px;\n  font-weight: bold;\n  line-height: 1.56;\n  color: #26334D;\n  padding-bottom: 20px;\n  border-bottom: 1px solid @black05;\n  margin-left: 20px;\n  margin-top: 40px;\n}\n\n.desc {\n  font-size: 16px;\n  line-height: 1.69;\n  color: #26334D;\n  margin-left: 36px;\n  margin-top: 20px;\n  margin-right: 20px;\n  position: relative;\n\n  &:before {\n    content: '';\n    position: absolute;\n    top: 10px;\n    left: -16px;\n    width: 6px;\n    height: 6px;\n    border-radius: 50%;\n    background-color: @black50;\n  }\n}\n\n.contact {\n  font-size: 16px;\n  line-height: 1.69;\n  color: #26334D;\n  padding-bottom: 60px;\n  margin: 20px;\n}\n\n.strong {\n  color: #2C7EDB;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_olUh5`,
	"desc": `desc_IERrd`,
	"contact": `contact_w2eb8`,
	"strong": `strong_kthlc`
};
export default ___CSS_LOADER_EXPORT___;
