import * as React from 'react';
import { createRoot } from 'react-dom/client';
let root;
import Popup from '@components/abstract/Popup';
import stl from './style.less';

export default class AlertViews extends React.PureComponent<any, any> {
  static show(message) {
    const elm = document.createElement('div');
    elm.id = `alertviews`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<AlertViews message={message} />);
  }

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  close = () => {
    const elm = document.getElementById(`alertviews`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount(); 
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { message } = this.props;
    const { visible } = this.state;
    return (
      <Popup visible={visible} onClose={this.close} customClass={stl.popup}>
          <div className={stl.message}>{message}</div>
          <span className={stl.close} onClick={this.close}>关闭</span>
      </Popup>
    );
  }
}
