import * as React from 'react';
import { connect } from 'dva';
import SearchBar from '@components/mobile/SearchBar';
import Wrong from '@components/Wrong';
import NoMedicalResult from '@components/NoMedicalResult';
import UnknownResult from '@components/UnknownResult';
import * as _ from 'lodash-es';
import CovidCard from '@components/CovidCard';
import SuicideCard from '@components/SuicideCard';
import MainCard from '@components/MainCard';
import QueryCorrection from '@components/QueryCorrection';
import PredictList from '@components/PredictList';
import IntentExplore from '@components/IntentExplore';
import HybridCard from '@components/HybridCard';
import MinaEntranceViews from '@components/mobile/MinaEntranceViews';
import FeedbackViews from '@components/mobile/FeedbackViews';
import DutyViews from '@components/DutyViews';
import { email } from '@constants/index';
import IcnMiniApp from '@components/icons/IcnMiniApp';
import IcnNegtive from '@components/icons/IcnNegtive';
import IcnTrademark from '@components/icons/IcnTrademark';
import WxOpenLaunchWeapp from '@components/mobile/WxOpenLaunchWeapp';
import { event } from '@libs/ga';
import Logo from '@components/Logo';
import config from '@config';
import { navigateByType, os } from '@utils/browser';
import DoctorCard from '@components/DoctorCard';
import HospitalCard from '@components/HospitalCard';
import BaikeCard from '@components/BaikeCard';
import stl from './style.less';
import { ExpectCard } from '@components/ExpectCard';
import DrugstoreCard from '@components/DrugstoreCard';
import VaccineCard from '@components/VaccineCard';
import Footer from '@components/Footer';
import KgCard from '@components/KgCard';

@connect(({ search, app }) => ({
  queryCorrection: search.queryCorrection,
  departmentList: search.departmentList,
  drugList: search.drugList,
  diseaseList: search.diseaseList,
  examineList: search.examineList,
  state: search.state,
  covid: search.covid,
  types: search.types,
  faqOpinionIntent: search.faqOpinionIntent,
  baikeIntent: search.baikeIntent,
  doctorIntent: search.doctorIntent,
  hospitalIntent: search.hospitalIntent,
  drugstoreIntent: search.drugstoreIntent,
  vaccineIntent: search.vaccineIntent,
  expectedIntent: search.expectedIntent,
  kgIntent: search.kgIntent,
  predict: search.predict,
  isBlur: search.isBlur,
  blurStyle: search.blurStyle,
  noSearch: app.noSearch,
}))
export default class Search extends React.PureComponent<any, any> {
  renderMainCard = () => {
    const {
      types,
      baikeIntent,
      faqOpinionIntent,
      doctorIntent,
      hospitalIntent,
      drugstoreIntent,
      vaccineIntent,
    } = this.props;
    return (
      <>
        {_.map(types, (type) => {
          if (type === 'faqOpinion' && !_.isEmpty(faqOpinionIntent.list)) {
            return (
              <MainCard
                context={faqOpinionIntent.context}
                list={faqOpinionIntent.list}
                type="faqOpinion"
                key={type}
              />
            );
          }
          if (type === 'baike' && baikeIntent.item.id) {
            return <BaikeCard item={baikeIntent.item} key={type} />;
          }
        })}
        {!_.isEmpty(doctorIntent.list) ? (
          <DoctorCard item={doctorIntent} />
        ) : !_.isEmpty(hospitalIntent.list) ? (
          <HospitalCard item={hospitalIntent} />
        ) : !_.isEmpty(drugstoreIntent.list) ? (
          <DrugstoreCard
            context={drugstoreIntent.context}
            list={drugstoreIntent.list}
          />
        ) : !_.isEmpty(vaccineIntent?.list) ? (
          <VaccineCard
            context={vaccineIntent.context}
            list={vaccineIntent.list}
          />
        ) : null}
      </>
    );
  };

  showMina = () => {
    event({
      category: 'search_showmina',
      action: 'click',
      label: '',
    });
    MinaEntranceViews.show();
  };

  showFeedback = () => {
    event({
      category: 'search_showfeedback',
      action: 'click',
      label: '',
    });
    FeedbackViews.show();
  };

  showDuty = () => {
    event({
      category: 'search_showduty',
      action: 'click',
      label: '',
    });
    DutyViews.show();
  };

  showPredict = (item) => {
    const { dispatch } = this.props;
    dispatch({
      type: 'search/fetchPredict',
      payload: {
        item,
      },
    });
  };

  toView = (item) => {
    event({
      category: 'expect-card_toview',
      action: 'click',
      label: `${item.id}_faq`,
    });

    // TODO: 是否需要第三个参数？
    return navigateByType(item, this.props.dispatch, 'search/fetchFaq');
  };

  getPageStyle = (isBlur) => {
    const stl: any = {
      transition: '.3s filter',
    };
    if (isBlur) {
      stl.filter = 'blur(25px)';
    }
    return stl;
  };

  directSearch = (query) => {
    this.props.dispatch({
      type: 'search/search',
      payload: {
        query,
        direct: true,
      },
    });
  };

  render() {
    const {
      state,
      queryCorrection,
      expectedIntent,
      predict,
      covid,
      kgIntent,
      isBlur,
      noSearch,
    } = this.props;

    return (
      <div style={this.getPageStyle(isBlur)}>
        {config.sponsor.search && !noSearch && (
          <>
            <a className={stl.header} href={'/'}>
              <Logo className={stl.logoHome} />
            </a>
            <SearchBar className={stl.searchBar} />
          </>
        )}
        <div className={stl.searchResult}>
          <QueryCorrection
            raw={queryCorrection.raw}
            tip={queryCorrection.tip}
            onSearch={this.directSearch}
          />
          {state === -2 && <Wrong />}
          {state === 0 && <NoMedicalResult />}
          {state === 2 && <UnknownResult />}
          <div className={stl.margin}>
            {state === 4 && <CovidCard list={covid} />}
          </div>
          {state === 5 && <SuicideCard />}
          {_.get(kgIntent, 'text') ? (
            <KgCard item={kgIntent} />
          ) : (
            _.get(expectedIntent, 'id') && (
              <ExpectCard item={expectedIntent} onClick={this.toView} />
            )
          )}
          <PredictList
            title={predict.title}
            list={predict.list}
            onClickItem={this.showPredict}
          />
          {this.renderMainCard()}
          <IntentExplore />
          <HybridCard />
          {state === 1 && (
            <>
              {config.sponsor.feedback && (
                <div className={stl.button} onClick={this.showFeedback}>
                  <IcnNegtive />
                  <div className={stl.text}>结果有问题，我要反馈</div>
                </div>
              )}
              {!config.sponsor.duplication && (
                <div className={stl.button} onClick={this.showMina}>
                  <IcnMiniApp />
                  <div className={stl.text}>体验小程序版</div>
                  <WxOpenLaunchWeapp
                    condition={os.isWechat}
                    height={50}
                    username={'gh_f4ccad239ba3'}
                    path={'pages/index/index.html'}
                  />
                </div>
              )}
              {config.sponsor.hasProduct && (
                <>
                  <div className={stl.trademark}>
                    <IcnTrademark />
                  </div>
                  <div className={stl.footer}>
                    <div className={stl.entrance} onClick={this.showDuty}>
                      免责声明
                    </div>
                    <div className={stl.line} />
                    <a className={stl.entrance} href={`mailto:${email}`}>
                      联系我们
                    </a>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {config.sponsor.hasProduct && <Footer />}
      </div>
    );
  }
}
