import React from 'react';
import SidebarViews from '@components/SidebarViews';
import { pageview } from '@libs/ga';
import { createRoot } from 'react-dom/client';
let root;
import stl from './style.less';
import { get, map } from 'lodash';

export default class BusinessViews extends React.PureComponent<any, any> {
  static show(item) {
    const elm = document.createElement('div');
    elm.id = `BusinessViews`;
    document.body.appendChild(elm);
    root = createRoot(elm);
    root.render(<BusinessViews item={item} />);
  }
  constructor(props) {
    super(props);
    pageview(`${location.pathname}#!views_business`);
    this.state = {
      visible: !!props.item,
    };
  }

  close = () => {
    const elm = document.getElementById(`BusinessViews`);
    this.setState({
      visible: false,
    });
    if (elm) {
      setTimeout(() => {
        root.unmount();
        document.body.removeChild(elm);
      }, 200);
    }
  };

  render() {
    const { visible } = this.state;
    const { item } = this.props;
    return (
      <SidebarViews visible={visible} onClose={this.close}>
        <div className={stl.title}>{get(item, 'title')}</div>
        {map(item?.list, (desc, index) => (
          <div className={stl.desc} key={index}>
            { desc }
          </div>
        ))}
        <div className={stl.wrap}>
          <img
            className={stl.qrcode}
            src={item?.qrcode}
          />
        </div>
      </SidebarViews>
    );
  }
}
